import { useCallback } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { constProvider } from 'providers';

const msg2label = {
  'must be a well-formed email address': 'common.error.emailFormat',
  'must not be blank': 'common.error.notBlank',
  'Service unavailable': 'common.error.serviceUnavailable'
};

export function useMutationErrorHandler(resource: keyof typeof constProvider.RESOURCES) {
  const notify = useNotify();
  const translate = useTranslate();
  return useCallback(
    (error: Response & { body?: { errors?: string[] } }) => {
      const errors = error?.body?.errors;
      const labels =
        error.status >= 400 && error.status < 500
          ? `resources.${constProvider.RESOURCES[resource].URI}.fields`
          : `common.error.labels`;
      let message = '';
      if (!errors) {
        message = JSON.stringify(error);
      } else if (Array.isArray(errors)) {
        message = (errors as any[])
          .map(({ fieldName, errorDescription }) => `${translate(`${labels}.${fieldName}`)} ${errorDescription}`)
          .join(' | ');
      } else {
        message = Object.entries(errors)
          .map(
            ([fieldName, msg]) =>
              `${translate(`${labels}.${fieldName}`)} ${translate(msg2label[msg as keyof typeof msg2label])}`
          )
          .join(' | ');
      }
      notify(message, 'error', { smart_count: 1 });
    },
    [notify, translate, resource]
  );
}
