import { resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { messages as polishMessages } from './pl';
import { messages as italianMessages } from './it';

export enum Locale {
  IT = 'it',
  PL = 'pl'
}

export const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === Locale.IT ? italianMessages : polishMessages),
  resolveBrowserLocale()
);
