import { Grid, Modal } from '@material-ui/core';
import React, { useCallback, useContext } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  useDataProvider,
  useRefresh,
  useTranslate
} from 'react-admin';
import { __RouterContext as RouterContext } from 'react-router';
import {
  CreateInstanceAside,
  ModalContent,
  UniversalFilterFields,
  ListPicker,
  ListPickerColumns
} from 'components/common';
import { useButtonPositioning, useModalController, usePermissionsMap, useUnlinkButton } from 'hooks/common';
import { Device, PatientDetailsMeasurementDto } from 'models/models';
import { constProvider } from 'providers';
import { defaultListProps } from 'services/utils';
import { EditView } from 'types';
import { deviceConsts } from '../../../device/Device.const';
import { patientConsts } from '../../Patient.const';
import { BasicInfoCard } from '../patient-dashboard/basic-info-card/basic-info-card';
import { useStyles } from './patient-device.style';

const filterFields: UniversalFilterFields<Device> = [
  {
    source: 'serialNumber'
  }
];

const listColumns: ListPickerColumns<Device> = [
  {
    source: 'serialNumber'
  },
  {
    source: 'deviceType'
  }
];

export function PatientDevice(props: EditView<PatientDetailsMeasurementDto>) {
  const { record } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const permission = usePermissionsMap();
  const rowSelectable = useCallback(() => false, []);

  const routerContext = useContext(RouterContext);
  const routerContextOverrides = { ...routerContext, location: { ...routerContext.location, search: '' } };

  const UnlinkButton = useUnlinkButton('PATIENT_DEVICE', { patientId: record.id });
  const { setContainerNode, style: createInstanceButtonStyle } = useButtonPositioning({ x: 'right', y: 'bottom' });

  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const { isOpen: showAvailable, open: openAvailableModal, close: closeAvailableModal } = useModalController();

  const devicesAdded = useCallback(
    (ids: number[]) => {
      ids.forEach((id) => {
        dataProvider
          .patch(constProvider.RESOURCES.PATIENT_DEVICE.URI, { id, data: { patientId: record.id } })
          .then(() => refresh());
      });
      closeAvailableModal();
    },
    [dataProvider, refresh, closeAvailableModal, record.id]
  );

  const listProps = {
    ...defaultListProps,
    basePath: `/${constProvider.RESOURCES.PATIENT.URI}`,
    resource: constProvider.RESOURCES.PATIENT_DEVICE.URI,
    filterDefaultValues: { $patientId: record.id },
    aside: permission.any(
      ['SECTION_PATIENTS_LIST_VIEW', 'SPECIFIC_DEVICE_ASSIGNMENT_ADD'],
      <CreateInstanceAside style={createInstanceButtonStyle} callback={openAvailableModal} />
    )
  };

  return (
    <div
      ref={(node) => {
        setContainerNode(node);
        if (!node) {
          return;
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <BasicInfoCard data={{ patient: record } as any} hideTitle />
        </Grid>
        <Grid item xs={12}>
          <RouterContext.Provider value={routerContextOverrides}>
            <List {...listProps}>
              <Datagrid isRowSelectable={rowSelectable} classes={{ rowCell: classes.row, headerCell: classes.row }}>
                <TextField source="deviceNumber" />
                <FunctionField
                  label={translate(deviceConsts.FIELDS.TYPE)}
                  title={translate(deviceConsts.FIELDS.TYPE)}
                  render={(r: any) => r?.deviceType?.name}
                />
                <TextField source="operatingMode" />
                <DateField source="dateOfAssignment" />
                <DateField source="dateOfReturn" />
                {permission.any(
                  ['SECTION_PATIENTS_LIST_VIEW', 'SPECIFIC_DEVICE_ASSIGNMENT_EDIT'],
                  <FunctionField
                    title={translate(deviceConsts.MEASURING_DEVICE.LABELS.UNLINK)}
                    label={deviceConsts.MEASURING_DEVICE.LABELS.ACTION}
                    render={UnlinkButton}
                  />
                )}
              </Datagrid>
            </List>
          </RouterContext.Provider>
        </Grid>
      </Grid>
      <Modal open={showAvailable} disableAutoFocus disableEnforceFocus onBackdropClick={closeAvailableModal}>
        <ModalContent title={patientConsts.TAB.DEVICES.UNASSIGNED.TITLE} className={classes.searchAvailableModal}>
          <ListPicker
            onCancel={closeAvailableModal}
            onSave={devicesAdded}
            basePath={`/${constProvider.RESOURCES.PATIENT.URI}`}
            resource={constProvider.RESOURCES.AVAILABLE_DEVICE.URI}
            filterDefaultValues={{ $patientId: record.id }}
            filterFields={filterFields}
            listColumns={listColumns}
          >
            <BasicInfoCard data={{ patient: record } as any} hideTitle />
          </ListPicker>
        </ModalContent>
      </Modal>
    </div>
  );
}
