import { Font, Align } from 'chartjs-plugin-datalabels/types/options';

export const chartOptions = {
  responsive: false,
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  },
  plugins: {
    datalabels: {
      color: 'white',
      font: {
        weight: 'bold'
      } as Font,
      align: 'end' as Align,
      borderWidth: 0,
      display: function (context: any) {
        return context.dataset.data[context.dataIndex] > 0;
      }
    }
  }
};
