import React from 'react';
import { useDispatch } from 'react-redux';
import { Sidebar, setSidebarVisibility } from 'react-admin';
import { useStyles } from './CustomSidebar.styles';

const CustomSidebar = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Sidebar
      classes={classes}
      {...props}
      onMouseEnter={() => dispatch(setSidebarVisibility(true))}
      onMouseLeave={() => dispatch(setSidebarVisibility(false))}
    />
  );
};

export default CustomSidebar;
