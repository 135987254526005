import axios, { AxiosError, AxiosResponse } from 'axios';
import { constProvider } from './const';
import { cond, get, matches, noop } from 'lodash';
import { HttpError } from 'ra-core';

function createAxiosInstance(baseURL: string) {
  return axios.create({
    baseURL,
    timeout: Number(process.env.REACT_APP_HTTP_TIMEOUT || 3000)
  });
}
export const httpInstance = createAxiosInstance(constProvider.BASE_API_URL);

httpInstance.interceptors.response.use(interceptSuccess, interceptFailure);

function interceptSuccess(response: AxiosResponse<any>) {
  response.data = cond([
    [matches(constProvider.RESOURCES.DEVICE_TYPES.URI), remapNameToId(response)],
    [matches(constProvider.RESOURCES.USER_MEDICALCENTER.URI), remapUserMedicalcenters(response)],
    [matches(constProvider.RESOURCES.ADMIN_MEDICALCENTER.URI), remapAdminMedicalcenter(response)],
    [() => true, () => response.data]
  ])(getResourceFromResponse(response));

  return response;
}

function interceptFailure(error: AxiosError) {
  const response = cond<AxiosError, AxiosResponse | null>([
    // add some mappings here to get error and re-map it to correct response. It's handy for development
    [() => true, () => null]
  ])(error);

  if (response !== null) {
    return response;
  } else {
    throw error;
  }
}

function getResourceFromResponse(response: AxiosResponse | undefined): string | undefined {
  if (response === undefined) {
    return undefined;
  }

  const { url = '', baseURL = '' } = response.config;
  return url.substring(baseURL.length + 1).split('?')[0];
}

function remapNameToId(response: AxiosResponse<any>) {
  return () => (response.data as Array<{ name: string }>).map(({ name }) => ({ name, id: name }));
}

function remapUserMedicalcenters(response: AxiosResponse<{ id: string; name: string; organizationPath: string }[]>) {
  return () => {
    return (response.data || []).map(({ name, organizationPath }) => ({
      id: organizationPath,
      organizationPath,
      name
    }));
  };
}

interface AdminMedicalcenter {
  readonly email: string;
  readonly firstName: string;
  readonly id: string;
  readonly lastName: string;
  readonly phoneNumber: string;
  readonly type: string;
}

function remapAdminMedicalcenter(response: AxiosResponse<AdminMedicalcenter[]>) {
  return () => {
    return (response.data || []).map(({ id, firstName, lastName, email, phoneNumber }) => ({
      id,
      name: `${firstName} ${lastName}, ${email}, ${phoneNumber}`
    }));
  };
}

export interface AxiosToFetchApiResponse<DATA = any> {
  readonly status: number;
  readonly statusText: string;
  readonly headers: object;
  readonly body: string;
  readonly json: DATA;
}

export function axiosSuccessToFetchJsonAPI<DATA>({
  status,
  statusText,
  headers,
  data
}: AxiosResponse<DATA>): AxiosToFetchApiResponse<DATA> {
  let body = '';
  try {
    body = JSON.stringify(data);
  } catch (e) {
    noop();
  }

  return {
    status: Number(status),
    statusText,
    headers,
    body,
    json: data
  };
}

export function axiosFailureToFetchJsonAPI<DATA>(error: AxiosError<DATA>): AxiosToFetchApiResponse<DATA> {
  throw new HttpError(
    get(error, 'response.message', '') || get(error, 'response.statusText', '') || error.message,
    get(error, 'response.status', 0),
    get(error, 'response.data', undefined)
  );
}
