import React from 'react';
import { Layout } from 'react-admin';
import CustomAppBar from './CustomAppBar/CustomAppBar';
import CustomMenu from './CustomMenu/CustomMenu';
import CustomSidebar from './CustomSidebar/CustomSidebar';
import { useClasses } from './CustomLayout.styles';

const CustomLayout = (props: any) => {
  const useClass = useClasses();

  return <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} sidebar={CustomSidebar} classes={useClass} />;
};

export default CustomLayout;
