import React, { useState, useCallback } from 'react';
import { Datagrid, TextField, useTranslate } from 'react-admin';
import { CreateInstanceAside, PageContentWithTitle, List, BooleanField } from 'components/common';
import { usePermissionsMap } from 'hooks/common';
import { FiltersButton } from 'components/common/FiltersButton/FiltersButton';
import { medicalCenterConsts } from '../MedicalCenter.const';
import { MedicalCenterFilters } from './medicalcenter-filters/MedicalCenterFilters';

interface FieldData {
  active: boolean;
  city: string;
  country: string;
  permitSubCenters: boolean;
  name: string;
}

export function MedicalCenterList(props: any) {
  const [showFilters, setShowFilters] = useState(false);
  const translate = useTranslate();
  const permissions = usePermissionsMap();
  const toggleFilters = useCallback(() => setShowFilters(!showFilters), [showFilters]);

  return (
    <PageContentWithTitle title={medicalCenterConsts.LIST_TITLE}>
      <List
        {...props}
        filters={showFilters ? <MedicalCenterFilters /> : undefined}
        title={translate(medicalCenterConsts.LIST_TITLE)}
        bulkActionButtons={false}
        exporter={false}
        actions={<FiltersButton toggleFilters={toggleFilters} />}
        aside={permissions.any('SECTION_MEDICAL_CENTERS_ADD', <CreateInstanceAside />)}
      >
        <Datagrid rowClick="edit">
          <TextField label={translate(medicalCenterConsts.LABELS.NAME)} source="name" />
          <TextField label={translate(medicalCenterConsts.LABELS.CITY)} source="city" />
          <BooleanField label={translate(medicalCenterConsts.LABELS.ACTIVE)} source="active" />
          <BooleanField label={translate(medicalCenterConsts.LABELS.SUBCENTERS)} source="permitSubCenters" />
        </Datagrid>
      </List>
    </PageContentWithTitle>
  );
}
