import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import { Circle, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { MAP_PROJECTION } from './map-card.const';

export const getCircleGeometry = (longitude: number, latitude: number, radius: number) =>
  new Circle(fromLonLat([longitude, latitude], MAP_PROJECTION), radius);

export const getPointGeometry = (longitude: number, latitude: number) =>
  new Point(fromLonLat([longitude, latitude], MAP_PROJECTION));

export const getCircleFeature = (longitude: number, latitude: number, radius: number) =>
  new Feature(getCircleGeometry(longitude, latitude, radius));

export const getPointFeature = (longitude: number, latitude: number) =>
  new Feature(getPointGeometry(longitude, latitude));

export const changeFeaturesGeometry = (layer: VectorLayer, pointGeometry: Point, circleGeometry: Circle) => {
  const source = layer.getSource();
  source.getFeatures().forEach((feature: Feature) => {
    const geometry = feature.getGeometry();

    if (geometry instanceof Point) {
      feature.setGeometry(pointGeometry);
    } else if (geometry instanceof Circle) {
      feature.setGeometry(circleGeometry);
    }
  });
};
