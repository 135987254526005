import React from 'react';
import { useSelector } from 'react-redux';
import {
  useGetOne,
  useMutation,
  useTranslate,
  Loading,
  Error as ErrorComponent,
  FormWithRedirect,
  BooleanInput
} from 'react-admin';
import { Box, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { constProvider } from 'providers';
import { getPatientLocalization } from 'features/resources/selectors';
import { PatientLocalizationForm } from './patient-localization-form/patient-localization-form';
import { patientLocalizationConsts } from './patient-localization.const';
import { useStyles } from './patient-localization.style';

export function PatientLocalization(props: any) {
  const { record } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const { data, loading, error } = useGetOne(constProvider.RESOURCES.PATIENT_LOCATION.URI, record.id);
  const [mutate] = useMutation();
  const patientLocalizationData = useSelector((state: any) => getPatientLocalization(state, record.id));

  if (loading) return <Loading />;

  if (error) return <ErrorComponent />;

  const toggleEnabled = (value: boolean) => {
    mutate({
      type: 'update',
      resource: constProvider.RESOURCES.PATIENT_LOCATION.URI,
      payload: {
        id: record.id,
        data: {
          enabled: value
        }
      }
    });
  };

  const patientLocalizationEnabled = data.enabled || patientLocalizationData.enabled;

  const renderPatientLocalizationForm = () => (
    <form>
      <BooleanInput
        resource={constProvider.RESOURCES.PATIENT_LOCATION.URI}
        source="enabled"
        label={patientLocalizationEnabled ? undefined : translate(patientLocalizationConsts.ENABLE_LOCALIZATION)}
        onChange={toggleEnabled}
      />
    </form>
  );

  return (
    <Box className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent className={classes.localizationEnableContent}>
              <Typography className={classes.localizationEnableInfo}>
                {translate(patientLocalizationConsts.LOCALIZATION_ENABLE_INFO)}
              </Typography>
              <FormWithRedirect record={data} render={renderPatientLocalizationForm} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {patientLocalizationEnabled && <PatientLocalizationForm {...props} />}
    </Box>
  );
}
