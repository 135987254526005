import { makeStyles } from '@material-ui/styles';
import { fontWeight } from 'style';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    margin: '24px'
  },
  localizationEnableContent: {
    '&:last-child': {
      paddingBottom: 0
    }
  },
  localizationEnableInfo: {
    marginBottom: '12px',
    color: theme.palette.text.secondary,
    fontWeight: fontWeight.medium
  }
}));
