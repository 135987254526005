import React from 'react';
import { useTranslate, TextInput, required, minValue } from 'react-admin';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { constProvider } from 'providers';
import { fontWeight } from 'style';
import { ValuesCard } from 'components/common/values-card/values-card';
import { LocationLockDownDataDTO } from '../patient-localization-form.models';
import { FieldNames } from '../patient-localization-form.const';
import { locationLockDownCardConst } from './location-lockdown-card.const';
import { AddressField } from './address-field/address-field';

type LocationLockdownCardProps = {
  data?: LocationLockDownDataDTO;
  isEditMode: boolean;
};

const radiusValidator = [required(), minValue(1)];

export function LocationLockdownCard({ data, isEditMode }: LocationLockdownCardProps) {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <ValuesCard
      header={translate(locationLockDownCardConst.HEADER)}
      items={[
        { label: translate(locationLockDownCardConst.LONGITUDE), value: data ? data.longitude : undefined },
        { label: translate(locationLockDownCardConst.LATITUDE), value: data ? data.latitude : undefined },
        {
          label: translate(locationLockDownCardConst.RADIUS),
          value: data && data.radius ? `${data.radius}m` : undefined
        },
        { label: translate(locationLockDownCardConst.ADDRESS), value: data ? data.address : undefined }
      ]}
    >
      {isEditMode && (
        <>
          <Divider className={classes.divider} />
          <Typography className={classes.formInfo}>{translate(locationLockDownCardConst.FORM_INFO)}</Typography>
          <TextInput
            resource={constProvider.RESOURCES.LOCATION_LOCKDOWN.URI}
            source="radius"
            helperText={locationLockDownCardConst.RADIUS_HELPER_TEXT}
            validate={radiusValidator}
            variant="standard"
            fullWidth
          />
          <Divider className={classes.divider} />
          <AddressField
            addressName={FieldNames.address}
            longitudeName={FieldNames.longitude}
            latitudeName={FieldNames.latitude}
            inputProps={{
              label: translate(`resources.${constProvider.RESOURCES.LOCATION_LOCKDOWN.URI}.fields.address`),
              variant: 'standard',
              fullWidth: true
            }}
          />
        </>
      )}
    </ValuesCard>
  );
}

export const useStyles = makeStyles({
  divider: {
    margin: '24px 0'
  },
  formInfo: {
    marginBottom: '24px',
    fontWeight: fontWeight.medium
  }
});
