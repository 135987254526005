import { constProvider, Resource } from './const';
import { get, has, size, trim, find, defaults } from 'lodash';

import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { DataProvider } from 'ra-core';
import { axiosFailureToFetchJsonAPI, axiosSuccessToFetchJsonAPI, AxiosToFetchApiResponse, httpInstance } from './http';
import { stringify } from 'query-string';

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const simpleRestProvider = (apiUrl: string, axiosInstance: AxiosInstance): DataProvider => {
  const httpClient = adjustAxiosToFetchJsonInterface(axiosInstance);

  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      const queryParams = Object.fromEntries(
        Object.entries(params.filter)
          .filter(([key]) => key.startsWith('$'))
          .map(([key, value]) => [key.substr(1), value])
      );
      const raFilter = Object.fromEntries(Object.entries(params.filter).filter(([key]) => !key.startsWith('$')));

      const query = {
        ...queryParams,
        sort: JSON.stringify(mapSortParam(resource, field, order)),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(raFilter)
      };

      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url, resource).then(({ headers, json: data }) => {
        if (isPaginationEnabled(resource) && !has(headers, 'content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
          );
        }
        return {
          data,
          total: isPaginationEnabled(resource)
            ? parseInt(get(headers, 'content-range').split('/').pop(), 10)
            : size(data)
        };
      });
    },

    getOne: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, resource).then(({ json: data }) => ({
        data
      })),

    getMany: (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url, resource).then(({ json: data }) => ({ data }));
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify(mapSortParam(resource, field, order)),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id
        })
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url, resource).then(({ headers, json: data }) => {
        if (isPaginationEnabled(resource) && !has(headers, 'content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
          );
        }
        return {
          data,
          total: isPaginationEnabled(resource)
            ? parseInt(get(headers, 'content-range').split('/').pop(), 10)
            : size(data)
        };
      });
    },

    update: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, resource, {
        method: 'put',
        data: params.data
      }).then(({ json: data }) => ({ data })),

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, resource, { method: 'put', data: params.data })
        )
      ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}`, resource, { method: 'post', data: params.data }).then(({ json }) => ({
        data: { ...params.data, id: json.id }
      }));
    },

    delete: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, resource, { method: 'delete', data: params.previousData }).then(
        ({ json: data }) => ({
          data
        })
      ),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`, resource, { method: 'delete' }))
      ).then((responses) => ({
        data: responses.map(({ json }) => json.id)
      })),

    getCustomUrl: (resource: string) => {
      const url = `${apiUrl}/${resource}`;

      return httpClient(url, resource).then(({ json: data }) => {
        return {
          data
        };
      });
    },
    patch: (resource: string, params: any) => {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, resource, { method: 'patch', data: params.data }).then(
        ({ json: data }) => ({
          data
        })
      );
    }
  };
};

function isPaginationEnabled(resource: string): boolean {
  const findResult = find(constProvider.RESOURCES, { URI: resource });
  return Boolean(findResult) && defaults(findResult, { USE_PAGINATION: true }).USE_PAGINATION;
}

function getRequestHeaders(resource: string) {
  const isResourceWithoutOrganizationPathHeader = Boolean(
    find(constProvider.RESOURCES, { URI: resource, USE_ORGANIZATION_PATH_HEADER: false })
  );
  const organizationPath = getOrganizationPath();

  const organizationPathHeader =
    !isResourceWithoutOrganizationPathHeader && organizationPath ? { 'organization-path': organizationPath } : {};

  return { Accept: 'application/json', 'Content-Type': 'application/json', ...organizationPathHeader };
}

function getOrganizationPath() {
  return trim(JSON.parse(localStorage.getItem('persist:root') || '{}').selectedMedicalcenter, '"');
}

function adjustAxiosToFetchJsonInterface(axiosInstance: AxiosInstance) {
  return (url: string, resource: string, options: AxiosRequestConfig = {}): Promise<AxiosToFetchApiResponse> =>
    axiosInstance({ url, ...options, headers: getRequestHeaders(resource) })
      .then((response) => axiosSuccessToFetchJsonAPI(response))
      .catch((error) => axiosFailureToFetchJsonAPI(error));
}

export const dataProvider: DataProvider = simpleRestProvider(constProvider.BASE_API_URL, httpInstance);

type SortParam = [string, string];

function mapSortParam(resource: string, field: string, order: string): SortParam {
  const foundResource = find<Resource>(
    Object.values(constProvider.RESOURCES),
    ({ URI, SORT_FIELD_MAP }) => URI === resource && Boolean(SORT_FIELD_MAP)
  );

  return [get(foundResource, `SORT_FIELD_MAP.${field}`, field), order];
}
