import { Card, CardContent, Typography } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useGetOne, useTranslate, useDataProvider, useNotify } from 'react-admin';
import { PatientMeasurementDto, PatientStatusDto, PatientCategoryEnum } from 'models/models';
import { constProvider } from 'providers';
import { basicInfoConsts } from './basic-info-card.const';
import { useStyles } from './basic-info-card.styles';
import { PatientCategoryBadge } from 'components/common/patient-category-badge/patient-category-badge';

type BasicInfoCard = {
  className?: string;
  data: PatientMeasurementDto;
  hideTitle?: boolean;
};

export function BasicInfoCard({ className, data, hideTitle }: BasicInfoCard) {
  const translate = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [category, setCategory] = useState<PatientCategoryEnum | string>('-');

  const { id, patient } = data;
  const { gender, medicalCenterId, pesel, phoneNumber } = patient;

  const patientId = id ?? patient.id;

  useEffect(() => {
    dataProvider
      .getCustomUrl(`${constProvider.RESOURCES.PATIENT.URI}/${patientId}/measurement/status`)
      .then(({ data }: { data: PatientStatusDto }) => {
        setCategory(data.patientCategory);
      })
      .catch((error: Error) => {
        notify(error.message, 'warning');
      });
  }, [dataProvider, patientId, notify]);

  const { data: medicalCenter } = useGetOne(constProvider.RESOURCES.MEDICAL_CENTER.URI, medicalCenterId);

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        {hideTitle || (
          <Typography variant="h6" component="h2" className={classes.header}>
            {translate(basicInfoConsts.LABEL)}
          </Typography>
        )}
        <Typography gutterBottom>
          <span className={classes.prop}>{translate(basicInfoConsts.GENDER)}:</span>
          <span className={classes.text}>{translate(get(constProvider.GENDER_TO_LABEL, gender))}</span>
        </Typography>
        <Typography gutterBottom>
          <span className={classes.prop}>{translate(basicInfoConsts.IDENTIFIER)}:</span>
          <span className={classes.text}>{pesel}</span>
        </Typography>
        <Typography gutterBottom>
          <span className={classes.prop}>{translate(basicInfoConsts.PHONE_NUMBER)}:</span>
          <span className={classes.text}>{phoneNumber}</span>
        </Typography>
        <Typography gutterBottom>
          <span className={classes.prop}>{translate(basicInfoConsts.MEDICAL_CENTER_NAME)}:</span>
          <span className={classes.text}>{medicalCenter ? medicalCenter.name : ''}</span>
        </Typography>
        <Typography>
          <span className={classes.prop}>{translate(basicInfoConsts.CATEGORY)}:</span>
          <PatientCategoryBadge category={category} />
        </Typography>
      </CardContent>
    </Card>
  );
}
