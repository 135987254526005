import { reduce, get } from 'lodash';
import { constProvider } from '../../providers';

export const parseFormErrors = (error: { response: any }, translate: (message: string) => string) => {
  const response = error.response;

  if (!response || !response.data) {
    return {};
  }

  const errors = response.data.errors;

  if (!errors) {
    return {};
  }

  return reduce(
    errors,
    (acc: any, e: { fieldName: string; errorDescription: string }) => ({
      ...acc,
      [e.fieldName]: translate(
        get(constProvider.FORM_ERROR_TO_MESSAGE, e.errorDescription, constProvider.DEFAULT_ERROR_MESSAGE)
      )
    }),
    {}
  );
};
