import React, { useCallback, useState } from 'react';
import { Datagrid, ReferenceField, TextField, useTranslate } from 'react-admin';
import { List, PageContentWithTitle, StatusField, UniversalFilterFields } from 'components/common';
import { MeasuringDeviceDto } from 'models/models';
import { constProvider } from 'providers';
import { FiltersButton } from 'components/common/FiltersButton/FiltersButton';
import { measuringDeviceConsts } from '../measuring-device.const';
import { DeviceListFilters } from './device-list-filters/device-list-filters';

const filterFields: UniversalFilterFields<MeasuringDeviceDto> = [
  {
    source: 'serialNumber'
  },
  {
    source: 'addressBDA'
  },
  {
    source: 'model'
  },
  {
    source: 'type'
  },
  {
    source: 'medicalCenterId',
    resource: 'MEDICALCENTER_FILTER_SELECT'
  }
];

export function MeasuringDeviceList(props: any) {
  const translate = useTranslate();
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = useCallback(() => setShowFilters(!showFilters), [showFilters]);

  return (
    <PageContentWithTitle title={measuringDeviceConsts.LIST_TITLE}>
      <List
        {...props}
        filters={showFilters ? <DeviceListFilters fields={filterFields} /> : undefined}
        title={translate(measuringDeviceConsts.LIST_NAME)}
        bulkActionButtons={false}
        exporter={false}
        actions={<FiltersButton toggleFilters={toggleFilters} />}
      >
        <Datagrid rowClick="edit">
          <TextField source="serialNumber" />
          <TextField source="producer" />
          <TextField source="addressBDA" />
          <TextField source="model" />
          <TextField source="type" />
          <ReferenceField
            variant="standard"
            source="medicalCenterId"
            reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
          >
            <TextField source="name" />
          </ReferenceField>
          <StatusField source="status" />
        </Datagrid>
      </List>
    </PageContentWithTitle>
  );
}
