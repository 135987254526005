import { Card, Grid, Typography, Box, LinearProgress } from '@material-ui/core';

import { ExceedChart } from './exceed-chart/exceed-chart';
import React, { useEffect, useState } from 'react';
import { useStyles } from './exceed-card.styles';
import { useTranslate, useDataProvider } from 'react-admin';
import { constProvider } from 'providers';
import { dashboardDoctorConsts } from '../dashboard-doctor/dashboard-doctor.const';
import { measurementTypeLabelType } from './exceed-card.const';
import { DoctorPatientsExceededMeasurementsDto } from 'models/models';

export function ExceedCard() {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [data, setData] = useState<DoctorPatientsExceededMeasurementsDto | null>(null);

  useEffect(() => {
    dataProvider
      .getCustomUrl(constProvider.RESOURCES.PATIENT_MEASUREMENT_EXCEED_STATS.URI)
      .then(({ data }: { data: DoctorPatientsExceededMeasurementsDto }) => {
        setData(data);
      });
  }, [dataProvider]);

  const measurementTypeLabel: measurementTypeLabelType = {
    temperature: translate(dashboardDoctorConsts.TYPE.TEMPERATURE),
    saturation: translate(dashboardDoctorConsts.TYPE.SATURATION),
    pulse: translate(dashboardDoctorConsts.TYPE.PULSE)
  };

  return (
    <Card className={classes.container}>
      <Grid container justify="center">
        {data ? (
          <Grid item xs={12}>
            <Typography component="h6" variant="h6" className={classes.title}>
              {translate(dashboardDoctorConsts.TYPE.TITLE)}
            </Typography>
            <Box className={classes.chart}>
              {Object.entries(data).map((e, i) => {
                return (
                  <Grid container key={i} spacing={2}>
                    <Grid item xs={2} md={12} lg={2}>
                      <Typography variant="caption">{measurementTypeLabel[e[0]]}</Typography>
                    </Grid>
                    <Grid item xs={8} md={12} lg={7} xl={8}>
                      <ExceedChart data={e[1]} type={e[0]} />
                    </Grid>
                    <Grid item xs={2} md={12} lg={3} xl={2}>
                      <Typography variant="caption">
                        {e[1].total} {translate(dashboardDoctorConsts.TYPE.TEXT)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Grid>
        ) : (
          <Box className={classes.indicator}>
            <LinearProgress />
          </Box>
        )}
      </Grid>
    </Card>
  );
}
