import { makeStyles } from '@material-ui/core/styles';
import * as styles from 'style';

export const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.03)',
    transform: 'translateY(0)!important' as any,
    visibility: 'visible!important' as any
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: '70px'
  },
  logo: {
    height: '40px',
    flex: 3
  }
});

const menuButtonBaseStyles = {
  backgroundColor: styles.colors.darkBlue,
  margin: 0,
  padding: '12px 15px',
  borderRadius: 0,
  borderRight: `1px solid ${styles.colors.darkBlue}`,
  transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  '&:hover': {
    backgroundColor: styles.colors.darkBlue
  }
};

export const useClassesClosed = makeStyles({
  menuButton: {
    ...menuButtonBaseStyles,
    padding: '12px 15px',
    width: '55px',
    position: 'absolute'
  }
});

export const useClassesOpen = makeStyles({
  menuButton: {
    ...menuButtonBaseStyles,
    width: '240px',
    position: 'absolute'
  },
  menuButtonIconOpen: {
    marginRight: 'auto'
  }
});
