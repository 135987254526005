import { makeStyles } from '@material-ui/core';
import * as styles from 'style';

export const useStyles = makeStyles({
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    fontSize: '12px',
    color: styles.colors.darkGray,
    fontWeight: 'bold',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  link: {
    padding: '0 5px',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.7
    }
  }
});
