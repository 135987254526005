import { FunctionField, useTranslate } from 'react-admin';
import { colors } from '../../../style';
import React, { useCallback } from 'react';
import { measuringDeviceConsts } from '../../resources/measuring-device/measuring-device.const';
import { Tooltip, IconButton } from '@material-ui/core';
import { Check as CheckIcon, Build as BuildIcon, CompareArrows as CompareArrowsIcon } from '@material-ui/icons';
import { MeasuringDeviceStatusEnum, MeasuringDeviceDto } from 'models/models';
import { makeStyles } from '@material-ui/core/styles';

type StatusFieldProps = {
  source: string;
} & any;

const { green } = colors;

const useStyles = makeStyles({
  checkIcon: {
    color: green
  }
});

export function StatusField({ source, ...restProps }: StatusFieldProps) {
  const translate = useTranslate();
  const classes = useStyles();

  const renderStatusField = useCallback(
    ({ status }: MeasuringDeviceDto) => {
      const getName = (status: MeasuringDeviceStatusEnum) => {
        switch (status) {
          case 'ASSIGNED':
            return translate(measuringDeviceConsts.AVAILABILITY.ASSIGNED);
          case 'SERVICE':
            return translate(measuringDeviceConsts.AVAILABILITY.SERVICE);
          case 'AVAILABLE':
            return translate(measuringDeviceConsts.AVAILABILITY.AVAILABLE);
          default:
            return undefined;
        }
      };
      const getIcon = (status: MeasuringDeviceStatusEnum) => {
        switch (status) {
          case 'AVAILABLE':
            return <CheckIcon fontSize="small" className={classes.checkIcon} />;
          case 'SERVICE':
            return <BuildIcon fontSize="small" />;
          case 'ASSIGNED':
            return <CompareArrowsIcon fontSize="small" />;
          default:
            return undefined;
        }
      };
      const getStatus = (status: MeasuringDeviceStatusEnum) => {
        const name = getName(status);
        const icon = getIcon(status);
        return (
          <Tooltip title={name} placement="right">
            <IconButton aria-label={name}>{icon}</IconButton>
          </Tooltip>
        );
      };
      return getStatus(status);
    },
    [translate, classes.checkIcon]
  );

  return <FunctionField source={source} render={renderStatusField} {...restProps} />;
}
