import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 16px'
  },
  icon: {
    width: '18px',
    height: '18px',
    marginRight: '8px'
  },
  dropdown: {
    marginLeft: 'auto'
  }
}));
