import React, { useCallback } from 'react';
import { FunctionField } from 'react-admin';
import { Link } from 'react-router-dom';
import { DoctorPatientDto } from 'models/models';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { constProvider } from 'providers';

const useStyles = makeStyles({
  text: {
    textDecoration: 'none',
    color: 'black'
  }
});

type LinkFieldProps = {
  source: string;
} & any;

export function LinkField({ source, ...restProps }: LinkFieldProps) {
  const classes = useStyles();
  const renderLinkField = useCallback(
    (props: DoctorPatientDto) => {
      const id = get(props, 'id');
      const text = get(props, source);

      if (!id) return text;

      return (
        <Link to={`${constProvider.RESOURCES.PATIENT.URI}/${id}`} className={classes.text}>
          {text}
        </Link>
      );
    },
    [source, classes.text]
  );
  return <FunctionField source={source} render={renderLinkField} {...restProps} />;
}
