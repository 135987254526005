import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type UseButtonPositioningOptions = {
  x: 'right' | 'left';
  y: 'top' | 'bottom';
  alignToCenter?: boolean;
};

export function useButtonPositioning({ x, y, alignToCenter = true }: UseButtonPositioningOptions) {
  const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(null);
  const [style, setStyle] = useState({
    transform: alignToCenter ? 'translate(-50%, -50%)' : 'translate(0, 0)',
    top: 0,
    left: 0,
    bottom: 'auto',
    right: 'auto',
    position: 'fixed'
  });
  useEffect(() => {
    if (!containerNode) {
      return;
    }
    const observer = new ResizeObserver(() => {
      const clientRect = containerNode.getBoundingClientRect();
      setStyle({
        ...style,
        transform: `translate(${clientRect[x]}px, ${clientRect[y]}px)${alignToCenter ? ' translate(-50%, -50%)' : ''}`
      });
    });
    observer.observe(containerNode);
    return () => observer.disconnect();
  }, [containerNode, x, y, alignToCenter, style]);
  return { setContainerNode, style };
}
