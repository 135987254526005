import { constProvider } from 'providers';

export const locationLockDownCardConst = Object.freeze({
  HEADER: `resources.${constProvider.RESOURCES.PATIENT.URI}.edit.localization.locationLockDownCard.header`,
  LONGITUDE: `resources.${constProvider.RESOURCES.PATIENT.URI}.edit.localization.locationLockDownCard.longitude`,
  LATITUDE: `resources.${constProvider.RESOURCES.PATIENT.URI}.edit.localization.locationLockDownCard.latitude`,
  RADIUS: `resources.${constProvider.RESOURCES.PATIENT.URI}.edit.localization.locationLockDownCard.radius`,
  ADDRESS: `resources.${constProvider.RESOURCES.PATIENT.URI}.edit.localization.locationLockDownCard.address`,
  FORM_INFO: `resources.${constProvider.RESOURCES.LOCATION_LOCKDOWN.URI}.formInfo`,
  RADIUS_HELPER_TEXT: `resources.${constProvider.RESOURCES.LOCATION_LOCKDOWN.URI}.radiusHelperText`
});
