import { Anchor, Align, Font } from 'chartjs-plugin-datalabels/types/options';

export const chartOptions = {
  responsive: true,
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  },
  scales: {
    xAxes: [
      {
        display: false,
        stacked: true
      }
    ],
    yAxes: [
      {
        display: false,
        stacked: true
      }
    ]
  },
  plugins: {
    datalabels: {
      color: 'white',
      font: {
        weight: 'bold'
      } as Font,
      anchor: 'end' as Anchor,
      align: 'left' as Align,
      borderWidth: 0
    }
  }
};
