import { FunctionField } from 'react-admin';

import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { matches, constant, cond, get } from 'lodash';
import { constProvider } from 'providers';
import { colors } from 'style/colors';
import { DoctorPatientDto, MeasurementDetailDto } from 'models/models';

type MeasurementFieldProps = {
  source: string;
  suffix?: boolean;
} & any;

const useStyles = makeStyles({
  valueRed: {
    color: colors.darkerRed
  },
  valueYellow: {
    color: colors.darkYellow
  }
});

export function MeasurementField({ source, ...restProps }: MeasurementFieldProps) {
  const classes = useStyles();

  const getValueStyle = cond([
    [matches(constProvider.MEASUREMENT_STATUS.RED), constant(classes.valueRed)],
    [matches(constProvider.MEASUREMENT_STATUS.YELLOW), constant(classes.valueYellow)]
  ]);

  const renderMeasurementField = useCallback(
    (props: DoctorPatientDto) => {
      const data: MeasurementDetailDto = get(props, source, {});
      if (!data) return '-';
      const { category, value } = data;
      return (
        <Typography className={getValueStyle(category)}>
          {value} {source === 'temperatureMeasurement' && '°C'}
        </Typography>
      );
    },
    [getValueStyle, source]
  );

  return <FunctionField source={source} render={renderMeasurementField} {...restProps} />;
}
