import { makeStyles } from '@material-ui/core/styles';
import { fontWeight } from 'style';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '24px'
  },
  card: {
    height: '100%'
  },
  measurementIcon: {
    width: '50px',
    height: '50px',
    fill: theme.palette.primary.main,
    marginBottom: '8px'
  },
  measurementArrow: {
    width: '16px',
    height: '18px',
    marginLeft: '5px',
    marginTop: '10px'
  },
  prop: {
    color: theme.palette.text.primary,
    fontWeight: fontWeight.medium
  },
  label: {
    color: theme.palette.text.secondary
  }
}));
