import React from 'react';
import { Create } from 'react-admin';
import { PageContentWithTitle } from 'components/common';
import { UserDetails } from '../common/user-details/user-details';
import { UsersForm } from '../common/user-form/UsersForm';
import { UserPrivileges } from '../common/user-privileges/user-privileges';
import { userConsts } from '../User.const';

const createSteps = [UserDetails, UserPrivileges];

export function UsersCreate(props: any) {
  return (
    <PageContentWithTitle title={userConsts.CREATE_TITLE}>
      <Create {...props} title={userConsts.PAGE_TITLE}>
        <UsersForm steps={createSteps} />
      </Create>
    </PageContentWithTitle>
  );
}
