import { combineReducers } from 'redux';

import { historyProvider } from '../providers';
import { connectRouter } from 'connected-react-router';

import selectedMedicalcenter from 'features/selectedMedicalcenter/reducer';
import { adminReducer } from 'react-admin';

const rootReducer = combineReducers({
  admin: adminReducer,
  router: connectRouter(historyProvider),
  selectedMedicalcenter: selectedMedicalcenter
});

export default rootReducer;
