import { FunctionField } from 'react-admin';
import { Link } from 'react-router-dom';
import { DoctorPatientDto } from 'models/models';
import { get } from 'lodash';

import React, { useCallback } from 'react';
import { SurroundSound } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { constProvider } from 'providers';

type DeviceFieldProps = {
  source: string;
} & any;

export const useStyles = makeStyles(() => ({
  icon: {
    color: 'black'
  }
}));

export function DeviceField({ source, ...restProps }: DeviceFieldProps) {
  const { icon } = useStyles();

  const renderDeviceField = useCallback(
    (props: DoctorPatientDto) => {
      const deviceId = get(props, source);

      if (!deviceId) return <SurroundSound color="disabled" />;

      return (
        <Link to={`${constProvider.RESOURCES.PATIENT.URI}/${deviceId}`}>
          <SurroundSound color="inherit" className={icon} />
        </Link>
      );
    },
    [source, icon]
  );
  return <FunctionField source={source} render={renderDeviceField} {...restProps} />;
}
