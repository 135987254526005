import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useSetLocale, useTranslate } from 'react-admin';
import { Button, Popper, Grow, MenuList, Paper, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Locale } from 'providers';
import { useStyles } from './ChangeLanguageDropdown.styles';
import { useOnClickOutside } from 'hooks/common';

const ChangeLanguageDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [language, setLanguage] = useState<Locale>(Locale.PL);
  const classes = useStyles();
  const translate = useTranslate();
  const setLocale = useSetLocale();

  const changeLanguage = useCallback(
    (lang: Locale) => {
      setLanguage(lang);
      setLocale(lang);
      setAnchorEl(null);
    },
    [setLocale]
  );

  const ref = useRef<HTMLButtonElement | null>(null);

  const openLanguageDropdown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  useOnClickOutside(ref, () => setAnchorEl(null));

  const languages = useMemo(
    () => [
      { name: Locale.PL, label: 'common.changeLanguageDropdown.polish' },
      { name: Locale.IT, label: 'common.changeLanguageDropdown.italian' }
    ],
    []
  );

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={openLanguageDropdown}
        className={classes.button}
        ref={ref}
      >
        {language.toUpperCase()}
        <ArrowDropDownIcon className={classes.arrow} />
      </Button>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.growPlacementBottom}>
              <MenuList id="simple-menu">
                {languages.map((element) => (
                  <MenuItem
                    key={element.name}
                    onClick={() => changeLanguage(element.name)}
                    className={classes.menuItem}
                  >
                    {translate(element.label)}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ChangeLanguageDropdown;
