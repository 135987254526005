import React, { useCallback } from 'react';
import { Form, FormRenderProps, Field } from 'react-final-form';
import {
  BooleanInput,
  SaveButton,
  required,
  useTranslate,
  useRedirect,
  useNotify,
  useDataProvider,
  AutocompleteInput,
  ReferenceInput
} from 'react-admin';
import { validatePhoneNumber } from 'services/validators';
import { Box, Grid, Toolbar, Typography, Button } from '@material-ui/core';
import { constProvider } from 'providers';
import { formatPhoneNumber, parseFormErrors } from 'services/utils';
import { FormTextInput } from 'components/common/FormTextInput/FormTextInput';
import { MedicalCenterFormValues } from './MedicalCenterForm.models';
import { medicalCenterFormConsts } from './MedicalCenterForm.const';
import { useStyles } from './MedicalCenterForm.styles';
import { useSelector } from 'react-redux';
import * as Types from 'AppTypes';
import { getSelectedMedicalcenter } from 'features/selectedMedicalcenter/selectors';
import { SuperiorMedicalcenter } from './SuperiorMedicalcenter';

type MedicalCenterFormProps = {
  isEditMode?: boolean;
} & any;

export function MedicalCenterForm(props: MedicalCenterFormProps) {
  const { isEditMode, basePath, record, version } = props;
  const notify = useNotify();
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const currentSelectedMedicalCenter = useSelector((state: Types.RootState) => getSelectedMedicalcenter(state));

  const submit = useCallback(
    async (values: MedicalCenterFormValues) => {
      try {
        if (isEditMode) {
          const response = await dataProvider.update(constProvider.RESOURCES.MEDICAL_CENTER.URI, {
            id: record.id,
            data: values
          });
          notify('ra.notification.updated', 'info', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            smart_count: 1
          });
          redirectTo('edit', basePath, response.data.id);
        } else {
          await dataProvider.create(constProvider.RESOURCES.MEDICAL_CENTER.URI, {
            data: values
          });
          notify('ra.notification.created', 'info', {
            // eslint-disable-next-line @typescript-eslint/camelcase
            smart_count: 1
          });
          redirectTo('list', basePath);
        }
      } catch (error) {
        return parseFormErrors(error, translate);
      }
    },
    [basePath, dataProvider, isEditMode, notify, record.id, redirectTo, translate]
  );

  const onCancelClick = useCallback(() => {
    redirectTo(`/${constProvider.RESOURCES.MEDICAL_CENTER.URI}`);
  }, [redirectTo]);

  const validatePhoneNumberValidator = useCallback(
    (value: string) => {
      return validatePhoneNumber(value, translate(medicalCenterFormConsts.VALIDATION.phoneNumber));
    },
    [translate]
  );

  const renderForm = (formProps: FormRenderProps<MedicalCenterFormValues>) => {
    const parentMedicalcenterId = isEditMode
      ? formProps.form.getState().values.medicalCenterParentId
      : currentSelectedMedicalCenter;

    return (
      <form>
        <Box className={classes.container}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              {Boolean(parentMedicalcenterId) && <SuperiorMedicalcenter medicalcenterId={parentMedicalcenterId} />}
            </Grid>
            <Grid item={true} xs={12}>
              <Field
                name="name"
                label={translate(medicalCenterFormConsts.LABELS.NAME)}
                component={FormTextInput}
                validate={required(translate('ra.validation.required'))}
                fullWidth
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Field
                name="nip"
                label={translate(medicalCenterFormConsts.LABELS.NIP)}
                component={FormTextInput}
                validate={required(translate('ra.validation.required'))}
                fullWidth
              />
            </Grid>
            <Grid item={true} xs={6}>
              <Field
                name="address"
                label={translate(medicalCenterFormConsts.LABELS.ADDRESS)}
                component={FormTextInput}
                validate={required(translate('ra.validation.required'))}
                fullWidth
              />
            </Grid>
            <Grid item={true} xs={4}>
              <Field
                name="postalCode"
                label={translate(medicalCenterFormConsts.LABELS.POSTAL_CODE)}
                component={FormTextInput}
                validate={required(translate('ra.validation.required'))}
                fullWidth
              />
            </Grid>
            <Grid item={true} xs={4}>
              <Field
                name="city"
                label={translate(medicalCenterFormConsts.LABELS.CITY)}
                component={FormTextInput}
                validate={required(translate('ra.validation.required'))}
                fullWidth
              />
            </Grid>
            <Grid item={true} xs={4}>
              <Field
                name="country"
                label={translate(medicalCenterFormConsts.LABELS.COUNTRY)}
                component={FormTextInput}
                validate={required(translate('ra.validation.required'))}
                fullWidth
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Box display="flex">
                <BooleanInput
                  className={classes.permitSubCenters}
                  label={translate(medicalCenterFormConsts.LABELS.PERMIT_SUB_CENTRES)}
                  source="permitSubCenters"
                />
                <BooleanInput label={translate(medicalCenterFormConsts.LABELS.ACTIVE)} source="active" />
              </Box>
            </Grid>
          </Grid>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                {translate(medicalCenterFormConsts.SMS_NOTIFICATION_SUBTITLE)}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Field
                name="smsNotificationPhoneNumber"
                label={translate(medicalCenterFormConsts.LABELS.SMS_NOTIFICATION_PHONE_NUMBER)}
                component={FormTextInput}
                validate={validatePhoneNumberValidator}
                parse={formatPhoneNumber}
                placeholder="+481234567890"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                {translate(medicalCenterFormConsts.ADMIN_SUBTITLE)}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <ReferenceInput
                source="adminId"
                reference={constProvider.RESOURCES.ADMIN_MEDICALCENTER.URI}
                label={translate(medicalCenterFormConsts.LABELS.ADMIN)}
                fullWidth
                sort={adminAutocompleteSort}
                filterToQuery={filterToQueryForAdminAutocomplete}
                validate={required(translate('ra.validation.required'))}
              >
                <AutocompleteInput />
              </ReferenceInput>
            </Grid>
          </Grid>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button color="primary" className={classes.cancelButton} onClick={onCancelClick}>
              {translate(medicalCenterFormConsts.BUTTONS.cancelButtonCaption)}
            </Button>
            <SaveButton saving={formProps.submitting} handleSubmitWithRedirect={formProps.handleSubmit} />
          </Box>
        </Toolbar>
      </form>
    );
  };

  return (
    <Form
      initialValues={record}
      onSubmit={submit}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      render={renderForm}
    />
  );
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
};

function filterToQueryForAdminAutocomplete(searchString: string) {
  return { searchString };
}

const adminAutocompleteSort = { field: 'firstName', order: 'ASC' };
