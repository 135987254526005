import React from 'react';
import { useTranslate, Create } from 'react-admin';
import { PageContentWithTitle } from 'components/common';
import { MedicalCenterForm } from '../common/medicalcenter-form/MedicalCenterForm';
import { medicalCenterConsts } from '../MedicalCenter.const';

export function MedicalCenterCreate(props: any) {
  const translate = useTranslate();

  return (
    <PageContentWithTitle title={medicalCenterConsts.CREATE_TITLE}>
      <Create {...props} title={translate(medicalCenterConsts.CREATE_TITLE)}>
        <MedicalCenterForm />
      </Create>
    </PageContentWithTitle>
  );
}
