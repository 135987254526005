import { Tab, Tabs } from '@material-ui/core';
import { isNil } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Edit, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import * as Types from 'AppTypes';
import { EditContentWrapper, PageContentWithTitle, TabPanel } from 'components/common';
import { getPatient } from 'features/resources/selectors';
import { usePermissionsMap } from 'hooks/common';
import { PatientForm } from '../common/patient-form/PatientForm';
import { patientConsts } from '../Patient.const';
import { PatientDashboard } from './patient-dashboard/patient-dashboard';
import { PatientDevice } from './patient-device/patient-device';
import { PatientLocalization } from './patient-localization/patient-localization';

export function PatientEdit(props: any) {
  const { id } = props;
  const translate = useTranslate();
  const patient = useSelector((state: Types.RootState) => getPatient(state, id));
  const permissions = usePermissionsMap();

  const [selectedTabValue, setSelectedTabValue] = useState<number>(0);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setSelectedTabValue(newValue);
    },
    [setSelectedTabValue]
  );

  const titleInterpolation = useMemo(
    () => ({ name: patient ? `${patient.firstName} ${patient.lastName}` : undefined }),
    [patient]
  );

  const renderContent = (editProps: any) => {
    return (
      <>
        <Tabs
          value={selectedTabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="Patient tabs"
        >
          <Tab label={translate(patientConsts.TAB.PATIENT_DETAILS.LABEL)} />
          <Tab
            label={translate(patientConsts.TAB.DASHBOARD.LABEL)}
            disabled={!permissions.any('DASHBOARDS_PATIENT_DASHBOARD_READ_ONLY_PROPERTIES')}
          />
          <Tab
            label={translate(patientConsts.TAB.LOCALIZATION.LABEL)}
            disabled={
              !permissions.any(['SPECIFIC_DEFINE_GEOLOCATION_AREA_ADD', 'SPECIFIC_DEFINE_GEOLOCATION_AREA_EDIT'])
            }
          />
          <Tab
            label={translate(patientConsts.TAB.DEVICES.LABEL)}
            disabled={!permissions.any(['SECTION_PATIENTS_LIST_VIEW', 'SPECIFIC_DEVICE_ASSIGNMENT_ADD', 'SPECIFIC_DEVICE_ASSIGNMENT_EDIT'])}
          />
        </Tabs>
        <TabPanel index={0} value={selectedTabValue}>
          <PatientForm isEditMode {...editProps} />
        </TabPanel>
        {permissions.any(
          'DASHBOARDS_PATIENT_DASHBOARD_READ_ONLY_PROPERTIES',
          <TabPanel index={1} value={selectedTabValue}>
            <PatientDashboard {...editProps} />
          </TabPanel>
        )}
        {permissions.any(
          ['SPECIFIC_DEFINE_GEOLOCATION_AREA_ADD', 'SPECIFIC_DEFINE_GEOLOCATION_AREA_EDIT'],
          <TabPanel index={2} value={selectedTabValue}>
            <PatientLocalization {...editProps} />
          </TabPanel>
        )}
        {permissions.any(
          ['SECTION_PATIENTS_LIST_VIEW', 'SPECIFIC_DEVICE_ASSIGNMENT_ADD', 'SPECIFIC_DEVICE_ASSIGNMENT_EDIT'],
          <TabPanel index={3} value={selectedTabValue}>
            <PatientDevice {...editProps} />
          </TabPanel>
        )}
      </>
    );
  };

  return (
    <PageContentWithTitle
      title={!isNil(patient) ? patientConsts.EDIT_TITLE : undefined}
      titleInterpolation={titleInterpolation}
    >
      <Edit {...props} title={translate(patientConsts.PAGE_TITLE)}>
        <EditContentWrapper>{(editProps: any) => renderContent(editProps)}</EditContentWrapper>
      </Edit>
    </PageContentWithTitle>
  );
}
