import { FunctionField } from 'react-admin';
import { Link } from 'react-router-dom';
import React, { useCallback } from 'react';
import { get } from 'lodash';
import { constProvider } from 'providers';
import { PatientCategoryEnum, DoctorPatientDto } from 'models/models';
import { PatientCategoryBadge } from 'components/common/patient-category-badge/patient-category-badge';

type CategoryFieldProps = {
  source: string;
} & any;

const linkStyle = {
  textDecoration: 'none'
};

export function CategoryField({ source, ...restProps }: CategoryFieldProps) {
  const renderCategoryField = useCallback(
    (props: DoctorPatientDto) => {
      const category: PatientCategoryEnum = get(props, source, '');
      if (!category) return '-';
      const id = get(props, 'id');

      if (!id) return <PatientCategoryBadge category={category} />;

      return (
        <Link to={`${constProvider.RESOURCES.PATIENT.URI}/${id}`} style={linkStyle}>
          <PatientCategoryBadge category={category} />
        </Link>
      );
    },
    [source]
  );

  return <FunctionField source={source} render={renderCategoryField} {...restProps} />;
}
