import React from 'react';
import { Create } from 'react-admin';
import { PageContentWithTitle } from 'components/common';
import { PatientForm } from '../common/patient-form/PatientForm';
import { patientConsts } from '../Patient.const';

export function PatientCreate(props: any) {
  return (
    <PageContentWithTitle title={patientConsts.CREATE_TITLE}>
      <Create {...props} title={patientConsts.PAGE_TITLE}>
        <PatientForm />
      </Create>
    </PageContentWithTitle>
  );
}
