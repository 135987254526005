import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { Edit, useTranslate } from 'react-admin';
import { Tabs, Tab } from '@material-ui/core';
import * as Types from 'AppTypes';
import { getDevice } from 'features/resources/selectors';
import { PageContentWithTitle, TabPanel, EditContentWrapper } from 'components/common';
import { deviceConsts } from '../Device.const';
import { DeviceForm } from './device-form/DeviceForm';
import { DeviceMeasuringDevice } from './device-measuring-device/device-measuring-device';

export function DeviceEdit(props: any) {
  const translate = useTranslate();
  const device = useSelector((state: Types.RootState) => getDevice(state, props.id));
  const [selectedTabValue, setSelectedTabValue] = useState<number>(0);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setSelectedTabValue(newValue);
    },
    [setSelectedTabValue]
  );

  const titleInterpolation = useMemo(() => ({ name: device?.serialNumber }), [device]);

  const renderContent = (editProps: any) => {
    return (
      <>
        <Tabs
          value={selectedTabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="Device tabs"
        >
          <Tab label={translate(deviceConsts.TABS.PARAMETERS_TAB)} />
          <Tab label={translate(deviceConsts.TABS.HISTORY_TAB)} disabled />
          <Tab label={translate(deviceConsts.TABS.MEASURING_DEVICES_TAB)} />
          <Tab label={translate(deviceConsts.TABS.PROGRAMMING_TAB)} disabled />
        </Tabs>
        <TabPanel index={0} value={selectedTabValue}>
          <DeviceForm {...editProps} />
        </TabPanel>
        <TabPanel index={2} value={selectedTabValue}>
          <DeviceMeasuringDevice {...editProps} />
        </TabPanel>
      </>
    );
  };

  return (
    <PageContentWithTitle
      title={!isNil(device) ? deviceConsts.EDIT_HEADER : undefined}
      titleInterpolation={titleInterpolation}
    >
      <Edit {...props} title={translate(deviceConsts.EDIT_TITLE)}>
        <EditContentWrapper>{(editProps: any) => renderContent(editProps)}</EditContentWrapper>
      </Edit>
    </PageContentWithTitle>
  );
}
