import { makeStyles } from '@material-ui/core/styles';
import { fontWeight } from 'style';

export const useStyles = makeStyles(() => ({
  measurementContent: {
    padding: '16px 18px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    '&:last-child': {
      paddingBottom: '10px'
    }
  },
  measurementValue: {
    margin: '16px 0',
    fontWeight: fontWeight.medium
  },
  bottom: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  bottomIcon: {
    width: '18px',
    height: '18px',
    marginRight: '8px'
  }
}));
