import { useMemo } from 'react';
import { usePermissions } from 'react-admin';
import { UserPermissions } from '../../components/resources/user';

export class Privileges {
  private readonly set: Set<UserPermissions>;
  constructor(privileges: UserPermissions[]) {
    this.set = new Set<UserPermissions>(privileges);
  }
  public all(privileges: UserPermissions | UserPermissions[]): boolean;
  public all<T>(privileges: UserPermissions | UserPermissions[], subject: T): T | null;
  public all<T>(privileges: UserPermissions | UserPermissions[], subject?: T): T | null | boolean {
    const [yes, no] = subject ? [subject, null] : [true, false];
    return ([] as UserPermissions[]).concat(privileges).every((role) => this.set.has(role)) ? yes : no;
  }
  public any(privileges: UserPermissions | UserPermissions[]): boolean;
  public any<T>(privileges: UserPermissions | UserPermissions[], subject: T): T | null;
  public any<T>(privileges: UserPermissions | UserPermissions[], subject?: T): T | null | boolean {
    const [yes, no] = subject ? [subject, null] : [true, false];
    return ([] as UserPermissions[]).concat(privileges).some((role) => this.set.has(role)) ? yes : no;
  }
}

export function usePermissionsMap() {
  const { permissions } = usePermissions();
  return useMemo(() => new Privileges(permissions), [permissions]);
}
