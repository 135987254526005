export const patientConsts = Object.freeze({
  PAGE_TITLE: 'resources.patient.name',
  LIST_TITLE: 'resources.patient.list.title',
  CREATE_TITLE: 'resources.patient.create.title',
  EDIT_TITLE: 'resources.patient.edit.title',
  TAB: {
    PATIENT_DETAILS: {
      LABEL: 'resources.patient.edit.patientDetails.label'
    },
    DASHBOARD: {
      LABEL: 'resources.patient.edit.dashboard.label'
    },
    LOCALIZATION: {
      LABEL: 'resources.patient.edit.localization.label'
    },
    DEVICES: {
      LABEL: 'resources.patient.edit.device.label',
      UNASSIGNED: {
        TITLE: 'resources.patient.edit.device.unassignedTitle'
      }
    }
  }
});
