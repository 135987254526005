import { cond, constant, matches } from 'lodash';

import { colors } from 'style/colors';

const lineColor = [colors.green, colors.primaryBlue, colors.primaryBlueDark];
export const getLineColor = cond([
  [matches('TEMPERATURE'), constant(lineColor[0])],
  [matches('SATURATION'), constant(lineColor[1])],
  [matches('PULSE'), constant(lineColor[2])]
]);

export const getValues = cond([
  [matches('TEMPERATURE'), constant([36, 37.4, 39])],
  [matches('SATURATION'), constant([89, 81, 50])],
  [matches('PULSE'), constant([0, 80, 100])]
]);
const annotationColor = [colors.lightGreen, colors.yellow, colors.red];
export const getAnnotations = (chartType: string) => {
  return getValues(chartType).map((v, i) => {
    return {
      type: 'line' as 'line',
      id: 'hline' + i,
      mode: 'horizontal' as 'horizontal',
      scaleID: 'y-axis-0',
      value: v,
      borderColor: annotationColor[i],
      borderWidth: 1
    };
  });
};
