import { makeStyles } from '@material-ui/core';
import { fontWeight } from 'style';

export const useStyles = makeStyles((theme) => ({
  wideDivider: {
    width: 'calc(100% + 120px)',
    margin: '30px -60px'
  },
  sectionTitle: {
    marginBottom: '1.375em'
  },
  deviceDetails: {
    color: theme.palette.text.secondary,
    fontWeight: fontWeight.medium
  },
  searchAvailableModal: {
    width: '70%',
    minWidth: '900px'
  },
  statusValue: {
    color: theme.palette.text.primary
  },
  cancelButton: {
    marginRight: '30px'
  },
  row: {
    paddingLeft: '3.5em'
  },
  subtitle: {
    marginLeft: '2.3em',
    marginRight: '2.3em',
    marginTop: '1.625em',
    marginBottom: '2.56em',
    color: theme.palette.text.secondary,
    fontWeight: fontWeight.medium
  }
}));
