import React, { useMemo } from 'react';
import { TextInput, SelectInput, Filter, useTranslate, DateInput, ReferenceInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { constProvider } from 'providers';
import { dashboardDoctorConsts } from '../dashboard-doctor.const';

const useStyles = makeStyles({
  input: {
    minWidth: '220px'
  },
  filters: {
    margin: '20px'
  }
});

const genderChoices = Object.entries(constProvider.GENDER_TO_LABEL).map(([id, name]) => ({ id, name }));

type DashboardDoctorFilters = { className?: string } & any;

export function DashboardDoctorFilters({ className, ...props }: DashboardDoctorFilters) {
  const translate = useTranslate();
  const classes = useStyles();

  const filterClassName = useMemo(() => [classes.filters, className], [className, classes.filters]);

  return (
    <Filter {...props} className={filterClassName}>
      <DateInput
        alwaysOn
        source="examDateFrom"
        label={translate(dashboardDoctorConsts.FILTERS.EXAM_DATE_FROM)}
        className={classes.input}
      />
      <DateInput
        alwaysOn
        source="examDateTo"
        label={translate(dashboardDoctorConsts.FILTERS.EXAM_DATE_TO)}
        className={classes.input}
        resettable
      />
      <ReferenceInput
        className={classes.input}
        source="medicalCenterId"
        reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
        alwaysOn
        label={translate(dashboardDoctorConsts.FILTERS.MEDICAL_CENTER)}
        resettable
      >
        <SelectInput variant="standard" />
      </ReferenceInput>
      <TextInput
        className={classes.input}
        label={translate(dashboardDoctorConsts.FILTERS.DEVICE_SERIAL_NUMBER)}
        id="standard-basic"
        source="deviceSerialNumber"
        alwaysOn
        resettable
      />
      <TextInput
        className={classes.input}
        label={translate(dashboardDoctorConsts.FILTERS.FIRST_NAME)}
        id="standard-basic"
        source="firstName"
        alwaysOn
        resettable
      />
      <TextInput
        className={classes.input}
        label={translate(dashboardDoctorConsts.FILTERS.LAST_NAME)}
        id="standard-basic"
        source="lastName"
        alwaysOn
        resettable
      />
      <TextInput
        className={classes.input}
        label={translate(dashboardDoctorConsts.FILTERS.PESEL)}
        id="standard-basic"
        source="pesel"
        alwaysOn
        resettable
      />
      <SelectInput
        label={translate(dashboardDoctorConsts.FILTERS.GENDER)}
        source="gender"
        alwaysOn
        fullWidth
        choices={genderChoices}
        resettable
      />
      <DateInput
        alwaysOn
        source="statusFrom"
        label={translate(dashboardDoctorConsts.FILTERS.STATUS_FROM)}
        className={classes.input}
        resettable
      />
      <DateInput
        alwaysOn
        source="statusTo"
        label={translate(dashboardDoctorConsts.FILTERS.STATUS_TO)}
        className={classes.input}
        resettable
      />
      <TextInput
        className={classes.input}
        label={translate(dashboardDoctorConsts.FILTERS.STATUS)}
        id="standard-basic"
        source="patientStatus"
        alwaysOn
        resettable
      />
    </Filter>
  );
}
