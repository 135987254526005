import React, { useState, useCallback, useEffect, ChangeEvent, useRef } from 'react';
import { find, isString } from 'lodash';
import { useField } from 'react-final-form';
import * as Nominatim from 'nominatim-browser';
import { Autocomplete } from '@material-ui/lab';
import { Box, IconButton, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './address-field.styles';

type AddressFieldProps = {
  addressName: string;
  longitudeName: string;
  latitudeName: string;
  inputProps: any;
};

export function AddressField({ longitudeName, latitudeName, addressName, inputProps }: AddressFieldProps) {
  const classes = useStyles();
  const {
    input: { onChange: onAddressChange, value: addressValue },
    meta: { touched, error }
  } = useField(addressName);
  const {
    input: { onChange: onLonChange }
  } = useField(longitudeName);
  const {
    input: { onChange: onLatChange }
  } = useField(latitudeName);
  const onAddressChangeRef = useRef(onAddressChange);
  const onLonChangeRef = useRef(onLonChange);
  const onLatChangeRef = useRef(onLatChange);
  const [value, setValue] = React.useState<string | Nominatim.NominatimResponse | undefined>('');
  const [inputValue, setInputValue] = React.useState<string>('');
  const [suggestions, setSuggestions] = useState<Nominatim.NominatimResponse[]>([]);
  const [suggestionVisible, setSuggestionsVisible] = useState<boolean>(false);

  const onSuggestsLookup = useCallback((userInput: string) => {
    Nominatim.geocode({
      q: userInput,
      addressdetails: false
    }).then((results: Nominatim.NominatimResponse[]) => {
      setSuggestions(results);
    });
  }, []);

  useEffect(() => {
    onSuggestsLookup(addressValue);
  }, [onSuggestsLookup, addressValue]);

  useEffect(() => {
    const suggest = find(suggestions, { display_name: addressValue });

    if (suggest) {
      setValue(suggest);
      setInputValue(suggest.display_name);
    }
  }, [suggestions, addressValue]);

  const toggleRenderSuggestions = useCallback(() => {
    if (!suggestionVisible) {
      onSuggestsLookup(inputValue);
    }

    setSuggestionsVisible(!suggestionVisible);
  }, [suggestionVisible, inputValue, onSuggestsLookup]);

  const onChange = useCallback(
    (event: ChangeEvent<{}>, newValue: string | Nominatim.NominatimResponse | null) => {
      if (!newValue || isString(newValue)) {
        return;
      }

      const suggest = find(suggestions, { display_name: newValue.display_name });

      if (suggest) {
        onAddressChangeRef.current(suggest.display_name);
        onLonChangeRef.current(suggest.lon);
        onLatChangeRef.current(suggest.lat);
      }

      setValue(newValue.display_name);
      setSuggestionsVisible(false);
    },
    [suggestions]
  );

  const onInputChange = useCallback((event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);

    if (newInputValue === '') {
      onAddressChangeRef.current('');
    }
  }, []);

  const getOptionLabel = useCallback((option: string | Nominatim.NominatimResponse) => {
    if (isString(option)) {
      return option;
    }

    if (option.display_name) {
      return option.display_name;
    }

    return '';
  }, []);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Autocomplete
        className={classes.addressField}
        open={suggestionVisible}
        onOpen={toggleRenderSuggestions}
        onClose={toggleRenderSuggestions}
        value={value}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        options={suggestions}
        getOptionLabel={getOptionLabel}
        filterOptions={(x) => x}
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        renderInput={(params: any) => (
          <TextField {...params} {...inputProps} error={!!(touched && error)} helperText={touched && error} />
        )}
      />
      <IconButton className={classes.searchButton} aria-label="search" onClick={toggleRenderSuggestions}>
        <SearchIcon className={classes.searchIcon} />
      </IconButton>
    </Box>
  );
}
