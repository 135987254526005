import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { ReferenceInput, required, SelectInput, useTranslate } from 'react-admin';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { MedicalcenterSelectionFormModel } from './MedicalcenterSelectionFormModel';
import { constProvider } from 'providers';
import { colors } from 'style';

export function MedicalcenterSelectionForm(formProps: FormRenderProps<MedicalcenterSelectionFormModel>) {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <form>
      <Box className={classes.form}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <ReferenceInput
              className={classes.input}
              source="id"
              reference={constProvider.RESOURCES.USER_MEDICALCENTER.URI}
              label="medicalcenterSelection.label"
              validate={required()}
              alwaysOn
              variant="standard"
            >
              <SelectInput />
            </ReferenceInput>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button
          className={classes.submit}
          onClick={formProps.handleSubmit}
          variant="contained"
          color="primary"
          type="submit"
        >
          {translate('medicalcenterSelection.button.submit')}
        </Button>
      </Box>
    </form>
  );
}

const useStyles = makeStyles({
  form: {
    marginBottom: '20px'
  },
  input: {
    width: '100%'
  },
  submit: {
    width: '100%',
    backgroundColor: colors.darkBlue
  }
});
