import { isEmpty, trim } from 'lodash';

const PHONE_REGEX = /\+(1|7|\d{2,3}?)(\d{9,10}?)$/;

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

export const validateRegex = (value: string, regex: RegExp, message: string) =>
  !isEmpty(value) && !regex.test(value) ? message : undefined;

export const validatePhoneNumber = (value: string, message: string) => validateRegex(value, PHONE_REGEX, message);

export const validateEmail = (value: string, message: string) => validateRegex(value, EMAIL_REGEX, message);

export const peselValidation = (value: string | undefined, message = 'validation.peselInvalidMessage') =>
  isPeselValid(trim(value)) ? undefined : message;

function isPeselValid(pesel: string | undefined | null): boolean {
  if (!pesel || pesel.length !== 11) {
    return false;
  }

  const controlNumber = parseInt(pesel.charAt(10));
  const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  const sum = weights.reduce((acc, weight, index) => acc + parseInt(pesel.charAt(index)) * weight, 0) % 10;

  return (10 - sum) % 10 === controlNumber;
}
