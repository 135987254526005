import { Grid, Typography, Switch } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { useTranslate } from 'react-admin';
import { UserPermissions } from '../../User.const';
import { useStyles } from '../user-form/UsersForm.styles';

export interface Row {
  privilegeName: string;
  values: { name: string }[];
}

interface PrivilegesRowProps {
  availablePrivileges: Set<UserPermissions>;
  row: Row;
  privileges: string[];
  setPrivilege: (event: ChangeEvent<any>, value: boolean) => void;
}

export function PrivilegesRow({
  availablePrivileges,
  row: { values, privilegeName },
  setPrivilege,
  privileges = []
}: PrivilegesRowProps) {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      <Grid item xs={2}>
        <Typography variant="subtitle1" className={classes.privilegeSubtitle}>
          {translate(privilegeName)}
        </Typography>
      </Grid>
      {values.map(({ name }, i) =>
        name === '' ? (
          <Grid key={`name_empty_${i}`} item xs={2} />
        ) : (
          <Grid key={name} item xs={2}>
            <Switch
              disabled={!availablePrivileges.has(name as UserPermissions)}
              color="primary"
              name={name}
              onChange={setPrivilege}
              defaultChecked={privileges.includes(name)}
            />
          </Grid>
        )
      )}
    </Grid>
  );
}
