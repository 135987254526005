import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FilterListOutlined from '@material-ui/icons/FilterListOutlined';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

type UserActionsProps = { toggleFilters: () => void; className?: string };

export function DashboardDoctorActions({ toggleFilters, className }: UserActionsProps) {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)}>
      <IconButton aria-label="Filters" onClick={toggleFilters}>
        <FilterListOutlined />
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    margin: '0 20px 20px 0'
  }
});
