import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'style';

export const useStyles = makeStyles({
  container: {
    margin: '24px'
  },
  pullRight: {
    alignItems: 'flex-end'
  },
  flexStart: {
    justifySelf: 'flex-start'
  },
  autoMarginLeft: {
    marginLeft: 'auto'
  },
  tabContent: {
    display: 'block',
    padding: '16px 2px',
    backgroundColor: 'white',
    marginTop: -8
  },
  subtitle: {
    fontWeight: 500
  },
  previousButton: {
    marginRight: '30px',
    border: `1px solid ${colors.lightBlue}`
  },
  cancelButton: {
    marginRight: '30px'
  },
  typeInput: {
    width: '100%'
  },
  activeSwitch: {
    float: 'right'
  }
});
