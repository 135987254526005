export const colors = {
  white: '#ffffff',

  // black
  black54: 'rgba(0,0,0,0.54)',
  black87: 'rgba(0,0,0,0.87)',

  // gray
  lightGray: '#f5f5f5',
  darkGray: '#666',
  gray: '#cccccc',
  gray137: 'rgb(137, 137, 137)',

  //textColors
  hightEmphasis: 'rgba(255, 255, 255, 0.87)',
  mediumEmphasis: 'rgba(255, 255, 255, 0.60)',
  Disabled: 'rgba(255, 255, 255, 0.38)',

  // blue
  darkBlue: '#004BA0',
  darkerBlue: '#3c7bba',
  primaryBlue: '#1976d2',
  primaryBlueDark: '#0d47a1',
  lightBlue: '#63A4FF',
  lighterBlue: '#B1D1FF',

  // green
  green: '#00ACC1',
  lightGreen: '#00c853',
  lighterGreen: '#33d375',
  darkGreen: '#0097a7',

  // yellow
  yellow: '#fbc02d',
  darkYellow: '#fd8d00',

  // red
  red: '#ff3535',
  darkRed: '#da3549',
  darkerRed: '#d0021b'
};
