export const dashboardConsts = Object.freeze({
  TABS: {
    DOCTOR: {
      TITLE: 'dashboard.doctor.title'
    },
    ADMIN: {
      TITLE: 'dashboard.admin.title'
    }
  }
});

export enum DASHBOARDS {
  ADMIN = 'admin',
  DOCTOR = 'doctor'
}
