import { fontWeight } from 'style';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'style/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 18px 0',
    minHeight: '100%',
    marginRight: '10px'
  },
  patientsNumber: {
    fontWeight: fontWeight.medium,
    [theme.breakpoints.up('xs')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center'
    },
    margin: '30px 0'
  },
  listItem: {
    fontSize: '12px',
    position: 'relative',
    padding: '3px 0',
    '&:before': {
      content: '""',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      background: colors.lightGray,
      display: 'block',
      marginRight: '8px'
    }
  },
  emergencyCount: {
    '&:before': {
      background: colors.darkRed
    }
  },
  warningCount: {
    '&:before': {
      background: colors.darkYellow
    }
  },
  healthyCount: {
    '&:before': {
      background: colors.lightGreen
    }
  },
  notValidCount: {
    '&:before': {
      background: colors.darkerBlue
    }
  },
  unknownCount: {
    '&:before': {
      background: colors.gray
    }
  },
  indicator: {
    width: '100px'
  }
}));
