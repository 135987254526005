import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslate, useQueryWithStore, LinearProgress } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { medicalCenterFormConsts } from '../MedicalCenterForm.const';
import { constProvider } from 'providers';
import { colors } from 'style';

interface SuperiorMedicalcenterProps {
  medicalcenterId: string | number;
}

export function SuperiorMedicalcenter({ medicalcenterId }: SuperiorMedicalcenterProps) {
  const translate = useTranslate();
  const classes = useStyles();

  const { loading, error, data: { name } = { name: undefined } } = useQueryWithStore({
    type: 'getOne',
    resource: constProvider.RESOURCES.MEDICAL_CENTER.URI,
    payload: { id: medicalcenterId }
  });

  if (error) {
    return null;
  }

  return (
    <Typography variant="subtitle1" className={classes.subtitle}>
      {translate(medicalCenterFormConsts.SUPERIOR_MEDICALCENTER_NAME_SUBTITLE)}
      &nbsp;
      {loading ? (
        <LinearProgress className={classes.linearProgress} />
      ) : (
        <span className={classes.medicalcenterName}>{name}</span>
      )}
    </Typography>
  );
}

export const useStyles = makeStyles({
  subtitle: {
    fontWeight: 500,
    color: colors.black54
  },
  linearProgress: {
    display: 'inline-block',
    position: 'relative',
    top: '5px'
  },
  medicalcenterName: {
    fontWeight: 500,
    color: colors.black87
  }
});
