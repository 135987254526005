import { Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslate, Edit } from 'react-admin';
import { EditContentWrapper, PageContentWithTitle, TabPanel } from 'components/common';
import { UserDetails } from '../common/user-details/user-details';
import { UsersForm } from '../common/user-form/UsersForm';
import { UserPrivileges } from '../common/user-privileges/user-privileges';
import { userConsts } from '../User.const';

const detailsStep = [UserDetails];
const privilegesStep = [UserPrivileges];

export function UsersEdit(props: any) {
  const translate = useTranslate();
  const [selectedTabValue, setSelectedTabValue] = useState<number>(0);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<any>, newValue: number) => {
      setSelectedTabValue(newValue);
    },
    [setSelectedTabValue]
  );
  const renderContent = (editProps: any) => {
    return (
      <>
        <Tabs
          value={selectedTabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label={translate(userConsts.TABS.LABEL)}
        >
          <Tab label={translate(userConsts.TABS.DETAILS)} />
          <Tab label={translate(userConsts.TABS.PERMISSION)} />
        </Tabs>
        <TabPanel index={0} value={selectedTabValue}>
          <UsersForm isEditMode {...editProps} steps={detailsStep} />
        </TabPanel>
        <TabPanel index={1} value={selectedTabValue}>
          <UsersForm isEditMode {...editProps} steps={privilegesStep} />
        </TabPanel>
      </>
    );
  };

  return (
    <PageContentWithTitle title={userConsts.EDIT_TITLE}>
      <Edit {...props} title={translate(userConsts.PAGE_TITLE)}>
        <EditContentWrapper>{(editProps: any) => renderContent(editProps)}</EditContentWrapper>
      </Edit>
    </PageContentWithTitle>
  );
}
