enum GENDER {
  'MAN' = 'MAN',
  'WOMAN' = 'WOMAN'
}

const GENDER_TO_LABEL = {
  [GENDER.MAN]: 'common.gender.man',
  [GENDER.WOMAN]: 'common.gender.woman'
};

enum PATIENT_STATUS {
  'NEWLY_REGISTERED' = 'NEWLY_REGISTERED',
  'CALL_EMERGENCY' = 'CALL EMERGENCY',
  'CALL_POLICE' = 'CALL POLICE',
  'NOTIFY_PATIENT' = 'NOTIFY PATIENT',
  'MEDICAL_CONSULTATION' = 'MEDICAL CONSULTATION'
}

const PATIENT_STATUS_TO_LABEL = {
  [PATIENT_STATUS.NEWLY_REGISTERED]: 'resources.patient.edit.dashboard.status.newlyRegistered',
  [PATIENT_STATUS.CALL_EMERGENCY]: 'resources.patient.edit.dashboard.status.callEmergency',
  [PATIENT_STATUS.CALL_POLICE]: 'resources.patient.edit.dashboard.status.callPolice',
  [PATIENT_STATUS.NOTIFY_PATIENT]: 'resources.patient.edit.dashboard.status.notifyPatient',
  [PATIENT_STATUS.MEDICAL_CONSULTATION]: 'resources.patient.edit.dashboard.status.medicalConsultation'
};

enum MEASUREMENT_CATEGORY {
  'EMERGENCY' = 'EMERGENCY',
  'WARNING' = 'WARNING',
  'HEALTHY' = 'HEALTHY',
  'NOT_VALID' = 'NOT_VALID'
}

enum MEASUREMENT_DEVICE_TYPE {
  SCALE = 'SCALE',
  BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
  PULSE_OXIMETER = 'PULSE_OXIMETER',
  GLUCOMETER = 'GLUCOMETER',
  BLOOD_PRESSURE_AND_GLUCOMETER = 'BLOOD_PRESSURE_AND_GLUCOMETER',
  THERMOMETER = 'THERMOMETER'
}

enum MEASUREMENT_CONNECTION_TYPE {
  WIRE = 'WIRE',
  BT_2_0 = 'BT_2_0',
  BT_4_0 = 'BT_4_0'
}

const MEASUREMENT_CATEGORY_TO_LABEL = {
  [MEASUREMENT_CATEGORY.EMERGENCY]: 'common.measurementCategory.emergency',
  [MEASUREMENT_CATEGORY.WARNING]: 'common.measurementCategory.warning',
  [MEASUREMENT_CATEGORY.HEALTHY]: 'common.measurementCategory.healthy',
  [MEASUREMENT_CATEGORY.NOT_VALID]: 'common.measurementCategory.notValid'
};

enum USER_ROLES {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  NURSE = 'NURSE',
  PARAMEDIC = 'PARAMEDIC',
  DOCTOR = 'DOCTOR'
}

export enum PRIVILEGES {
  LIST_VIEW = 'LIST_VIEW',
  READ_ONLY_PROPERTIES = 'READ_ONLY_PROPERTIES',
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

export enum SECTIONS_PRIVILEGES {
  USERS = 'SECTION_USERS',
  PATIENTS = 'SECTION_PATIENTS',
  RESULTS = 'SECTION_RESULTS',
  DEVICES = 'SECTION_DEVICES',
  MEASUREMENTS_DEVICE = 'SECTION_MEASUREMENTS_DEVICE',
  MEDICAL_CENTERS = 'SECTION_MEDICAL_CENTERS',
  REPORTS = 'SECTION_REPORTS'
}

export enum DASHBOARDS_PRIVILEGES {
  ADMIN_DASHBOARD = 'DASHBOARDS_ADMIN_DASHBOARD',
  PATIENT_DASHBOARD = 'DASHBOARDS_PATIENT_DASHBOARD',
  DOCTORS_DASHBOARD = 'DASHBOARDS_DOCTORS_DASHBOARD',
  PARAMEDIC_DASHBOARD = 'DASHBOARDS_PARAMEDIC_DASHBOARD'
}

export enum SPECIFIC_PRIVILEGES {
  DEVICE_ASSIGNEMENT = 'SPECIFIC_DEVICE_ASSIGNEMENT',
  DEFINE_GEOLOCATION_AREA = 'SPECIFIC_DEFINE_GEOLOCATION_AREA',
  DEFINE_SMS_NOTIFICATION = 'SPECIFIC_DEFINE_SMS_NOTIFICATION',
  IMPORT_EXPORT_SCP_FILES = 'SPECIFIC_IMPORT_EXPORT_SCP_FILES',
  PATIENT_CARD = 'SPECIFIC_PATIENT_CARD',
  DEVICE_PROGRAMMING = 'SPECIFIC_DEVICE_PROGRAMMING',
  FIRMWARE_UPDATE = 'SPECIFIC_FIRMWARE_UPDATE'
}

enum MEASUREMENT_STATUS {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  BLUE = 'BLUE'
}

enum PATIENT_CATEGORY {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  UNKNOWN = 'UNKNOWN'
}

const PATIENT_COLOR_TO_CATEGORY = {
  [PATIENT_CATEGORY.RED]: 'Alarm',
  [PATIENT_CATEGORY.YELLOW]: 'Warning',
  [PATIENT_CATEGORY.GREEN]: 'Ok',
  [PATIENT_CATEGORY.BLUE]: 'No data',
  [PATIENT_CATEGORY.UNKNOWN]: 'Not ready'
};

enum PATIENT_LOCKDOWN_STATUS {
  IN = 'IN',
  OUT = 'OUT'
}

export class Resource {
  public readonly URI: string;
  public readonly LABEL?: string;
  public readonly SORT_FIELD_MAP?: { [key: string]: string };
  public readonly USE_PAGINATION?: boolean;
  public readonly USE_ORGANIZATION_PATH_HEADER?: boolean;

  constructor({ URI, LABEL, SORT_FIELD_MAP, USE_PAGINATION, USE_ORGANIZATION_PATH_HEADER }: Resource) {
    this.URI = URI;
    this.LABEL = LABEL;
    this.SORT_FIELD_MAP = SORT_FIELD_MAP;
    this.USE_PAGINATION = USE_PAGINATION;
    this.USE_ORGANIZATION_PATH_HEADER = USE_ORGANIZATION_PATH_HEADER;
  }
}

const RESOURCES = {
  DASHBOARD: new Resource({
    LABEL: 'resources.dashboard.name',
    URI: ''
  }),
  MEDICAL_CENTER: new Resource({
    URI: process.env.REACT_APP_MEDICALCENER_URI as string,
    LABEL: 'resources.medicalcenter.name',
    SORT_FIELD_MAP: {
      city: 'locationAddress.city'
    }
  }),
  EXAMINATION: new Resource({
    URI: process.env.REACT_APP_EXAMINATION_URI as string,
    LABEL: 'resources.examination.name'
  }),
  DEVICE: new Resource({
    URI: process.env.REACT_APP_DEVICE_URI as string,
    LABEL: 'resources.device.name'
  }),
  AVAILABLE_DEVICE: new Resource({
    URI: process.env.REACT_APP_AVAILABLE_DEVICE_URI as string
  }),
  MEASURING_DEVICE: new Resource({
    URI: process.env.REACT_APP_MEASURING_DEVICE_URI as string,
    LABEL: 'resources.measuringdevice.name',
    SORT_FIELD_MAP: {
      addressBDA: 'bda',
      model: 'model.name',
      type: 'model.type'
    }
  }),
  MEASURING_DEVICE_MEDICAL_CENTER: new Resource({
    URI: process.env.REACT_APP_MEASURINGDEVICE_MEDICALCENTER_URI as string
  }),
  PATIENT: new Resource({
    URI: process.env.REACT_APP_PATIENT_URI as string,
    LABEL: 'resources.patient.name'
  }),
  USER: new Resource({
    URI: process.env.REACT_APP_USER_URI as string,
    LABEL: 'resources.user.name'
  }),
  USER_MEDICALCENTER: new Resource({
    URI: process.env.REACT_APP_USER_MEDICALCENTER_URI as string,
    USE_ORGANIZATION_PATH_HEADER: false,
    USE_PAGINATION: false
  }),
  USER_PRIVILEGES: new Resource({
    URI: process.env.REACT_APP_USER_PRIVILEGES_URI as string
  }),
  DEVICE_TYPES: new Resource({
    URI: process.env.REACT_APP_DEVICE_TYPES_URI as string,
    USE_PAGINATION: false
  }),
  MEDICALCENTER_SUBCENTER: new Resource({
    URI: process.env.REACT_APP_MEDICALCENTER_SUBCENTER_URI as string
  }),
  LOCATION_LOCKDOWN: new Resource({
    URI: process.env.REACT_APP_LOCATION_LOCKDOWN_URI as string
  }),
  PATIENT_LOCATION: new Resource({
    URI: process.env.REACT_APP_PATIENT_LOCATION_URI as string
  }),
  PATIENT_DEVICE: new Resource({
    URI: process.env.REACT_APP_PATIENT_DEVICE_URI as string
  }),
  DEVICE_LOCATION: new Resource({
    URI: process.env.REACT_APP_DEVICE_LOCATION_URI as string
  }),
  DEVICE_MEASURINGDEVICE: new Resource({
    URI: process.env.REACT_APP_DEVICE_MEASURINGDEVICE_URI as string
  }),
  AVAILABLE_MEASURINGDEVICE: new Resource({
    URI: process.env.REACT_APP_AVAILABLE_MEASURINGDEVICE_URI as string
  }),
  DOCTOR_PATIENT_MEASUREMENT: new Resource({
    URI: process.env.REACT_APP_DOCTOR_PATIENT_MEASUREMENT_URI as string
  }),
  MEDICALCENTER_FILTER_SELECT: new Resource({
    URI: process.env.REACT_APP_MEDICALCENTER_FILTER_SELECT_URI as string,
    USE_PAGINATION: false
  }),
  MEDICALCENTER_SUBCENTER_FILTER_SELECT: new Resource({
    URI: process.env.REACT_APP_MEDICALCENTER_SUBCENTER_FILTER_SELECT_URI as string,
    USE_PAGINATION: false
  }),
  PATIENT_CATEGORY_STATS: new Resource({
    URI: process.env.REACT_APP_PATIENT_CATEGORY_STATS_URI as string
  }),
  PATIENT_MEASUREMENT_EXCEED_STATS: new Resource({
    URI: process.env.REACT_APP_PATIENT_MEASUREMENT_EXCEED_STATS_URI as string
  }),
  ROLE_PRIVILEGE: new Resource({
    URI: process.env.REACT_APP_ROLE_PRIVILEGE as string,
    USE_PAGINATION: false
  }),
  ADMIN_MEDICALCENTER: new Resource({
    URI: process.env.REACT_APP_ADMIN_MEDICALCENTER_URI as string,
    USE_PAGINATION: false
  })
} as const;

export const constProvider = Object.freeze({
  VERSION: process.env.REACT_APP_VERSION as string,
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL as string,
  AUTH_USER_PRIVILEGES_URL: `${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_AUTH_USER_PRIVILEGES_URL}`,
  AUTH_TOKEN_REFRESH_ENDPOINT_URL: `${process.env.REACT_APP_BASE_AUTH_API_URL}/${process.env.REACT_APP_AUTH_TOKEN_REFRESH_ENDPOINT_URI}`,
  AUTH_LOGIN_ENDPOINT_URL: `${process.env.REACT_APP_BASE_AUTH_API_URL}/${process.env.REACT_APP_AUTH_LOGIN_ENDPOINT_URI}`,
  AUTH_LOGOUT_ENDPOINT_URL: `${process.env.REACT_APP_BASE_AUTH_API_URL}/${process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT_URI}`,
  AUTH_LOGIN_AUTHORIZATION_SECRET: process.env.REACT_APP_AUTH_LOGIN_AUTHORIZATION_SECRET as string,
  RESOURCES,
  FORM_ERROR_TO_MESSAGE: {
    'MEDICAL CENTER ALREADY EXISTS': 'validation.medicalCenterAlreadyExists',
    MEASURING_DEVICE_AND_DEVICE_MEDICAL_CENTER_CONFLICT: 'validation.measuringDeviceAndDeviceMedicalCenterConflict'
  },
  DEFAULT_ERROR_MESSAGE: 'validation.defaultErrorMessage',
  PERMISSION_DENIED_MESSAGE: 'common.authorization.permissionDenied',
  GENDER_TO_LABEL,
  DATE_TIME_FORMAT: 'DD-MM-YYYY hh:mm:ss',
  MEDICALCENTER_SELECTION_PAGE_URI: '/medicalcenter-selection',
  LOGIN_PAGE_URI: '/login',
  USER_ROLES,
  PRIVILEGES,
  SECTIONS_PRIVILEGES,
  DASHBOARDS_PRIVILEGES,
  SPECIFIC_PRIVILEGES,
  PATIENT_STATUS_TO_LABEL,
  MEASUREMENT_CATEGORY_TO_LABEL,
  MEASUREMENT_STATUS,
  MEASUREMENT_CATEGORY,
  MEASUREMENT_DEVICE_TYPE,
  MEASUREMENT_CONNECTION_TYPE,
  PATIENT_CATEGORY,
  PATIENT_COLOR_TO_CATEGORY,
  PATIENT_LOCKDOWN_STATUS,
  BUTTONS: {
    CANCEL: 'common.button.cancel',
    NEXT: 'common.button.next',
    BACK: 'common.button.back'
  }
});
