export const hexToRgb = (hex: string): number[] | undefined => {
  const match = hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g);

  if (!match) {
    return;
  }

  return match.map((x) => parseInt(x, 16));
};
