import React from 'react';
import { UserMenu } from 'react-admin';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useStyles } from './CustomUserMenu.styles';

const CustomUserMenu = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.userMenu}>
      <div className={classes.namesContainer}>
        {/* TODO get name */}
        <p className={classes.name}>Jan Kowalski</p>
        <span className={classes.company}>Pro-plus</span>
      </div>
      <div className={classes.circle}>
        {/* TODO get initials */}
        <span className={classes.initials}>JK</span>
      </div>
      <UserMenu {...props} icon={<ArrowDropDownIcon />} className={classes.originalUserMenu} />
    </div>
  );
};

export default CustomUserMenu;
