export const deviceConsts = Object.freeze({
  LIST_TITLE: 'resources.device.name',
  LIST_HEADER: 'resources.device.list.header',
  EDIT_TITLE: 'resources.device.name',
  EDIT_SUBTITLE: 'resources.device.edit.subtitle',
  EDIT_HEADER: 'resources.device.edit.header',
  TABS: {
    PARAMETERS_TAB: 'resources.device.edit.tabs.parameters',
    HISTORY_TAB: 'resources.device.edit.tabs.history',
    MEASURING_DEVICES_TAB: 'resources.device.edit.tabs.measuringDevice',
    PROGRAMMING_TAB: 'resources.device.edit.tabs.programming'
  },
  FIELDS: {
    SERIAL_NUMBER: 'resources.device.edit.fields.serialNumber',
    AVAILABLE: 'resources.device.edit.fields.available',
    TYPE: 'resources.device.fields.deviceType'
  },
  AVAILABILITY: {
    AVAILABLE: 'common.deviceAvailability.available',
    NOT_AVAILABLE: 'common.deviceAvailability.notAvailable'
  },
  VALIDATION: {
    PHONE_NUMBER: 'validation.phoneNumberInvalidMessage'
  },
  BUTTONS: {
    CANCEL_BUTTON: 'common.button.cancel'
  },
  MEASURING_DEVICE: {
    LABELS: {
      TITLE: 'resources.device.measuringDevice.title',
      ASSIGN_TITLE: 'resources.device.measuringDevice.assignTitle',
      DEVICE_CHOICE_SUBTITLE: 'resources.device.measuringDevice.deviceChoiceSubtitle',
      ACTION: 'resources.device.measuringDevice.labels.action',
      UNLINK: 'resources.device.measuringDevice.labels.unlink',
      SERIAL_NUMBER: 'resources.device.measuringDevice.labels.serialNumber',
      TYPE: 'resources.device.measuringDevice.labels.type',
      SIM_NUMBER: 'resources.device.measuringDevice.labels.simNumber'
    }
  }
});
