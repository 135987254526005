import { makeStyles } from '@material-ui/core';
import { fontWeight } from 'style';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: ' 14px 30px 30px'
  },
  divider: {
    width: '100%',
    margin: '30px 0'
  },
  sectionHeader: {
    marginBottom: '18px',
    fontWeight: fontWeight.medium
  },
  status: {
    marginTop: '30px',
    color: theme.palette.text.secondary,
    fontWeight: fontWeight.medium
  },
  statusValue: {
    color: theme.palette.text.primary
  },
  cancelButton: {
    marginRight: '30px'
  }
}));
