import React, { useMemo, useCallback } from 'react';
import {
  BooleanInput,
  SelectInput,
  TextInput,
  required,
  ReferenceInput,
  email,
  useTranslate,
  SaveButton
} from 'react-admin';
import { Box, Grid, Toolbar, Typography, Button } from '@material-ui/core';
import { userDictionary } from '../../User.const';
import { UsersFormValues, UserStepProps } from '../user-form/UsersForm.models';
import { usersFormConsts } from '../user-form/UsersForm.const';
import { validatePhoneNumber } from 'services/validators';
import { useStyles } from '../user-form/UsersForm.styles';
import { constProvider } from 'providers';
import { includes } from 'lodash';
import { formatPhoneNumber } from 'services/utils';

type UserDetailsProps = UserStepProps;

export function UserDetails(props: UserDetailsProps) {
  const { isEditMode, resource, onCancelClick, onBackClick, formProps, hasPrev, hasNext } = props;
  const translate = useTranslate();
  const classes = useStyles();

  const phoneNumberValidator = useCallback(
    (value) => validatePhoneNumber(value, translate(usersFormConsts.VALIDATION.phoneNumber)),
    [translate]
  );

  const passwordValidator = useCallback(
    (value, allValues) =>
      value === allValues.password ? undefined : translate(usersFormConsts.VALIDATION.passwordValidation),
    [translate]
  );

  const phoneNumberFieldValidator = useMemo(() => [required(), phoneNumberValidator], [phoneNumberValidator]);

  const emailFieldValidator = useMemo(() => [required(), email(translate(usersFormConsts.VALIDATION.email))], [
    translate
  ]);

  const passwordFieldValidator = useMemo(() => {
    return [required(), passwordValidator];
  }, [passwordValidator]);

  const requiredForLimitedEditor = useCallback((value: string, allValues: UsersFormValues) => {
    const isLimitedEditor = includes([constProvider.USER_ROLES.NURSE, constProvider.USER_ROLES.DOCTOR], allValues.type);
    return isLimitedEditor ? required()(value) : undefined;
  }, []);

  return (
    <Box className={classes.container}>
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {translate(usersFormConsts.DETAILS_SUBTITLE)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <BooleanInput resource={resource} className={classes.activeSwitch} source="active" />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              resource={resource}
              className={classes.typeInput}
              source="type"
              alwaysOn
              choices={userDictionary.USER_ROLES}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput resource={resource} source="title" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput resource={resource} source="npvz" disabled={isEditMode} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput validate={required()} resource={resource} source="firstName" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput validate={required()} resource={resource} source="lastName" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextInput validate={required()} resource={resource} source="address" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} resource={resource} source="postalCode" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} resource={resource} source="city" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} resource={resource} source="country" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              validate={phoneNumberFieldValidator}
              resource={resource}
              source="phoneNumber"
              placeholder="+481234567890"
              parse={formatPhoneNumber}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput validate={emailFieldValidator} resource={resource} source="email" fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {translate(usersFormConsts.MEDICALCENTER_SUBTITLE)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              className={classes.typeInput}
              label={usersFormConsts.LABELS.MEDICAL_CENTER}
              source="medicalCenterId"
              reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
              validate={requiredForLimitedEditor}
            >
              <SelectInput alwaysOn />
            </ReferenceInput>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {translate(usersFormConsts.ACCOUNT_DETAILS_SUBTITLE)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              validate={passwordFieldValidator}
              resource={resource}
              source="password"
              fullWidth
              type="password"
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              validate={passwordFieldValidator}
              resource={resource}
              source="password2"
              type="password"
              fullWidth
            />
          </Grid>
        </Grid>
      </>
      <Toolbar className={classes.toolbar}>
        <Box>
          {hasPrev && (
            <Button color="primary" className={classes.cancelButton} onClick={onBackClick}>
              {translate(usersFormConsts.BUTTONS.backButtonCaption)}
            </Button>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button color="primary" className={classes.cancelButton} onClick={onCancelClick}>
            {translate(usersFormConsts.BUTTONS.cancelButtonCaption)}
          </Button>
          {hasNext ? (
            <Button color="primary" onClick={formProps.handleSubmit}>
              {translate(usersFormConsts.BUTTONS.nextButtonCaption)}
            </Button>
          ) : (
            <SaveButton saving={formProps.submitting} handleSubmitWithRedirect={formProps.handleSubmit} />
          )}
        </Box>
      </Toolbar>
    </Box>
  );
}
