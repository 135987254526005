import { makeStyles } from '@material-ui/core/styles';
import { fontWeight, colors } from '../../../../../style';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '24px'
  },
  privilegesSection: {
    marginBottom: '30px'
  },
  privilegeSubtitle: {
    fontWeight: fontWeight.medium,
    color: theme.palette.text.primary
  },
  subtitle: {
    fontWeight: fontWeight.medium,
    color: theme.palette.text.secondary
  },
  cancelButton: {
    '&:first-child': {
      border: `1px solid ${colors.lightBlue}`
    },
    marginRight: '30px'
  },
  typeInput: {
    width: '100%'
  },
  activeSwitch: {
    float: 'right'
  },
  privilege: {
    marginBottom: '24px',
    color: theme.palette.primary.main,
    fontWeight: fontWeight.medium
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0
  }
}));
