import React, { PropsWithChildren } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useStyles } from './values-card.styles';

type ValuesCardProps = PropsWithChildren<{
  header: string;
  items: Array<{
    label: string;
    value?: string | number;
  }>;
}>;

export function ValuesCard({ header, items, children }: ValuesCardProps) {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="h2" className={classes.header}>
          {header}:
        </Typography>
        {items.map((item, index) => (
          <Typography key={index} variant="body2" component="p" className={classes.item}>
            <span className={classes.label}>{item.label}:</span> {item.value ? item.value : '-'}
          </Typography>
        ))}
        {children}
      </CardContent>
    </Card>
  );
}
