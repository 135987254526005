import React, { useState, useCallback } from 'react';
import { Datagrid, Pagination } from 'react-admin';
import { constProvider } from 'providers';

import { TabPanel } from 'components/common';
import { CustomTab, CustomTabs } from '../../custom-tabs/custom-tabs';

export function CustomDatagrid(props: any) {
  const [selectedTabValue, setSelectedTabValue] = useState<number>(0);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setSelectedTabValue(newValue);
      props.category(newValue);
    },
    [setSelectedTabValue, props]
  );

  const renderCategoryTabs = () => {
    return Object.entries(constProvider.PATIENT_COLOR_TO_CATEGORY).map(([id, name]) => (
      <CustomTab key={id} label={name} />
    ));
  };

  const renderCategoryPanel = () => {
    return Object.entries(constProvider.PATIENT_COLOR_TO_CATEGORY).map(([id], index) => (
      <TabPanel index={index} value={selectedTabValue} key={id}>
        <Datagrid isRowSelectable={false} {...props} />
        <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 250]} {...props} />
      </TabPanel>
    ));
  };

  return (
    <>
      <CustomTabs value={selectedTabValue} onChange={handleTabChange} aria-label="Dashboard tabs" variant="fullWidth">
        {renderCategoryTabs()}
      </CustomTabs>
      {renderCategoryPanel()}
    </>
  );
}
