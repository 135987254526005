import React from 'react';
import { AppBar } from 'react-admin';

import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { useStyles, useClassesClosed, useClassesOpen } from './CustomAppBar.styles';
import { ReactComponent as Logo } from 'assets/company-logo.svg';
import { isSidebarOpen } from 'features/ui/selectors';
import ChangeLanguageDropdown from 'components/common/ChangeLanguageDropdown/ChangeLanguageDropdown';
import CustomUserMenu from './CustomUserMenu/CustomUserMenu';
import { MedicalcenterSelectionBarSelector } from './MedicalcenterSelectionBarSelector/MedicalcenterSelectionBarSelector';

const CustomAppBar = (props: any) => {
  const open = useSelector(isSidebarOpen);
  const classes = useStyles();
  const classesClosed = useClassesClosed();
  const classesOpen = useClassesOpen();
  const useClass = open ? classesOpen : classesClosed;

  return (
    <AppBar {...props} position="sticky" classes={useClass} className={classes.container} userMenu={<CustomUserMenu />}>
      <Typography variant="h6" color="textPrimary" className={classes.title} id="react-admin-title" />
      <Logo className={classes.logo} />
      <MedicalcenterSelectionBarSelector />
      <ChangeLanguageDropdown />
    </AppBar>
  );
};

export default CustomAppBar;
