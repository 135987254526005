import { useCallback } from 'react';

export function useToggleListItem<T>(list: T[], setter: (list: T[]) => void) {
  return useCallback(
    (item: T) => {
      const set = new Set(list);
      if (set.has(item)) {
        set.delete(item);
      } else {
        set.add(item);
      }
      setter([...set]);
    },
    [list, setter]
  );
}
