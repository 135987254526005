import { makeStyles } from '@material-ui/styles';
import { colors } from 'style';

export const useStyles = makeStyles((theme: any) => ({
  deviceLocationContainer: {
    marginBottom: '24px'
  },
  cancelButton: {
    marginRight: '24px'
  }
}));

export const useToolbarClasses = makeStyles((theme: any) => ({
  toolbar: {
    backgroundColor: colors.white,
    paddingLeft: 0,
    paddingRight: 0
  }
}));

export const useEditButtonClasses = makeStyles((theme: any) => ({
  root: {
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: colors.darkGreen
    }
  }
}));
