import React from 'react';
import { SelectInput } from 'react-admin';

interface ActiveFilterDropdownProps {
  source: string;
  alwaysOn?: boolean;
  allowEmpty?: boolean;
  className?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  label?: string;
}

export const statusFilterDropdownChoices = [
  { id: 'true', name: 'common.active.active' },
  { id: 'false', name: 'common.active.notActive' }
];

export function ActiveFilterDropdown({
  className,
  source,
  alwaysOn,
  variant,
  label = 'common.active.label',
  allowEmpty
}: ActiveFilterDropdownProps) {
  return (
    <SelectInput
      allowEmpty={allowEmpty}
      variant={variant}
      label={label}
      helperText={''}
      className={className}
      source={source}
      alwaysOn={alwaysOn}
      choices={statusFilterDropdownChoices}
    />
  );
}
