import { constProvider } from 'providers';

export const measuringDeviceConsts = Object.freeze({
  LIST_TITLE: 'resources.measuringdevice.title',
  LIST_NAME: 'resources.measuringdevice.name',
  LIST_HEADER: 'resources.measuringdevice.list.header',
  EDIT_TITLE: 'resources.measuringdevice.name',
  EDIT_HEADER: 'resources.measuringdevice.edit.header',
  TABS: {
    PARAMETERS_TAB: 'resources.measuringdevice.edit.tabs.parameters',
    HISTORY_TAB: 'resources.measuringdevice.edit.tabs.history',
    EXAMINATION_TAB: 'resources.measuringdevice.edit.tabs.examination'
  },
  FIELDS: {
    SERIAL_NUMBER: 'resources.measuringdevice.edit.fields.serialNumber',
    AVAILABLE: 'resources.measuringdevice.edit.fields.available',
    PRODUCER: 'resources.measuringdevice.edit.fields.producer'
  },
  AVAILABILITY: {
    AVAILABLE: 'common.measuringDeviceAvailability.available',
    ASSIGNED: 'common.measuringDeviceAvailability.assigned',
    SERVICE: 'common.measuringDeviceAvailability.service'
  },
  BUTTONS: {
    CANCEL_BUTTON: 'common.button.cancel',
    BACK_BUTTON: 'common.button.back'
  }
});

export const measuringDeviceDictionary = Object.freeze({
  DEVICE_TYPE: [
    {
      id: constProvider.MEASUREMENT_DEVICE_TYPE.SCALE,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.deviceTypes.${constProvider.MEASUREMENT_DEVICE_TYPE.SCALE}`
    },
    {
      id: constProvider.MEASUREMENT_DEVICE_TYPE.BLOOD_PRESSURE_MONITOR,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.deviceTypes.${constProvider.MEASUREMENT_DEVICE_TYPE.BLOOD_PRESSURE_MONITOR}`
    },
    {
      id: constProvider.MEASUREMENT_DEVICE_TYPE.PULSE_OXIMETER,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.deviceTypes.${constProvider.MEASUREMENT_DEVICE_TYPE.PULSE_OXIMETER}`
    },
    {
      id: constProvider.MEASUREMENT_DEVICE_TYPE.GLUCOMETER,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.deviceTypes.${constProvider.MEASUREMENT_DEVICE_TYPE.GLUCOMETER}`
    },
    {
      id: constProvider.MEASUREMENT_DEVICE_TYPE.BLOOD_PRESSURE_AND_GLUCOMETER,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.deviceTypes.${constProvider.MEASUREMENT_DEVICE_TYPE.BLOOD_PRESSURE_AND_GLUCOMETER}`
    },
    {
      id: constProvider.MEASUREMENT_DEVICE_TYPE.THERMOMETER,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.deviceTypes.${constProvider.MEASUREMENT_DEVICE_TYPE.THERMOMETER}`
    }
  ],
  CONNECTION_TYPE: [
    {
      id: constProvider.MEASUREMENT_CONNECTION_TYPE.WIRE,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.connectionTypes.${constProvider.MEASUREMENT_CONNECTION_TYPE.WIRE}`
    },
    {
      id: constProvider.MEASUREMENT_CONNECTION_TYPE.BT_2_0,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.connectionTypes.${constProvider.MEASUREMENT_CONNECTION_TYPE.BT_2_0}`
    },
    {
      id: constProvider.MEASUREMENT_CONNECTION_TYPE.BT_4_0,
      name: `resources.${constProvider.RESOURCES.MEASURING_DEVICE.URI}.dictionary.connectionTypes.${constProvider.MEASUREMENT_CONNECTION_TYPE.BT_4_0}`
    }
  ]
});
