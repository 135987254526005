import React, { useRef, useEffect, useState } from 'react';
import OlMap from 'ol/Map';
import View, { ViewOptions } from 'ol/View';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Interaction } from 'ol/interaction';
import 'ol/ol.css';

type MapProps = {
  className?: string;
  view: ViewOptions;
  layers?: Layer[];
  interactions?: Interaction[];
  filter?: any;
};

const ANIMATION_DURATION = 1000;

export function Map({ className, view, layers = [], interactions, filter }: MapProps) {
  const [mapInstance, setMapInstance] = useState<OlMap | null>(null);
  const mapElement = useRef(null);
  const viewRef = useRef(view);
  const layersRef = useRef(layers);
  const interactionsRef = useRef(interactions);
  const filterRef = useRef(filter);

  useEffect(() => {
    const osm = new TileLayer({
      source: new OSM()
    });

    if (filterRef.current) {
      // @ts-ignore
      osm.addFilter(filterRef.current);
    }

    setMapInstance(
      new OlMap({
        layers: [osm, ...layersRef.current],
        view: new View(viewRef.current),
        interactions: interactionsRef.current
      })
    );
  }, []);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    mapInstance.setTarget(mapElement.current || undefined);
  }, [mapInstance]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    mapInstance.getView().animate({ center: view.center, duration: ANIMATION_DURATION });
  }, [mapInstance, view.center]);

  useEffect(() => {
    if (!mapInstance || !interactions) {
      return;
    }

    mapInstance.getInteractions().forEach((interaction) => {
      mapInstance.removeInteraction(interaction);
    });

    interactions.forEach((interaction) => {
      mapInstance.addInteraction(interaction);
    });
  }, [mapInstance, interactions]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    mapInstance.getLayers().forEach((layer) => {
      // @ts-ignore
      layer.getFilters().forEach((item) => {
        if (item === filter) {
          item.setActive(filter.getActive());
        }
      });
    });
  }, [mapInstance, filter]);

  return <div ref={mapElement} className={className} />;
}
