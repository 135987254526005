import { makeStyles } from '@material-ui/styles';
import { colors } from 'style';

export const useStyles = makeStyles({
  content: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  map: {
    width: '100%',
    height: '600px',
    overflow: 'hidden'
  },
  legendSymbol: {
    width: '20px',
    height: '1px',
    marginRight: '8px',
    border: 'none',
    borderTop: `3px dashed`,
    color: colors.white,
    backgroundColor: colors.white
  },
  locationLockdownLegendSymbol: {
    borderColor: '#00c853'
  },
  deviceLocaionLegendSymbol: {
    borderColor: colors.green
  }
});
