import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextField } from '@material-ui/core';

export function FormTextInput({
  meta: { touched, error, submitError },
  input: inputProps,
  ...props
}: FieldRenderProps<string, HTMLInputElement | HTMLTextAreaElement>) {
  return (
    <TextField
      variant="filled"
      error={!!(touched && (error || submitError))}
      helperText={touched && (error || submitError)}
      {...inputProps}
      {...props}
    />
  );
}
