import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useRedirect } from 'react-admin';
import { colors } from 'style';

type CreateInstanceAsideProps = { basePath: string; className?: string; callback?: Function } & any;

export function CreateInstanceAside(props: CreateInstanceAsideProps) {
  const { basePath, className, callback, ...rest } = props;
  const classes = useStyles();
  const redirectTo = useRedirect();

  const onClick = useCallback(() => {
    callback ? callback() : redirectTo(`${basePath}/create`);
  }, [redirectTo, basePath, callback]);

  return (
    <IconButton {...rest} aria-label="create" className={classnames(classes.createButton, className)} onClick={onClick}>
      <AddIcon className={classes.createIcon} />
    </IconButton>
  );
}

export const useStyles = makeStyles({
  createButton: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '56px',
    height: '56px',
    backgroundColor: colors.green,
    zIndex: 1,
    '&:hover': {
      backgroundColor: colors.darkGreen
    }
  },
  createIcon: {
    color: colors.white
  }
});
