import React, { useCallback } from 'react';
import { isEmpty, cond, matches, constant, take, sortBy, flow, partialRight, reverse } from 'lodash';
import moment from 'moment';
import {
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { CategorizedMeasurementDto } from 'models/models';
import { constProvider } from 'providers';
import { useStyles } from './list-card.styles';

type ListCardProps = {
  className: string;
  label: string;
  list: CategorizedMeasurementDto[];
  suffix?: string;
};

export function ListCard({ className, label, list, suffix }: ListCardProps) {
  const classes = useStyles();

  const parseExamDate = useCallback((examDate: string) => {
    return moment(examDate).format(constProvider.DATE_TIME_FORMAT);
  }, []);

  const getRowStyle = cond([
    [matches(constProvider.MEASUREMENT_STATUS.RED), constant(classes.rowRed)],
    [matches(constProvider.MEASUREMENT_STATUS.YELLOW), constant(classes.rowYellow)]
  ]);

  const getValueStyle = cond([
    [matches(constProvider.MEASUREMENT_STATUS.RED), constant(classes.valueRed)],
    [matches(constProvider.MEASUREMENT_STATUS.YELLOW), constant(classes.valueYellow)]
  ]);

  if (isEmpty(list)) return null;

  const filteredList: CategorizedMeasurementDto[] = flow([
    partialRight(sortBy, [({ examDate }: CategorizedMeasurementDto) => moment(examDate).unix()]),
    reverse,
    partialRight(take, 15)
  ])(list);

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <Typography className={classes.label} align="left">
          {label}
        </Typography>
        <TableContainer>
          <Table size="small" aria-label="table">
            <TableBody>
              {filteredList.map((item, index) => (
                <TableRow key={index} className={getRowStyle(item.status)}>
                  <TableCell align="left">{parseExamDate(item.examDate)}</TableCell>
                  <TableCell align="left" className={getValueStyle(item.status)}>
                    {item.value} {suffix}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
