import 'chartjs-plugin-annotation';

import React, { useEffect, useRef } from 'react';
import { categoryColor } from '../category-card-utils';

import Chartjs from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { chartOptions } from './config';
import { DoctorCategorizedPatients } from 'models/models';

interface PieChartProps {
  data: DoctorCategorizedPatients;
}

export function PieChart({ data }: PieChartProps) {
  const chartContainer = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const { emergencyCount, warningCount, healthyCount, notValidCount, unknownCount } = data;
      const mappedChartData = {
        datasets: [
          {
            backgroundColor: categoryColor,
            data: [emergencyCount, warningCount, healthyCount, notValidCount, unknownCount],
            borderWidth: 0
          }
        ]
      };
      const chartConfig = {
        type: 'pie',
        data: mappedChartData,
        options: chartOptions,
        plugins: [ChartDataLabels]
      };
      Chartjs.plugins.unregister(ChartDataLabels);
      new Chartjs(chartContainer.current, chartConfig);
    }
  }, [chartContainer, data]);

  return <canvas ref={chartContainer} height="180" width="180px" />;
}
