export const patientFormConsts = Object.freeze({
  MEDICALCENTER_SUBTITLE: 'resources.user.medicalcenter.subtitle',
  ACTIVE_SUBTITLE: 'resources.user.fields.active',
  VALIDATION: {
    phoneNumber: 'validation.phoneNumberInvalidMessage',
    email: 'validation.emailInvalidMessage'
  },
  BUTTONS: {
    cancelButtonCaption: 'common.button.cancel',
    backButtonCaption: 'common.button.back'
  }
});
