import React, { useCallback, useState, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import {
  useGetOne,
  useTranslate,
  useNotify,
  useMutation,
  Loading,
  Error as ErrorComponent,
  Toolbar,
  SaveButton
} from 'react-admin';
import { Grid, Box, Button } from '@material-ui/core';

import { constProvider } from 'providers';
import { patientLocalizationConsts } from '../patient-localization.const';
import { LocationLockDownDataDTO } from './patient-localization-form.models';
import { FieldNames } from './patient-localization-form.const';
import { LocationLockdownCard } from './location-lockdown-card/location-lockdown-card';
import { DeviceLocationCard } from './device-location-card/device-location-card';
import { MapCard } from './map-card/map-card';
import { useStyles, useToolbarClasses, useEditButtonClasses } from './patient-localization-form.styles';

export function PatientLocalizationForm(props: any) {
  const { record, defaultSubscription, version } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const [mutate] = useMutation();
  const classes = useStyles();
  const toolbarClasses = useToolbarClasses();
  const editButtonClasses = useEditButtonClasses();
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [locationLockDownData, setLocationLockDownData] = useState<LocationLockDownDataDTO | null>(null);
  const {
    data: initialLocationLockDownData,
    loading: locationLockDownLoading,
    error: locationLockDownError
  } = useGetOne(constProvider.RESOURCES.LOCATION_LOCKDOWN.URI, record.id);
  const { data: deviceLocationData, loading: deviceLocationLoading, error: deviceLocationError } = useGetOne(
    constProvider.RESOURCES.DEVICE_LOCATION.URI,
    record.id
  );

  const formData = useMemo(() => {
    return locationLockDownData || initialLocationLockDownData;
  }, [locationLockDownData, initialLocationLockDownData]);

  const onEditClick = useCallback(() => {
    setEditMode(true);
  }, []);

  const resetForm = useCallback(
    (formProps: FormRenderProps<LocationLockDownDataDTO>) => {
      formProps.form.change(FieldNames.address, initialLocationLockDownData.address);
      formProps.form.change(FieldNames.longitude, initialLocationLockDownData.longitude);
      formProps.form.change(FieldNames.latitude, initialLocationLockDownData.latitude);
      formProps.form.change(FieldNames.radius, initialLocationLockDownData.radius);
    },
    [initialLocationLockDownData]
  );

  const onCancelClick = useCallback(
    (formProps: FormRenderProps<LocationLockDownDataDTO>) => {
      setEditMode(false);
      setLocationLockDownData(initialLocationLockDownData);
      resetForm(formProps);
    },
    [initialLocationLockDownData, resetForm]
  );

  const submit = useCallback(
    (values) => {
      mutate(
        {
          type: 'update',
          resource: constProvider.RESOURCES.LOCATION_LOCKDOWN.URI,
          payload: {
            id: values.id,
            data: values
          }
        },
        {
          onSuccess: ({ data }: { data: LocationLockDownDataDTO }) => {
            notify('ra.notification.updated', 'info', {
              smart_count: 1
            });
            setLocationLockDownData(data);
            setEditMode(false);
          }
        }
      );
    },
    [mutate, notify]
  );

  const loading = locationLockDownLoading || deviceLocationLoading;
  const error = locationLockDownError || deviceLocationError;

  if (loading) return <Loading />;

  if (error) return <ErrorComponent />;

  const renderForm = (formProps: FormRenderProps<LocationLockDownDataDTO>) => {
    return (
      <form>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid container item spacing={0} xs={4}>
            <Grid item xs={12} className={classes.deviceLocationContainer}>
              <DeviceLocationCard data={deviceLocationData} />
            </Grid>
            <Grid item xs={12}>
              <LocationLockdownCard data={formData} isEditMode={isEditMode} />
            </Grid>
          </Grid>
          <Grid container item spacing={0} xs={8}>
            <Grid item xs={12}>
              <MapCard
                deviceLocationData={deviceLocationData}
                locationLockDownData={formProps.values}
                isEditMode={isEditMode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Toolbar classes={toolbarClasses}>
          <Box display="flex" justifyContent="flex-end" width="100%">
            {!isEditMode ? (
              <Button variant="contained" color="primary" classes={editButtonClasses} onClick={onEditClick}>
                {translate(patientLocalizationConsts.BUTTONS.EDIT)}
              </Button>
            ) : (
              <>
                <Button color="primary" onClick={() => onCancelClick(formProps)} className={classes.cancelButton}>
                  {translate(patientLocalizationConsts.BUTTONS.CANCEL)}
                </Button>
                <SaveButton saving={formProps.submitting} handleSubmitWithRedirect={formProps.handleSubmit} />
              </>
            )}
          </Box>
        </Toolbar>
      </form>
    );
  };

  const validateForm = (values: LocationLockDownDataDTO) => {
    const errors: Partial<LocationLockDownDataDTO> = {};

    if (!values.address) {
      errors.address = translate('ra.validation.required');
    }

    return errors;
  };

  return (
    <Form
      initialValues={formData}
      onSubmit={submit}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      validate={validateForm}
      render={renderForm}
    />
  );
}
