import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import moment from 'moment';
import { useStyles } from './Footer.styles';
import { constProvider } from 'providers';

export const Footer: FC = () => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <span>
        © {moment().year()} {translate('common.footer.rights')}{' '}
        {translate('common.footer.version', { version: constProvider.VERSION || 'DEVELOPMENT' })}
      </span>
      |
      <a className={classes.link} href="/">
        {translate('common.footer.privacyPolicy')}
      </a>
      |
      <a className={classes.link} href="/">
        {translate('common.footer.info')}
      </a>
      |
      <a className={classes.link} href="/">
        {translate('common.footer.servis')}
      </a>
    </footer>
  );
};
