import { constProvider } from 'providers';

export const patientLocalizationConsts = Object.freeze({
  ENABLE_LOCALIZATION: `resources.${constProvider.RESOURCES.PATIENT_LOCATION.URI}.enableLocalization`,
  LOCALIZATION_ENABLE_INFO: `resources.${constProvider.RESOURCES.PATIENT_LOCATION.URI}.localizationEnableInfo`,
  BUTTONS: {
    CANCEL: 'common.button.cancel',
    EDIT: 'common.button.edit'
  }
});
