import React, { useCallback } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import {
  SaveButton,
  TextInput,
  SelectInput,
  useTranslate,
  useRedirect,
  useNotify,
  useDataProvider,
  required,
  ReferenceInput
} from 'react-admin';
import classnames from 'classnames';
import { Box, Toolbar, Button, Grid } from '@material-ui/core';

import { constProvider } from 'providers';
import { parseFormErrors } from 'services/utils';

import { measuringDeviceConsts, measuringDeviceDictionary } from '../../measuring-device.const';
import { MeasuringDeviceFormValues } from './measuring-device-form.models';
import { useStyles } from './measuring-device-form.styles';

type MeasuringDeviceFormProps = any;

export function MeasuringDeviceForm(props: MeasuringDeviceFormProps) {
  const { record, basePath, version } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const translate = useTranslate();
  const classes = useStyles();

  const submit = useCallback(
    async (values: MeasuringDeviceFormValues) => {
      try {
        await dataProvider.patch(constProvider.RESOURCES.MEASURING_DEVICE_MEDICAL_CENTER.URI, {
          id: values.id,
          data: {
            medicalCenterId: values.medicalCenterId
          }
        });
        notify('ra.notification.updated', 'info', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          smart_count: 1
        });
        redirectTo('edit', basePath, record.id);
      } catch (error) {
        return parseFormErrors(error, translate);
      }
    },
    [dataProvider, basePath, notify, redirectTo, translate, record]
  );

  const onCancelClick = useCallback(() => {
    redirectTo(`/${constProvider.RESOURCES.MEASURING_DEVICE.URI}`);
  }, [redirectTo]);

  const onBackClick = useCallback(() => {
    window.history.back();
  }, []);

  const renderForm = (formProps: FormRenderProps<MeasuringDeviceFormValues>) => {
    return (
      <form>
        <Box className={classes.container}>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <TextInput
                  resource={constProvider.RESOURCES.MEASURING_DEVICE.URI}
                  source="serialNumber"
                  validate={required()}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <SelectInput
                  resource={constProvider.RESOURCES.MEASURING_DEVICE.URI}
                  source="type"
                  validate={required()}
                  choices={measuringDeviceDictionary.DEVICE_TYPE}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <ReferenceInput
                  resource={constProvider.RESOURCES.MEASURING_DEVICE.URI}
                  reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
                  source="medicalCenterId"
                  validate={required()}
                  fullWidth
                >
                  <SelectInput fullWidth />
                </ReferenceInput>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <TextInput
                  resource={constProvider.RESOURCES.MEASURING_DEVICE.URI}
                  source="producer"
                  validate={required()}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  resource={constProvider.RESOURCES.MEASURING_DEVICE.URI}
                  source="model"
                  validate={required()}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <SelectInput
                  resource={constProvider.RESOURCES.MEASURING_DEVICE.URI}
                  source="connectionType"
                  validate={required()}
                  choices={measuringDeviceDictionary.CONNECTION_TYPE}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button
              color="primary"
              className={classnames(classes.previousButton, classes.flexStart)}
              onClick={onBackClick}
            >
              {translate(measuringDeviceConsts.BUTTONS.BACK_BUTTON)}
            </Button>
            <Button
              color="primary"
              className={classnames(classes.cancelButton, classes.autoMarginLeft)}
              onClick={onCancelClick}
            >
              {translate(measuringDeviceConsts.BUTTONS.CANCEL_BUTTON)}
            </Button>
            <SaveButton saving={formProps.submitting} handleSubmitWithRedirect={formProps.handleSubmit} />
          </Box>
        </Toolbar>
      </form>
    );
  };

  return (
    <Form
      initialValues={record}
      onSubmit={submit}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      render={renderForm}
    />
  );
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
};
