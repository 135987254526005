import { CustomTab, CustomTabs } from './custom-tabs/custom-tabs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslate, Loading } from 'react-admin';
import { cond, constant } from 'lodash';

import { DashboardDoctor } from './dashboard-doctor/dashboard-doctor';
import { DashboardAdmin } from './dashboard-admin/dashboard-admin';
import { TabPanel } from 'components/common';
import { makeStyles } from '@material-ui/core/styles';
import { dashboardConsts } from './dashboard.const';
import { concatPermission, hasPermission } from '../../../services/utils/permissions.utils';
import { constProvider, DASHBOARDS_PRIVILEGES } from '../../../providers';
import { UserPermissions } from '../user';
import { DASHBOARDS } from './dashboard.const';

type DashboardProps = {
  permissions?: UserPermissions[];
} & any;

export function Dashboard(props: DashboardProps) {
  const permissions = props.permissions;
  const hasAdminPermission = hasPermissionToDashboard(permissions, constProvider.DASHBOARDS_PRIVILEGES.ADMIN_DASHBOARD);
  const hasDoctorPermission = hasPermissionToDashboard(
    permissions,
    constProvider.DASHBOARDS_PRIVILEGES.DOCTORS_DASHBOARD
  );

  const classes = useStyles();
  const translate = useTranslate();

  const [selectedTabValue, setSelectedTabValue] = useState<DASHBOARDS | undefined>(undefined);
  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: DASHBOARDS) => {
      setSelectedTabValue(newValue);
    },
    [setSelectedTabValue]
  );

  useEffect(() => {
    const target = cond<
      {
        permissions: UserPermissions[] | undefined;
        hasAdminPermission: boolean;
        hasDoctorPermission: boolean;
      },
      undefined | DASHBOARDS
    >([
      [({ permissions }) => !permissions, constant(undefined)],
      [
        ({ hasAdminPermission, hasDoctorPermission }) => hasAdminPermission && hasDoctorPermission,
        constant(DASHBOARDS.DOCTOR)
      ],
      [({ hasAdminPermission }) => hasAdminPermission, constant(DASHBOARDS.ADMIN)],
      [({ hasDoctorPermission }) => hasDoctorPermission, constant(DASHBOARDS.DOCTOR)]
    ])({
      permissions,
      hasAdminPermission,
      hasDoctorPermission
    });

    setSelectedTabValue(target);
  }, [permissions, hasAdminPermission, hasDoctorPermission, setSelectedTabValue]);

  if (!(permissions && selectedTabValue)) {
    return <Loading />;
  }

  return (
    // TODO add something like PageContent component to commons?
    <div className={classes.root}>
      <CustomTabs value={selectedTabValue} onChange={handleTabChange} aria-label="Dashboard tabs">
        {hasDoctorPermission && (
          <CustomTab value={DASHBOARDS.DOCTOR} label={translate(dashboardConsts.TABS.DOCTOR.TITLE)} />
        )}
        {hasAdminPermission && (
          <CustomTab value={DASHBOARDS.ADMIN} label={translate(dashboardConsts.TABS.ADMIN.TITLE)} />
        )}
      </CustomTabs>
      {hasDoctorPermission && (
        <TabPanel index={DASHBOARDS.DOCTOR} value={selectedTabValue as string}>
          <DashboardDoctor {...props} />
        </TabPanel>
      )}
      {hasAdminPermission && (
        <TabPanel index={DASHBOARDS.ADMIN} value={selectedTabValue as string}>
          <DashboardAdmin {...props} />
        </TabPanel>
      )}
    </div>
  );
}

function hasPermissionToDashboard(permissions: UserPermissions[] | undefined, dashboard: DASHBOARDS_PRIVILEGES) {
  return !permissions
    ? false
    : hasPermission(permissions, concatPermission(dashboard, constProvider.PRIVILEGES.READ_ONLY_PROPERTIES));
}

const useStyles = makeStyles({
  root: {
    padding: '25px 25px 25px 95px'
  }
});
