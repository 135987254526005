import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FilterListOutlined from '@material-ui/icons/FilterListOutlined';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

type FiltersButtonProps = { toggleFilters: () => void; className?: string };

export function FiltersButton({ toggleFilters, className }: FiltersButtonProps) {
  const classes = useStyles();

  return (
    <div className={classnames([classes.root, className])}>
      <IconButton aria-label="Filters" onClick={toggleFilters}>
        <FilterListOutlined />
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    paddingTop: 0
  }
});
