import React, { useCallback, useState, useRef } from 'react';
import { useQueryWithStore } from 'react-admin';
import { Button, Popper, Grow, MenuList, Paper, MenuItem, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import * as Types from 'AppTypes';
import * as styles from 'style';
import { constProvider } from 'providers';
import { getSelectedMedicalcenter } from 'features/selectedMedicalcenter/selectors';
import { selectMedicalcenterContext } from 'features/selectedMedicalcenter/actions';
import { find } from 'lodash';
import { useOnClickOutside } from 'hooks/common';

export function MedicalcenterSelectionBarSelector() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const medicalCenterId = useSelector((state: Types.RootState) => getSelectedMedicalcenter(state));
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef<HTMLButtonElement | null>(null);

  const changeMedicalCenter = useCallback(
    (selectedMedicalcenterId: string) => {
      setAnchorEl(null);
      if (selectedMedicalcenterId !== medicalCenterId) {
        dispatch(selectMedicalcenterContext(selectedMedicalcenterId));
      }
    },
    [dispatch, medicalCenterId, setAnchorEl]
  );

  const openDropdown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  useOnClickOutside(ref, () => setAnchorEl(null));

  const {
    loaded,
    data: medicalcenters
  }: { loaded: boolean; data: Array<{ id: string; name: string }> } = useQueryWithStore({
    type: 'getList',
    resource: constProvider.RESOURCES.USER_MEDICALCENTER.URI,
    payload: { pagination: { page: 0, perPage: 100 }, sort: { field: 'name', order: 'ASC' }, filter: {} }
  });

  function getSelectedMedicalcenterName() {
    const selectedMedicalcenter = find(medicalcenters, ({ id }) => id === medicalCenterId);
    return selectedMedicalcenter ? selectedMedicalcenter.name : '';
  }

  return loaded ? (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={openDropdown}
        className={classes.button}
        ref={ref}
      >
        {getSelectedMedicalcenterName()}
        <ArrowDropDownIcon className={classes.arrow} />
      </Button>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.growPlacementBottom}>
              <MenuList id="simple-menu" className={classes.menuList}>
                {(medicalcenters || []).map(({ id, name }: { id: string; name: string }) => (
                  <MenuItem className={classes.menuItem} key={id} onClick={() => changeMedicalCenter(id)}>
                    {name}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : (
    <></>
  );
}

export const useStyles = makeStyles({
  button: {
    color: styles.colors.primaryBlue
  },
  arrow: {
    color: 'black'
  },
  menuList: {
    maxHeight: '50vh',
    overflow: 'auto',
    overflowScolling: 'touch'
  },
  menuItem: {
    borderBottom: `1px solid ${styles.colors.lightGray}`
  },
  growPlacementBottom: {
    transformOrigin: 'center top'
  }
});
