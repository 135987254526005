import { FunctionField, useTranslate } from 'react-admin';

import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import { constProvider } from 'providers';
import { PatientCategoryEnum, DoctorPatientDto } from 'models/models';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

type PatientStatusFieldProps = {
  source: string;
} & any;

const useStyles = makeStyles({
  text: {
    textDecoration: 'none',
    color: 'black'
  }
});

export function PatientStatusField({ source, ...restProps }: PatientStatusFieldProps) {
  const translate = useTranslate();
  const classes = useStyles();
  const renderPatientStatusField = useCallback(
    (props: DoctorPatientDto) => {
      const status: PatientCategoryEnum = get(props, source);
      if (!status) return '-';
      const id = get(props, 'id');
      const text = translate(get(constProvider.PATIENT_STATUS_TO_LABEL, status));
      const statusItem = <Typography align="center">{text}</Typography>;
      if (!id) return statusItem;
      return (
        <Link to={`${constProvider.RESOURCES.PATIENT.URI}/${id}`} className={classes.text}>
          {statusItem}
        </Link>
      );
    },
    [source, translate, classes.text]
  );

  return <FunctionField source={source} render={renderPatientStatusField} {...restProps} />;
}
