import { FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Check, Clear } from '@material-ui/icons';
import React from 'react';

type BooleanFieldProps = {
  source: string;
} & any;

const useStyles = makeStyles({
  icon: {
    color: green[500]
  }
});

export function BooleanField({ source, ...restProps }: BooleanFieldProps) {
  const classes = useStyles();

  const renderBooleanField = (record: any) => (record[source] ? <Check className={classes.icon} /> : <Clear />);

  return <FunctionField source={source} render={renderBooleanField} {...restProps} />;
}
