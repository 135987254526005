import { makeStyles } from '@material-ui/styles';
import { fontWeight } from 'style';

export const useStyles = makeStyles((theme: any) => ({
  header: {
    marginBottom: '12px'
  },
  item: {
    color: theme.palette.text.primary,
    fontWeight: fontWeight.medium
  },
  label: {
    color: theme.palette.text.secondary
  }
}));
