import React from 'react';
import { Edit, useTranslate } from 'react-admin';
import { PageContentWithTitle } from 'components/common';
import { MedicalCenterForm } from '../common/medicalcenter-form/MedicalCenterForm';
import { medicalCenterConsts } from '../MedicalCenter.const';

export function MedicalCenterEdit(props: any) {
  const translate = useTranslate();

  return (
    <PageContentWithTitle title={medicalCenterConsts.EDIT_TITLE}>
      <Edit {...props} title={translate(medicalCenterConsts.EDIT_TITLE)}>
        <MedicalCenterForm isEditMode />
      </Edit>
    </PageContentWithTitle>
  );
}
