import React from 'react';
import { TextInput, SelectInput, Filter, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { ActiveFilterDropdown } from 'components/common';
import { medicalCenterConsts, medicalCenterDictionary } from '../../MedicalCenter.const';

const useStyles = makeStyles({
  input: {
    minWidth: '220px',
    marginBottom: '20px'
  }
});

export function MedicalCenterFilters(props: any) {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Filter {...props}>
      <TextInput
        className={classes.input}
        label={translate(medicalCenterConsts.LABELS.NAME)}
        id="standard-basic"
        source="name"
        alwaysOn
      />
      <TextInput
        className={classes.input}
        label={translate(medicalCenterConsts.LABELS.CITY)}
        id="standard-basic"
        source="city"
        alwaysOn
      />
      <ActiveFilterDropdown
        className={classes.input}
        label={medicalCenterConsts.LABELS.ACTIVE}
        source="active"
        alwaysOn
      />
      <SelectInput
        className={classes.input}
        label={medicalCenterConsts.LABELS.SUBCENTERS}
        source="permitSubCenters"
        alwaysOn
        choices={medicalCenterDictionary.SUBCENTERS}
      />
    </Filter>
  );
}
