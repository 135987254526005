import polishMessages from 'ra-language-polish';
import { constProvider } from '../const';

const MeasuringDeviceFields = {
  measuringDeviceId: 'Urządzenie pomiarowe',
  serialNumber: 'Numer seryjny',
  addressBDA: 'Adres BDA',
  model: 'Model',
  type: 'Typ',
  medicalCenterId: 'Ośrodek',
  status: 'Status',
  manufacturer: 'Producent',
  firstName: 'Imię',
  surname: 'Nazwisko',
  patientIdentifier: 'Pesel',
  producer: 'Producent'
};

export const messages = {
  ...polishMessages,
  common: {
    authorization: {
      permissionDenied: 'Brak uprawnień'
    },
    footer: {
      rights: 'Pro-PLUS. All Rights Reserved.',
      version: 'Wersja: %{version}',
      privacyPolicy: 'Polityka Prywatności',
      info: 'Info',
      servis: 'Serwis'
    },
    changeLanguageDropdown: {
      polish: 'Polish',
      italian: 'Italian'
    },
    gender: {
      man: 'Mężczyzna',
      woman: 'Kobieta'
    },
    active: {
      label: 'Aktywny',
      active: 'Aktywny',
      notActive: 'Nieaktywny'
    },
    deviceAvailability: {
      available: 'Dostępny',
      notAvailable: 'Niedostępny'
    },
    measuringDeviceAvailability: {
      available: 'Dostępny',
      assigned: 'Wypożyczony',
      service: 'Serwis'
    },
    button: {
      cancel: 'Anuluj',
      next: 'Dalej',
      back: 'Wstecz',
      edit: 'Edytuj'
    },
    measurementCategory: {
      emergency: 'Alarm',
      warning: 'Warning',
      healthy: 'OK',
      notValid: 'No data',
      unknown: 'Not ready'
    },
    measurementType: {
      temperature: 'Temperatura',
      saturation: 'Saturacja',
      pulse: 'Puls'
    },
    deviceType: {
      thermometer: 'Termometr',
      pulse_oximeter: 'Pulsoksymetr'
    },
    error: {
      labels: {
        COVID00000: 'Wewnętrzny błąd serwera:'
      },
      emailFormat: 'musi być poprawnym adresem e-mail',
      notBlank: 'nie może być puste',
      serviceUnavailable: 'Serwis chwilowo niedostępny'
    }
  },
  resources: {
    dashboard: { name: 'Pulpit' },
    [constProvider.RESOURCES.MEDICAL_CENTER.URI]: { name: 'Ośrodki' },
    [constProvider.RESOURCES.EXAMINATION.URI]: { name: 'Badania' },
    [constProvider.RESOURCES.DEVICE.URI]: {
      name: 'Aparaty',
      fields: {
        serialNumber: 'Numer',
        deviceType: 'Typ aparatu',
        simNumber: 'Numer sim',
        available: 'Dostępny',
        lastCheck: 'Ostatnie badanie',
        medicalCenterName: 'Ośrodek',
        medicalCenterId: 'Ośrodek',
        lastProgrammed: 'Ostatnie progr.',
        lent: 'Wypożyczenie',
        simPhoneNumber: 'Numer telefonu'
      },
      list: {
        header: 'Lista aparatów'
      },
      edit: {
        header: 'Informacje o aparacie: %{name}',
        subtitle: 'Dane aparatu',
        tabs: {
          parameters: 'Parametry',
          history: 'Historia',
          measuringDevice: 'Urządzenia pomiarowe',
          programming: 'Programowanie'
        },
        fields: {
          serialNumber: 'Numer seryjny',
          available: 'Status'
        }
      },
      measuringDevice: {
        title: 'Dodane urządzenia pomiarowe',
        assignTitle: 'Przypisanie urządzenia pomiarowego do aparatu',
        deviceChoiceSubtitle: 'Wybór urządzenia pomiarowego',
        labels: {
          action: 'Akcja',
          unlink: 'Odepnij',
          serialNumber: 'Numer seryjny aparatu',
          simNumber: 'Numer SIM',
          type: 'Typ'
        }
      }
    },
    [constProvider.RESOURCES.MEASURING_DEVICE.URI]: {
      name: 'Urządzenia pomiarowe',
      title: 'Lista urządzeń',
      fields: MeasuringDeviceFields,
      dictionary: {
        deviceTypes: {
          [constProvider.MEASUREMENT_DEVICE_TYPE.SCALE]: 'Waga',
          [constProvider.MEASUREMENT_DEVICE_TYPE.BLOOD_PRESSURE_MONITOR]: 'Ciśnieniomierz',
          [constProvider.MEASUREMENT_DEVICE_TYPE.PULSE_OXIMETER]: 'Pulsoksymetr',
          [constProvider.MEASUREMENT_DEVICE_TYPE.GLUCOMETER]: 'Glukometr',
          [constProvider.MEASUREMENT_DEVICE_TYPE.BLOOD_PRESSURE_AND_GLUCOMETER]: 'Ciśnieniomierz z glukometrem',
          [constProvider.MEASUREMENT_DEVICE_TYPE.THERMOMETER]: 'Termometr'
        },
        connectionTypes: {
          [constProvider.MEASUREMENT_CONNECTION_TYPE.WIRE]: 'Przewodowe',
          [constProvider.MEASUREMENT_CONNECTION_TYPE.BT_2_0]: 'Bluetooth 2.0',
          [constProvider.MEASUREMENT_CONNECTION_TYPE.BT_4_0]: 'Bluetooth 4.0'
        }
      },
      edit: {
        header: 'Dane urządzenia pomiarowego: %{name}',
        tabs: {
          parameters: 'Parametry',
          history: 'Historia',
          examination: 'Badania'
        }
      }
    },
    [constProvider.RESOURCES.AVAILABLE_MEASURINGDEVICE.URI]: {
      fields: MeasuringDeviceFields
    },
    [constProvider.RESOURCES.DEVICE_MEASURINGDEVICE.URI]: {
      empty: 'Brak przypisanych urządzeń pomiarowych',
      fields: MeasuringDeviceFields
    },
    [constProvider.RESOURCES.PATIENT.URI]: {
      name: 'Pacjenci',
      empty: 'Nie utworzono żadnego pacjenta.',
      invite: 'Chcesz dodać nowego pacjenta?',
      list: {
        title: 'Lista pacjentów'
      },
      edit: {
        title: 'Informacje o pacjencie: %{name}',
        patientDetails: {
          label: 'Dane Pacjenta'
        },
        dashboard: {
          label: 'Dashboard',
          basicPatientInfo: 'Informacje o pacjencie',
          gender: 'Płeć',
          identifier: 'Pesel',
          phoneNumber: 'Telefon',
          medicalCenterName: 'Ośrodek',
          category: 'Kategoria',
          measurement: {
            temperature: 'Temperatura',
            pulse: 'Tętno',
            saturation: 'Saturacja tlenowa krwi'
          },
          list: {
            temperature: 'Daty badań temperatury ciała',
            saturation: 'Daty badań saturacji tlenowej krwi',
            pulse: 'Daty badań tętna'
          },
          chart: {
            temperature: 'Temperatura ciała',
            saturation: 'Saturacja tlenowa krwi',
            pulse: 'Tętno',
            highest: 'Najwyższy pomiar',
            lowest: 'Najniższy pomiar'
          },
          status: {
            status: 'Status pacjenta',
            newlyRegistered: 'Nowo zarejestrowany',
            callEmergency: 'Wezwać pogotowie',
            callPolice: 'Zawiadomić policję',
            notifyPatient: 'Zawiadomić pacjenta',
            medicalConsultation: 'Konsultacja lekarska'
          }
        },
        localization: {
          label: 'Lokalizacja',
          locationLockDownCard: {
            header: 'Obszar kwarantanny',
            longitude: 'Szarokość geograficzna',
            latitude: 'Długość geograficzna',
            radius: 'Promień',
            address: 'Adres'
          },
          deviceLocationCard: {
            header: 'Dane lokalizacyjne',
            longitude: 'Szarokość geograficzna',
            latitude: 'Długość geograficzna',
            satellitesUsed: 'Liczba użytych satelitów',
            satellitesAvailable: 'Liczba dostępnych satelitów',
            positionDilutionOfPrecision: 'Dokładność'
          },
          mapCard: {
            legend: {
              locationLockDown: 'Obszar kwarantanny',
              deviceLocation: 'Dane lokalizacyjne'
            },
            messages: {
              reverseAddressLookupError: 'Nie udało się pobrać adresu'
            }
          }
        },
        device: {
          label: 'Aparaty',
          unassignedTitle: 'Wypożyczenie aparatu'
        }
      },
      create: {
        title: 'Dodawanie pacjenta'
      },
      fields: {
        firstName: 'Imię',
        lastName: 'Nazwisko',
        active: 'Aktywny',
        medicalCenterId: 'Ośrodek',
        verifyPesel: 'Sprawdź PESEL',
        pesel: 'Pesel',
        birthday: 'Data urodzenia',
        address: 'Adres',
        postCode: 'Kod',
        city: 'Miasto',
        country: 'Kraj',
        phoneNumber: 'Telefon',
        email: 'E-mail',
        gender: 'Płeć'
      },
      medicalcenter: {
        subtitle: 'Wybór ośrodka:',
        nameLabel: 'Ośrodek',
        testName: 'Test',
        medicalCenterName: 'Ośrodek'
      }
    },
    [constProvider.RESOURCES.PATIENT_DEVICE.URI]: {
      empty: 'Brak przypisanych aparatów',
      fields: {
        deviceNumber: 'Numer urządzenia',
        operatingMode: 'Tryb pracy',
        dateOfAssignment: 'Data przydziału',
        dateOfReturn: 'Data zwrotu'
      }
    },
    [constProvider.RESOURCES.USER.URI]: {
      name: 'Użytkownik',
      empty: 'Nie utworzono żadnego użytkownika.',
      invite: 'Chcesz dodać nowego użytkownika?',
      fields: {
        title: 'Tytuł',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        type: 'Rola',
        medicalCenterName: 'Ośrodek',
        medicalCenterId: 'Ośrodek',
        active: 'Aktywny',
        lastLogin: 'Logowanie',
        subtitle: 'Dane użytkownika:',
        npvz: 'NPWZ',
        address: 'Adres',
        postalCode: 'Kod pocztowy',
        city: 'Miasto',
        country: 'Kraj',
        phoneNumber: 'Telefon kontaktowy',
        password: 'Hasło',
        password2: 'Powtórz hasło',
        email: 'E-mail',
        login: 'Login',
        privileges: 'Dostępne uprawnienia'
      },
      consts: {
        active: {
          active: 'Aktywny',
          notActive: 'Nieaktywny'
        },
        userRoles: {
          [constProvider.USER_ROLES.ADMIN]: 'Administrator',
          [constProvider.USER_ROLES.NURSE]: 'Pielęgniarka',
          [constProvider.USER_ROLES.PARAMEDIC]: 'Ratownik',
          [constProvider.USER_ROLES.DOCTOR]: 'Lekarz'
        },
        privileges: {
          [constProvider.PRIVILEGES.LIST_VIEW]: 'Widok listy',
          [constProvider.PRIVILEGES.READ_ONLY_PROPERTIES]: 'Odczyt',
          [constProvider.PRIVILEGES.ADD]: 'Dodawanie',
          [constProvider.PRIVILEGES.EDIT]: 'Edycja',
          [constProvider.PRIVILEGES.DELETE]: 'Usuwanie'
        },
        sectionsPrivileges: {
          subtitle: 'Sekcje',
          [constProvider.SECTIONS_PRIVILEGES.USERS]: 'Użytkownicy',
          [constProvider.SECTIONS_PRIVILEGES.PATIENTS]: 'Pacjenci',
          [constProvider.SECTIONS_PRIVILEGES.RESULTS]: 'Wyniki',
          [constProvider.SECTIONS_PRIVILEGES.DEVICES]: 'Aparaty',
          [constProvider.SECTIONS_PRIVILEGES.MEASUREMENTS_DEVICE]: 'Urządzenia pomiarowe',
          [constProvider.SECTIONS_PRIVILEGES.MEDICAL_CENTERS]: 'Ośrodki',
          [constProvider.SECTIONS_PRIVILEGES.REPORTS]: 'Raporty'
        },
        dashboardsPrivileges: {
          subtitle: 'Panele',
          [constProvider.DASHBOARDS_PRIVILEGES.ADMIN_DASHBOARD]: 'Panel administratora',
          [constProvider.DASHBOARDS_PRIVILEGES.PATIENT_DASHBOARD]: 'Panel pacjenta',
          [constProvider.DASHBOARDS_PRIVILEGES.DOCTORS_DASHBOARD]: 'Panel lekarza',
          [constProvider.DASHBOARDS_PRIVILEGES.PARAMEDIC_DASHBOARD]: 'Panel personelu'
        },
        specificPrivileges: {
          subtitle: 'Pozostałe',
          [constProvider.SPECIFIC_PRIVILEGES.DEVICE_ASSIGNEMENT]: 'Przypisanie aparatu',
          [constProvider.SPECIFIC_PRIVILEGES.DEFINE_GEOLOCATION_AREA]: 'Obszar geolokacji',
          [constProvider.SPECIFIC_PRIVILEGES.DEFINE_SMS_NOTIFICATION]: 'Notyfikacje SMS',
          [constProvider.SPECIFIC_PRIVILEGES.IMPORT_EXPORT_SCP_FILES]: 'Import/Export plików scp',
          [constProvider.SPECIFIC_PRIVILEGES.PATIENT_CARD]: 'Karta pacjenta',
          [constProvider.SPECIFIC_PRIVILEGES.DEVICE_PROGRAMMING]: 'Programowanie aparatu',
          [constProvider.SPECIFIC_PRIVILEGES.FIRMWARE_UPDATE]: 'Aktualizacja oprogramowania'
        }
      },
      list: {
        title: 'Lista użytkowników'
      },
      create: {
        title: 'Dodawanie użytkownika'
      },
      edit: {
        title: 'Edycja użytkownika',
        tabs: {
          label: 'Zakładki',
          details: 'Edycja',
          permission: 'Uprawnienia'
        }
      },
      userDetails: {
        subtitle: 'Dane użytkownika:'
      },
      medicalcenter: {
        subtitle: 'Wybór ośrodka:',
        nameLabel: 'Ośrodek',
        testName: 'Test'
      },
      accountDetails: {
        subtitle: 'Konto użytkownika:',
        emailLabel: 'email',
        passwordLabel: 'hasło',
        passwordConfirmLabel: 'powtórz hasło'
      }
    },
    [constProvider.RESOURCES.PATIENT_LOCATION.URI]: {
      fields: {
        enabled: 'Wyłącz'
      },
      enableLocalization: 'Włącz',
      localizationEnableInfo: 'Czy chcesz włączyć monitorowanie lokalizacji?'
    },
    [constProvider.RESOURCES.DOCTOR_PATIENT_MEASUREMENT.URI]: {
      name: 'Pulpit Lekarza',
      fields: {
        firstName: 'Imię',
        lastName: 'Nazwisko',
        deviceSerialNumber: 'Aparat',
        temperatureMeasurement: 'Temperatura',
        saturationMeasurement: 'SPO2',
        pulseMeasurement: 'Tętno',
        patientCategory: 'Kategoria',
        patientLockdownStatus: 'Lokalizacja',
        patientStatus: 'Statusy'
      }
    },
    [constProvider.RESOURCES.LOCATION_LOCKDOWN.URI]: {
      fields: {
        radius: 'Promień',
        address: 'Adres'
      },
      formInfo: 'Zdefiniuj dozwolony obszar kwarantanny',
      radiusHelperText: 'Minimalna wartość 1m'
    }
  },
  validation: {
    phoneNumberInvalidMessage: 'Niepoprawny numer telefonu',
    emailInvalidMessage: 'Niepoprawny e-mail',
    passwordValidation: 'Rożne hasła',
    medicalCenterAlreadyExists: 'Ośrodek o takiej właściwości już istnieje',
    defaultErrorMessage: 'Error',
    peselInvalidMessage: 'Niepoprawny PESEL',
    measuringDeviceAndDeviceMedicalCenterConflict: 'Urządzenie pomiarowe posiada już aparat w innym centrum medycznym'
  },
  loginPage: {
    auth: {
      logout: 'Wyloguj'
    },
    notification: {
      loginError: 'Niepoprawny login lub hasło'
    },
    action: {
      login: 'Logowanie',
      export: 'Eksportuj'
    },
    controls: {
      loginForm: {
        header: 'Logowanie',
        loginLabel: 'Login',
        passwordLabel: 'Hasło'
      }
    }
  },
  medicalcenterSelection: {
    name: 'Wybór ośrodka',
    label: 'Wybierz ośrodek',
    button: {
      submit: 'LOGOWANIE'
    }
  },
  settings: {
    name: 'Ustawienia'
  },
  medicalcenter: {
    list: {
      header: 'Ośrodki',
      labels: {
        name: 'Nazwa',
        city: 'Miasto',
        active: 'Aktywny',
        notActive: 'Nieaktywny',
        subCenters: 'Podrzędne ośrodki',
        available: 'Dostępne',
        notAvailable: 'Niedostępne'
      }
    },
    create: {
      title: 'Dodawanie ośrodka'
    },
    edit: {
      title: 'Edycja ośrodka'
    },
    form: {
      details: {
        superiorMedicalcenterNameLabel: 'Ośrodek nadrzędny:',
        nameLabel: 'Nazwa',
        nipLabel: 'NIP',
        addressLabel: 'Adres',
        postalCodeLabel: 'Kod',
        cityLabel: 'Miasto',
        countryLabel: 'Kraj',
        activeLabel: 'Aktywny',
        permitSubCenters: 'Podrzędne ośrodki'
      },
      smsNotifications: {
        subtitle: 'Powiadomienia SMS:',
        smsNotificationPhoneNumberLabel: 'Telefon komórkowy'
      },
      admin: {
        subtitle: 'Dodawanie administratora ośrodka:',
        label: 'Administrator'
      }
    }
  },
  dashboard: {
    doctor: {
      title: 'Lekarz',
      category: {
        title: 'Liczba pacjentów'
      },
      type: {
        title: 'Liczba pacjentów z przekroczonymi wartościami w badaniach',
        text: 'pacjentów'
      },
      filters: {
        firstName: 'Imię',
        lastName: 'Nazwisko',
        pesel: 'Pesel',
        gender: 'Płeć',
        medicalCenter: 'Ośrodek',
        deviceSerialNumber: 'Numer aparatu',
        examDateFrom: 'Data badania od',
        examDateTo: 'Data badania do',
        statusFrom: 'Data statusu od',
        statusTo: 'Data statusu do',
        status: 'Status',
        category: 'Kategoria'
      }
    },
    admin: { title: 'Administrator' }
  }
};
