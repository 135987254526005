import React from 'react';
import { useTranslate } from 'react-admin';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export const PaginationLimit = ({ resource }: { resource?: string }) => {
  const translate = useTranslate();
  const resourcedLabel = `resources.${resource}.empty`;
  const translatedLabel = translate(resourcedLabel);
  const defaultTranslation = translate('ra.navigation.no_results');
  return (
    <CardContent>
      <Typography variant="body2">
        {translatedLabel === resourcedLabel ? defaultTranslation : translatedLabel}
      </Typography>
    </CardContent>
  );
};
