import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { Filter, SelectInput, TextInput, ReferenceInput } from 'react-admin';
import { MeasuringDeviceDto } from 'models/models';
import { constProvider } from 'providers';
import { UniversalFilterFields } from 'components/common';

const useStyles = makeStyles({
  input: {
    minWidth: '220px'
  },
  filters: {
    'margin-bottom': '20px'
  }
});

type DeviceListFilters = { className?: string; fields?: UniversalFilterFields<MeasuringDeviceDto> };

export function DeviceListFilters({ className, fields, ...props }: DeviceListFilters) {
  const classes = useStyles();

  const filterClassName = useMemo(() => [classes.filters, className], [className, classes.filters]);

  const filterControls = useMemo(
    () =>
      fields &&
      fields.map(({ source, resource }) => {
        return resource ? (
          <ReferenceInput
            key={source}
            className={classes.input}
            source={source}
            reference={constProvider.RESOURCES[resource].URI}
            alwaysOn
          >
            <SelectInput variant="standard" source="name" alwaysOn />
          </ReferenceInput>
        ) : (
          <TextInput key={source} className={classes.input} source={source} alwaysOn />
        );
      }),
    [fields, classes.input]
  );

  return (
    <Filter {...props} className={filterClassName}>
      {filterControls}
    </Filter>
  );
}
