import { UserPermissions } from '../../components/resources/user';
import {
  constProvider,
  DASHBOARDS_PRIVILEGES,
  PRIVILEGES,
  SECTIONS_PRIVILEGES,
  SPECIFIC_PRIVILEGES
} from '../../providers';

export function hasPermission(allUserPermissions: UserPermissions[], key: UserPermissions): boolean {
  return allUserPermissions.includes(key);
}

export function hasPermissionToDashboard(allUserPermissions: UserPermissions[]) {
  return [constProvider.DASHBOARDS_PRIVILEGES.ADMIN_DASHBOARD, constProvider.DASHBOARDS_PRIVILEGES.DOCTORS_DASHBOARD]
    .map((privilege) => concatPermission(privilege, constProvider.PRIVILEGES.READ_ONLY_PROPERTIES))
    .some((permission) => hasPermission(allUserPermissions, permission));
}

export function concatPermission(
  ...parts: Array<SECTIONS_PRIVILEGES | DASHBOARDS_PRIVILEGES | SPECIFIC_PRIVILEGES | PRIVILEGES>
): UserPermissions {
  return parts.join('_') as UserPermissions;
}
