import React, { useState, useCallback, FunctionComponent, FormEvent } from 'react';
import { useLogin, useNotify, useTranslate, Notification, useRedirect } from 'react-admin';
import { TextField, Button } from '@material-ui/core';
import { Footer } from 'components/common/Footer/Footer';
import { ReactComponent as Logo } from 'assets/company-logo.svg';
import ChangeLanguageDropdown from 'components/common/ChangeLanguageDropdown/ChangeLanguageDropdown';
import { useStyles } from './LoginPage.styles';
import { ModalContent } from 'components/common';
import { constProvider } from 'providers';

export const LoginPage: FunctionComponent = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles();
  const redirectTo = useRedirect();

  const setName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }, []);

  const setPass = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, []);

  const submit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      login({ username, password })
        .then(() => {
          redirectTo(constProvider.MEDICALCENTER_SELECTION_PAGE_URI);
        })
        .catch(() => notify(translate('loginPage.notification.loginError')));
    },
    [redirectTo, login, username, password, notify, translate]
  );

  return (
    <div className={classes.container}>
      <div className={classes.languageDropdown}>
        <ChangeLanguageDropdown />
      </div>
      <div className={classes.loginWrapper}>
        <div className={classes.companyLogo}>
          <Logo />
        </div>

        <ModalContent title="loginPage.controls.loginForm.header">
          <form onSubmit={submit}>
            <TextField
              id="standard-basic"
              fullWidth
              label={translate('loginPage.controls.loginForm.loginLabel')}
              value={username}
              onChange={setName}
            />
            <TextField
              className={classes.password}
              id="standard-basic"
              fullWidth
              label={translate('loginPage.controls.loginForm.passwordLabel')}
              type="password"
              value={password}
              onChange={setPass}
            />
            <Button className={classes.submit} variant="contained" color="primary" type="submit">
              {translate('loginPage.action.login')}
            </Button>
          </form>
        </ModalContent>

        <Notification />
      </div>
      <Footer />
    </div>
  );
};
