import React, { ReactNode, useCallback, useEffect, useState, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useRedirect, useMutation, useNotify } from 'react-admin';
import { constProvider } from 'providers';
import { useMutationErrorHandler } from 'hooks/common';
import { UsersFormValues } from './UsersForm.models';

type UsersFormProps = {
  isEditMode?: boolean;
  resource?: string;
  steps: ReactNode[];
} & any;

export function UsersForm(props: UsersFormProps) {
  const { isEditMode, basePath, version, steps } = props;
  const [record, setRecord] = useState(props.record);
  const [stepsCount, setStepsCount] = useState(steps.length);
  const [selectedStep, setSelectedStep] = useState<number>(0);

  useEffect(() => {
    setStepsCount(steps.length);
    setSelectedStep(0);
  }, [steps]);

  const redirectTo = useRedirect();
  const [mutate] = useMutation();
  const notify = useNotify();
  const onFailure = useMutationErrorHandler('USER');

  const isLastStep = useMemo(() => {
    return selectedStep === stepsCount - 1;
  }, [selectedStep, stepsCount]);

  const onBackClick = useCallback(() => {
    setSelectedStep(Math.max(0, selectedStep - 1));
  }, [setSelectedStep, selectedStep]);

  const onCancelClick = useCallback(() => {
    redirectTo(`/${constProvider.RESOURCES.USER.URI}`);
  }, [redirectTo]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getData = useCallback(({ password2, ...data }: UsersFormValues) => {
    return data;
  }, []);

  const submit = useCallback(
    (values: UsersFormValues) => {
      const data = getData(values);

      if (isEditMode) {
        mutate(
          {
            type: 'update',
            resource: constProvider.RESOURCES.USER.URI,
            payload: {
              id: values.id,
              data
            }
          },
          {
            onSuccess: () => {
              notify('ra.notification.updated', 'info', {
                smart_count: 1
              });
              redirectTo('list', basePath);
            },
            onFailure
          }
        );
      } else {
        const mutationInstructions = [
          {
            type: record.id ? 'update' : 'create',
            resource: constProvider.RESOURCES.USER.URI,
            payload: record.id
              ? {
                  id: record.id,
                  data
                }
              : { data }
          },
          {
            type: 'patch',
            resource: constProvider.RESOURCES.USER_PRIVILEGES.URI,
            payload: {
              id: record.id,
              data: { privileges: data.privileges }
            }
          }
        ][selectedStep];
        mutate(mutationInstructions, {
          onSuccess: ({ data: newRecord }: any) => {
            notify(record.id ? 'ra.notification.updated' : 'ra.notification.created', 'info', {
              smart_count: 1
            });

            if (!isLastStep) {
              setSelectedStep(selectedStep + 1);
              setRecord({ ...record, ...newRecord, privileges: [] });
            } else {
              redirectTo('list', basePath);
            }
          },
          onFailure
        });
      }
    },
    [redirectTo, basePath, getData, isEditMode, mutate, notify, onFailure, selectedStep, isLastStep, record]
  );

  const renderForm = (formProps: FormRenderProps<UsersFormValues>) => {
    const SubView = steps[selectedStep];
    return (
      <form autoComplete={'false'} autoCorrect={'false'}>
        <SubView
          {...props}
          record={record}
          formProps={formProps}
          onBackClick={onBackClick}
          onCancelClick={onCancelClick}
          hasPrev={selectedStep > 0}
          hasNext={selectedStep < stepsCount - 1}
        />
      </form>
    );
  };

  return (
    <Form
      initialValues={props.record}
      onSubmit={submit}
      subscription={defaultSubscription}
      key={version}
      keepDirtyOnReinitialize
      render={renderForm}
    />
  );
}

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
};
