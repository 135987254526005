import { Card, Grid, List, ListItem, Typography, LinearProgress, Box } from '@material-ui/core';
import classnames from 'classnames';
import { get } from 'lodash';
import { PieChart } from './pie-chart/pie-chart';
import React, { useEffect, useState } from 'react';
import { useStyles } from './category-card.styles';
import { dashboardDoctorConsts } from '../dashboard-doctor/dashboard-doctor.const';
import { useTranslate, useDataProvider } from 'react-admin';
import { classesType, measurementCategoryLabelType } from './category-card.models';
import { DoctorCategorizedPatients } from 'models/models';
import { constProvider } from 'providers';

export function CategoryCard() {
  const classes: classesType = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [data, setData] = useState<DoctorCategorizedPatients | null>(null);

  useEffect(() => {
    dataProvider
      .getCustomUrl(constProvider.RESOURCES.PATIENT_CATEGORY_STATS.URI)
      .then(({ data }: { data: DoctorCategorizedPatients }) => {
        setData(data);
      });
  }, [dataProvider]);

  const measurementCategoryLabel: measurementCategoryLabelType = {
    emergencyCount: translate(dashboardDoctorConsts.CATERORY.EMERGENCY),
    warningCount: translate(dashboardDoctorConsts.CATERORY.WARNING),
    healthyCount: translate(dashboardDoctorConsts.CATERORY.HEALTHY),
    notValidCount: translate(dashboardDoctorConsts.CATERORY.NOT_VALID),
    unknownCount: translate(dashboardDoctorConsts.CATERORY.UNKNOWN)
  };

  const list = () => {
    const emergency = get(measurementCategoryLabel, 'emergencyCount', '');
    const warning = get(measurementCategoryLabel, 'warningCount', '');
    const healthy = get(measurementCategoryLabel, 'healthyCount', '');
    const notValid = get(measurementCategoryLabel, 'notValidCount', '');
    const unknown = get(measurementCategoryLabel, 'unknownCount', '');
    return (
      <List>
        <ListItem key={emergency} className={classnames([classes.listItem, classes['emergencyCount']])}>
          {emergency}
        </ListItem>
        <ListItem key={warning} className={classnames([classes.listItem, classes['warningCount']])}>
          {warning}
        </ListItem>
        <ListItem key={healthy} className={classnames([classes.listItem, classes['healthyCount']])}>
          {healthy}
        </ListItem>
        <ListItem key={notValid} className={classnames([classes.listItem, classes['notValidCount']])}>
          {notValid}
        </ListItem>
        <ListItem key={unknown} className={classnames([classes.listItem, classes['unknownCount']])}>
          {unknown}
        </ListItem>
      </List>
    );
  };

  return (
    <Card className={classes.container}>
      <Grid container justify="center">
        {data ? (
          <>
            <Grid item xs={3} md={12} lg={3}>
              <Typography component="h6" variant="h6">
                {translate(dashboardDoctorConsts.CATERORY.TITLE)}
              </Typography>
              <Typography variant="h3" className={classes.patientsNumber}>
                {data.patientCount}
              </Typography>
            </Grid>
            <Grid item xs={7} md={12} lg={7}>
              <Grid container justify="center">
                <PieChart data={data} />
              </Grid>
            </Grid>
            <Grid item xs={2} md={12} lg={2}>
              {list()}
            </Grid>
          </>
        ) : (
          <Box className={classes.indicator}>
            <LinearProgress />
          </Box>
        )}
      </Grid>
    </Card>
  );
}
