import { Epic } from 'redux-observable';
import { push } from 'connected-react-router';
import { filter, mapTo } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { get } from 'lodash';
import Types from 'AppTypes';
import { constProvider } from 'providers';
import { selectMedicalcenterContext } from './actions';

export const redirectAfterMedicalcenterSelection: Epic<Types.RootAction, Types.RootAction, Types.RootState> = (
  action$
) => action$.pipe(filter(isActionOf(selectMedicalcenterContext)), mapTo(push({ pathname: '/dashboard' })));

export const redirectToMedicalcenterSelectionIfNotSelected: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState
> = (action$, state$) =>
  action$.pipe(
    filter((action) => {
      return (
        // @ts-ignore
        action.type === '@@router/LOCATION_CHANGE' &&
        state$.value.selectedMedicalcenter === null &&
        ![constProvider.LOGIN_PAGE_URI, constProvider.MEDICALCENTER_SELECTION_PAGE_URI].includes(
          get(action, 'payload.location.pathname')
        )
      );
    }),
    mapTo(push({ pathname: constProvider.MEDICALCENTER_SELECTION_PAGE_URI }))
  );
