import { makeStyles } from '@material-ui/styles';
import { colors } from 'style';

export const useStyles = makeStyles((theme: any) => ({
  addressField: {
    width: '100%',
    marginRight: '4px'
  },
  searchButton: {
    height: '48px',
    borderRadius: '4px',
    backgroundColor: colors.lightBlue,
    '&:hover': {
      backgroundColor: colors.darkerBlue
    }
  },
  searchIcon: {
    color: colors.white
  }
}));
