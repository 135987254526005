import React from 'react';
import { Pagination } from 'components/common';

export const defaultListProps = {
  hasCreate: false,
  hasEdit: false,
  hasList: false,
  hasShow: false,
  actions: null,
  bulkActionButtons: false,
  pagination: <Pagination />,
  perPage: 10,
  title: ' '
};
