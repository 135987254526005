import { colors } from 'style/colors';
import { fontWeight } from 'style';
import { makeStyles } from '@material-ui/core/styles';

type LineProps = {
  color: string;
};

export const useStyles = makeStyles((theme) => ({
  legend: {
    padding: '25px'
  },
  items: { marginTop: '30px' },
  date: {
    color: theme.palette.text.secondary
  },
  highest: {
    color: '#d0021b',
    fontWeight: fontWeight.bold,
    fontSize: '18px'
  },
  lowest: {
    color: colors.darkBlue,
    fontWeight: fontWeight.bold,
    fontSize: '18px'
  },
  title: {
    fontSize: '12px'
  },
  line: {
    width: '20px',
    height: '3px',
    marginRight: '10px',
    backgroundColor: (props: LineProps) => props.color
  },
  mediumText: {
    fontWeight: fontWeight.medium
  }
}));
