import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    margin: '24px'
  },
  subtitle: {
    fontWeight: 500
  },
  permitSubCenters: {
    '& label': {
      marginRight: '30px'
    }
  },
  cancelButton: {
    marginRight: '30px'
  }
});
