export const dashboardDoctorConsts = Object.freeze({
  CATERORY: {
    EMERGENCY: 'common.measurementCategory.emergency',
    WARNING: 'common.measurementCategory.warning',
    HEALTHY: 'common.measurementCategory.healthy',
    NOT_VALID: 'common.measurementCategory.notValid',
    UNKNOWN: 'common.measurementCategory.unknown',
    TITLE: 'dashboard.doctor.category.title'
  },
  TYPE: {
    TEMPERATURE: 'common.measurementType.temperature',
    SATURATION: 'common.measurementType.saturation',
    PULSE: 'common.measurementType.pulse',
    TITLE: 'dashboard.doctor.type.title',
    TEXT: 'dashboard.doctor.type.text'
  },
  FILTERS: {
    FIRST_NAME: 'dashboard.doctor.filters.firstName',
    LAST_NAME: 'dashboard.doctor.filters.lastName',
    PESEL: 'dashboard.doctor.filters.pesel',
    GENDER: 'dashboard.doctor.filters.gender',
    MEDICAL_CENTER: 'dashboard.doctor.filters.medicalCenter',
    DEVICE_SERIAL_NUMBER: 'dashboard.doctor.filters.deviceSerialNumber',
    EXAM_DATE_FROM: 'dashboard.doctor.filters.examDateFrom',
    EXAM_DATE_TO: 'dashboard.doctor.filters.examDateTo',
    STATUS_FROM: 'dashboard.doctor.filters.statusFrom',
    STATUS_TO: 'dashboard.doctor.filters.statusTo',
    STATUS: 'dashboard.doctor.filters.status',
    CATEGORY: 'dashboard.doctor.filters.category'
  }
});
