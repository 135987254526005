import { Box, Grid } from '@material-ui/core';
import { PatientMeasurementDto } from 'models/models';
import { Error as ErrorComponent, Loading, useDataProvider, useTranslate } from 'react-admin';
import React, { useEffect, useState } from 'react';

import { BasicInfoCard } from './basic-info-card/basic-info-card';
import { Chart } from './chart/chart';
import { ListCard } from './list-card/list-card';
import { MeasurementCard } from './measurement-card/measurement-card';
import { ReactComponent as PulseIcon } from './assets/pulse.svg';
import { ReactComponent as SaturationIcon } from './assets/saturation.svg';
import { StatusCard } from './status-card/status-card';
import { ReactComponent as TemperatureIcon } from './assets/temperature.svg';
import { constProvider } from 'providers';
import { patientDashboardConsts } from './patient-dashboard.const';
import { useStyles } from './patient-dashboard.styles';

export function PatientDashboard(props: any) {
  const { record } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [data, setData] = useState<PatientMeasurementDto | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    dataProvider
      .getCustomUrl(`${constProvider.RESOURCES.PATIENT.URI}/${record.id}/measurement`)
      .then(({ data }: { data: any }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error: Error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, record.id]);

  if (loading) return <Loading />;

  if (error || !data) return <ErrorComponent />;

  const { temperatureStats, pulseStats, saturationStats } = data.measurement;

  return (
    <Box className={classes.container}>
      <Grid container spacing={3}>
        <Grid container item spacing={3} alignItems="stretch">
          <Grid item={true} xs={12} sm={6} md={3}>
            <BasicInfoCard className={classes.card} data={data} />
          </Grid>

          <Grid item={true} xs={12} sm={6} md={3}>
            <MeasurementCard
              className={classes.card}
              icon={<TemperatureIcon className={classes.measurementIcon} />}
              label={translate(patientDashboardConsts.MEASUREMENT.TEMPERATURE)}
              data={temperatureStats}
              suffix="°C"
            />
          </Grid>

          <Grid item={true} xs={12} sm={6} md={3}>
            <MeasurementCard
              className={classes.card}
              icon={<PulseIcon className={classes.measurementIcon} />}
              label={translate(patientDashboardConsts.MEASUREMENT.PULSE)}
              data={pulseStats}
            />
          </Grid>

          <Grid item={true} xs={12} sm={6} md={3}>
            <MeasurementCard
              className={classes.card}
              icon={<SaturationIcon className={classes.measurementIcon} />}
              label={translate(patientDashboardConsts.MEASUREMENT.SATURATION)}
              data={saturationStats}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <StatusCard data={data} />
        </Grid>

        <Grid container item spacing={3} justify="flex-start">
          <Grid item={true} xs={12} sm={6} md={8}>
            <Chart
              data={temperatureStats}
              legend={translate(patientDashboardConsts.CHART.TEMPERATURE)}
              chartType="TEMPERATURE"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4}>
            <ListCard
              className={classes.card}
              label={translate(patientDashboardConsts.LIST.TEMPERATURE)}
              list={temperatureStats.stats}
              suffix="°C"
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} justify="flex-end">
          <Grid item={true} xs={12} sm={6} md={8}>
            <Chart
              data={saturationStats}
              legend={translate(patientDashboardConsts.CHART.SATURATION)}
              chartType="SATURATION"
            />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4}>
            <ListCard
              className={classes.card}
              label={translate(patientDashboardConsts.LIST.SATURATION)}
              list={saturationStats.stats}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3} justify="flex-end">
          <Grid item={true} xs={12} sm={6} md={8}>
            <Chart data={pulseStats} legend={translate(patientDashboardConsts.CHART.PULSE)} chartType="PULSE" />
          </Grid>
          <Grid item={true} xs={12} sm={6} md={4}>
            <ListCard
              className={classes.card}
              label={translate(patientDashboardConsts.LIST.PULSE)}
              list={pulseStats.stats}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
