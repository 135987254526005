import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useTranslate } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import { isSidebarOpen } from 'features/ui/selectors';
import { useClasses } from './CustomMenu.styles';
import { ResourceType } from './CustomMenu.models';

const CustomMenu: FC = (props: any) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const open = useSelector(isSidebarOpen);
  const resources: ResourceType[] = useSelector(getResources);
  const useClass = useClasses();
  const translate = useTranslate();

  return (
    <div>
      {resources
        .filter((resource) => resource.hasList && (!resource.options.permissions || resource.options.permissions.list))
        .map((resource) => (
          <MenuItemLink
            classes={useClass}
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label && translate(resource.options.label)) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={props.onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {isXSmall && props.logout}
    </div>
  );
};

export default CustomMenu;
