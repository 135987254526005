import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'style/colors';
import { fontWeight } from 'style/fontWeight';

export const useStyles = makeStyles({
  label: {
    color: 'white',
    width: '112px',
    height: '23px',
    borderRadius: '3px',
    fontSize: '14px',
    fontWeight: fontWeight.medium,
    display: 'inline-block'
  },
  red: {
    backgroundColor: colors.darkRed
  },
  blue: {
    backgroundColor: colors.darkerBlue
  },
  green: { backgroundColor: colors.lighterGreen },
  yellow: { backgroundColor: colors.darkYellow },
  unknown: { backgroundColor: colors.gray }
});
