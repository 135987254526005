import React from 'react';
import { useTranslate, Loading } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { colors } from 'style';

interface PageContentWithTitleProps {
  children?: React.ReactElement;
  title?: string;
  titleInterpolation?: object;
}

const useStyles = makeStyles({
  root: {
    padding: '25px 25px 25px 95px'
  },
  header: {
    fontWeight: 500,
    color: colors.darkBlue,
    'margin-bottom': '15px'
  }
});

export function PageContentWithTitle({ title, titleInterpolation, children }: PageContentWithTitleProps) {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title ? (
        <Typography className={classes.header} variant="h6" component="h1">
          {translate(title, titleInterpolation)}
        </Typography>
      ) : (
        <Loading />
      )}
      {children}
    </div>
  );
}
