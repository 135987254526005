import { constProvider } from './const';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { axiosFailureToFetchJsonAPI, httpInstance } from './http';
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { upperFirst, forIn } from 'lodash';
import { HttpError } from 'ra-core';
import axiosRetry from 'axios-retry';

import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { UserPermissions } from '../components/resources/user';

const AUTH_REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';
const AUTH_ACCESS_TOKEN_LOCAL_KEY = 'accessToken';
const AUTH_TOKEN_TYPE_STORAGE_KEY = 'tokenType';
const PERMISSIONS_STORAGE_KEY = 'permissions';
const AUTH_REFRESH_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY = 'refreshTokenExpirationTimestamp';

const LOGIN_GRANT_TYPE = 'password';
const REFRESH_GRANT_TYPE = 'refresh_token';

const AUTHORIZATION_HEADER_KEY = 'Authorization';
const CONTENT_TYPE_HEADER_KEY = 'Content-Type';
const BASIC_AUTHORISATION_TOKEN_TYPE = 'basic';
const CONTENT_TYPE_MULTIPART_DATA = { [CONTENT_TYPE_HEADER_KEY]: 'multipart/form-data' };

const TOKEN_STORAGE: Storage = localStorage;

type Token = {
  exp: number;
  authorities: UserPermissions[];
  client_id: string;
  organization_path: string;
  user_name: string;
};

type AccessToken = Token & { jti: string };
type RefreshToken = Token & { ati: string };

interface ApiTokenResponse {
  readonly access_token: string;
  readonly refresh_token: string;
  readonly token_type: string;
  readonly expires_in: number;
  readonly scope: string;
  readonly jti: string;
}

interface AuthProviderLoginParams {
  readonly username: string;
  readonly password: string;
}

export interface Permissions<T = UserPermissions[]> {
  loading: boolean;
  loaded: boolean;
  permissions?: T;
  error?: any;
}

export interface ReactAdminAuthProvider {
  login(params: AuthProviderLoginParams): Promise<void>;
  logout(): Promise<void>;
  checkAuth(): Promise<void>;
  checkError(error: HttpError): Promise<void>;
  getPermissions(): Promise<UserPermissions[]>;
  getToken(): string | null;
}

class AuthProvider implements ReactAdminAuthProvider {
  private axiosInstance: AxiosInstance;
  private isRefreshingToken = false;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.setupAxios(axiosInstance);
  }

  public refreshAuthLogic = (failedRequest: AxiosError<any>) => {
    if (!this.refreshToken) {
      return Promise.reject();
    }

    this.isRefreshingToken = true;
    return this.axiosInstance
      .post(constProvider.AUTH_TOKEN_REFRESH_ENDPOINT_URL, getTokenRefreshFormData(this.refreshToken), {
        skipAuthRefresh: true,
        timeout: 2000,
        headers: {
          ...CONTENT_TYPE_MULTIPART_DATA,
          ...authorisationHeader(BASIC_AUTHORISATION_TOKEN_TYPE, constProvider.AUTH_LOGIN_AUTHORIZATION_SECRET)
        }
      } as AxiosAuthRefreshRequestConfig)
      .then(
        ({
          data: { access_token: accessToken, refresh_token: refreshToken, token_type: tokenType }
        }: AxiosResponse<ApiTokenResponse>) => {
          this.refreshTokenExpirationTime = Number((jwt_decode(refreshToken) as RefreshToken).exp || 0);
          this.accessToken = accessToken;
          this.refreshToken = refreshToken;
          this.tokenType = tokenType;

          failedRequest.response!.config.headers = {
            ...failedRequest.response!.config.headers,
            ...authorisationHeader(tokenType, accessToken)
          };

          return Promise.resolve();
        }
      )
      .catch((error: AxiosError<any>): Promise<void> | void => {
        if (error?.response?.status === 401) {
          return this.logout();
        }

        axiosFailureToFetchJsonAPI(error);
      })
      .finally(() => {
        this.isRefreshingToken = false;
      });
  };

  private authRequestInterceptor = (request: AxiosRequestConfig) => {
    if (this.tokenType && this.accessToken) {
      if (request.url === constProvider.AUTH_TOKEN_REFRESH_ENDPOINT_URL) {
        request.headers = { ...authorisationHeader(this.tokenType, this.accessToken), ...request.headers };
      } else {
        request.headers = { ...request.headers, ...authorisationHeader(this.tokenType, this.accessToken) };
      }
    }

    return request;
  };

  private set accessToken(value: string | null) {
    if (null !== value) {
      TOKEN_STORAGE.setItem(AUTH_ACCESS_TOKEN_LOCAL_KEY, value);
    } else {
      TOKEN_STORAGE.removeItem(AUTH_ACCESS_TOKEN_LOCAL_KEY);
    }
  }

  private get accessToken(): string | null {
    return TOKEN_STORAGE.getItem(AUTH_ACCESS_TOKEN_LOCAL_KEY);
  }

  private set refreshToken(value: string | null) {
    if (null !== value) {
      TOKEN_STORAGE.setItem(AUTH_REFRESH_TOKEN_STORAGE_KEY, value);
    } else {
      TOKEN_STORAGE.removeItem(AUTH_REFRESH_TOKEN_STORAGE_KEY);
    }
  }

  private get refreshToken(): string | null {
    return TOKEN_STORAGE.getItem(AUTH_REFRESH_TOKEN_STORAGE_KEY);
  }

  private set tokenType(value: string | null) {
    if (null !== value) {
      TOKEN_STORAGE.setItem(AUTH_TOKEN_TYPE_STORAGE_KEY, value);
    } else {
      TOKEN_STORAGE.removeItem(AUTH_TOKEN_TYPE_STORAGE_KEY);
    }
  }

  private get tokenType(): string | null {
    return TOKEN_STORAGE.getItem(AUTH_TOKEN_TYPE_STORAGE_KEY);
  }

  private get permissions(): UserPermissions[] {
    const rawData = TOKEN_STORAGE.getItem(PERMISSIONS_STORAGE_KEY);
    return rawData ? (JSON.parse(rawData) as UserPermissions[]) : [];
  }

  private set permissions(value: UserPermissions[]) {
    TOKEN_STORAGE.setItem(PERMISSIONS_STORAGE_KEY, JSON.stringify(value));
  }

  private get refreshTokenExpirationTime(): number {
    return Number.parseInt(TOKEN_STORAGE.getItem(AUTH_REFRESH_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY) || '');
  }

  private set refreshTokenExpirationTime(value: number) {
    TOKEN_STORAGE.setItem(AUTH_REFRESH_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY, value.toString());
  }

  public async login(params: AuthProviderLoginParams): Promise<void> {
    return this.loginOnServer(params)
      .then(({ accessToken, tokenType, refreshToken }) => {
        const { exp: refreshTokenExpirstionTimestamp } = jwt_decode(refreshToken) as RefreshToken;
        const { authorities } = jwt_decode(accessToken) as AccessToken;

        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.tokenType = tokenType;
        this.permissions = authorities;
        this.refreshTokenExpirationTime = Number(refreshTokenExpirstionTimestamp);

        if (!authorities.includes('ROLE_SUPER_ADMIN')) {
          return;
        }

        return this.axiosInstance
          .get<{ id: number; name: UserPermissions }[]>(constProvider.AUTH_USER_PRIVILEGES_URL)
          .then(({ data }) => {
            this.permissions = ['ROLE_SUPER_ADMIN', ...data.map(({ name }) => name)];
          });
      })
      .catch(() => Promise.reject());
  }

  public async logout(): Promise<void> {
    return this.logoutOnServer(this.tokenType, this.accessToken).finally(() => {
      this.accessToken = null;
      this.refreshToken = null;
      this.tokenType = null;
      this.permissions = [];
      this.refreshTokenExpirationTime = 0;
    });
  }

  public async checkAuth(): Promise<void> {
    return this.refreshTokenExpirationTime >= moment().unix() && this.accessToken
      ? Promise.resolve()
      : Promise.reject();
  }

  public async checkError({ status }: HttpError): Promise<void> {
    if ([401, 403].includes(status) && !this.isRefreshingToken) {
      return Promise.reject();
    }

    return Promise.resolve();
  }

  public async getPermissions(): Promise<UserPermissions[]> {
    return Promise.resolve(this.permissions);
  }

  public getToken() {
    return this.accessToken;
  }

  private loginOnServer({
    username,
    password
  }: AuthProviderLoginParams): Promise<{
    accessToken: string;
    refreshToken: string;
    tokenType: string;
  }> {
    return this.axiosInstance
      .post<ApiTokenResponse>(constProvider.AUTH_LOGIN_ENDPOINT_URL, getLoginFormData(username, password), {
        skipAuthRefresh: true,
        timeout: 2000,
        headers: {
          ...CONTENT_TYPE_MULTIPART_DATA,
          ...authorisationHeader(BASIC_AUTHORISATION_TOKEN_TYPE, constProvider.AUTH_LOGIN_AUTHORIZATION_SECRET)
        }
      } as AxiosAuthRefreshRequestConfig)
      .then(
        ({
          data: { access_token: accessToken, refresh_token: refreshToken, token_type: tokenType }
        }: AxiosResponse<ApiTokenResponse>) => {
          return {
            accessToken,
            refreshToken,
            tokenType
          };
        },
        () => Promise.reject()
      );
  }

  private logoutOnServer(tokenType: string | null, accessToken: string | null): Promise<void> {
    // @ts-ignore
    return !(tokenType && accessToken)
      ? Promise.resolve()
      : this.axiosInstance
          .delete(constProvider.AUTH_LOGOUT_ENDPOINT_URL, {
            headers: {
              ...authorisationHeader(tokenType, accessToken)
            }
          })
          .catch(axiosFailureToFetchJsonAPI);
  }

  private setupAxios = (axiosInstance: AxiosInstance): void => {
    createAuthRefreshInterceptor(axiosInstance, this.refreshAuthLogic, { skipWhileRefreshing: false });
    axiosRetry(axiosInstance, { retries: 3, retryDelay: (retryCount) => this.retryDelay(retryCount) });
    axiosInstance.interceptors.request.use(this.authRequestInterceptor);
  };

  private retryDelay = (retryCount: number): number => {
    switch (retryCount) {
      case 1: {
        return 200;
      }

      case 2: {
        return 400;
      }

      case 3: {
        return 900;
      }

      default: {
        return retryCount * retryCount * 100;
      }
    }
  };
}

function authorisationHeader(tokenType: string, token: string) {
  return { [AUTHORIZATION_HEADER_KEY]: `${upperFirst(tokenType)} ${token}` };
}

function getFormData(data: object) {
  const formData = new FormData();

  forIn(data, (value, key) => {
    formData.append(key, value);
  });

  return formData;
}

function getLoginFormData(username: string, password: string) {
  return getFormData({ username, password, grant_type: LOGIN_GRANT_TYPE });
}

function getTokenRefreshFormData(refreshToken: string) {
  return getFormData({
    grant_type: REFRESH_GRANT_TYPE,
    refresh_token: refreshToken
  });
}

export const authProvider = new AuthProvider(httpInstance);
