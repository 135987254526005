import { makeStyles } from '@material-ui/core/styles';
import * as styles from 'style';

export const useStyles = makeStyles({
  drawerPaper: {
    marginTop: 0,
    backgroundColor: styles.colors.darkBlue,
    position: 'fixed',
    top: '40px',
    height: '100%',
    zIndex: 10,
    overflowY: 'hidden'
  }
});
