import { makeStyles } from '@material-ui/core/styles';

export const useClasses = makeStyles({
  root: {
    color: 'white'
  },
  icon: {
    color: 'white'
  }
});
