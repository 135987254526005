import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';
import { constProvider } from 'providers';
import { FilterProps } from 'types';

const useStyles = makeStyles({
  input: {
    minWidth: '220px'
  },
  filters: {
    'margin-bottom': '20px'
  }
});

export type UniversalFilterFields<T extends object> = Array<{
  source: keyof T;
  resource?: keyof typeof constProvider.RESOURCES;
}>;

export type UniversalFilterProps<T extends object> = {
  className?: string;
  fields: UniversalFilterFields<T>;
} & FilterProps<T>;

export function UniversalFilter<T extends object>({ className, fields, ...props }: UniversalFilterProps<T>) {
  const classes = useStyles();

  const filterClassName = useMemo(() => classnames(classes.filters, className), [className, classes.filters]);

  const inputs = useMemo(
    () =>
      fields.map(({ source, resource }) => {
        return resource ? (
          <ReferenceInput
            key={source}
            className={classes.input}
            source={source}
            reference={constProvider.RESOURCES[resource].URI}
            alwaysOn
          >
            <SelectInput variant="standard" />
          </ReferenceInput>
        ) : (
          <TextInput key={source} className={classes.input} source={source} alwaysOn />
        );
      }),
    [fields, classes.input]
  );

  return (
    <Filter {...props} className={filterClassName}>
      {inputs}
    </Filter>
  );
}
