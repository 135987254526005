import { Tab, TabProps, Tabs } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

import React from 'react';
import { colors } from 'style/colors';

interface StyledTabProps {
  label: string;
  value: string;
}

export const CustomTabs = withStyles({
  root: {
    background: colors.white,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    boxShadow: '0 0 9px 0 rgba(0, 0, 0, 0.03);'
  },
  indicator: {
    backgroundColor: colors.green,
    height: '3px'
  }
})(Tabs);

export const CustomTab = withStyles(() =>
  createStyles({
    root: {
      padding: '21px 0',
      '&:hover': {
        color: colors.green,
        opacity: 1
      },
      '&$selected': {
        color: colors.green
      },
      '&:focus': {
        color: colors.green
      }
    },
    selected: {}
  })
)((props: TabProps) => <Tab disableRipple {...props} />);
