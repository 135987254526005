import { selectMedicalcenterContext } from './actions';
import { createReducer } from 'typesafe-actions';
import { CLEAR_STATE } from 'react-admin';

export const selectedMedicalcenter = createReducer(null as string | null)
  .handleAction(selectMedicalcenterContext, (state, action) => action.payload)
  .handleAction(CLEAR_STATE, (state) => null);

export default selectedMedicalcenter;
export type SelectedMedicalcenterState = ReturnType<typeof selectedMedicalcenter>;
