import React from 'react';
import { useTranslate } from 'react-admin';
import { ValuesCard } from 'components/common/values-card/values-card';
import { DeviceLocationDataDTO } from '../patient-localization-form.models';
import { deviceLocationCardConst } from './device-location-card.const';

type DeviceLocationCardProps = {
  data?: DeviceLocationDataDTO;
};

export function DeviceLocationCard({ data }: DeviceLocationCardProps) {
  const translate = useTranslate();

  return (
    <ValuesCard
      header={translate(deviceLocationCardConst.HEADER)}
      items={[
        {
          label: translate(deviceLocationCardConst.POSITION_DILUTION_OF_PRECISION),
          value: data && data.positionDilutionOfPrecision ? `${data.positionDilutionOfPrecision}m` : undefined
        },
        {
          label: translate(deviceLocationCardConst.SATELLITES_AVAILABLE),
          value: data ? data.satellitesAvailable : undefined
        },
        { label: translate(deviceLocationCardConst.SATELLITES_USED), value: data ? data.satellitesUsed : undefined },
        { label: translate(deviceLocationCardConst.LATITUDE), value: data ? data.latitude : undefined },
        { label: translate(deviceLocationCardConst.LONGITUDE), value: data ? data.longitude : undefined }
      ]}
    />
  );
}
