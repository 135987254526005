import italianMessages from 'ra-language-italian';

export const messages = {
  ...italianMessages,
  common: {
    active: {
      label: 'Attivo',
      active: 'Attivo',
      notActive: 'Non attivo'
    }
  },
  loginPage: {
    auth: {
      logout: 'Disconnettere'
    },
    notification: {
      loginError: 'Nome utente o password errati'
    },
    action: {
      login: 'Accesso negozio'
    },
    controls: {
      loginForm: {
        header: 'Accesso negozio',
        loginLabel: 'Accesso',
        passwordLabel: 'Password'
      }
    }
  },
  medicalcenter: {
    list: {
      header: 'Centri',
      labels: {
        name: 'Nome',
        city: 'Città',
        active: 'Attivo',
        notActive: 'Non attivo',
        subCenters: 'Sottocentri',
        available: 'Disponibile',
        notAvailable: 'Non disponibile'
      }
    }
  }
};
