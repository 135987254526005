import React, { forwardRef } from 'react';
import { Paper, AppBar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import * as styles from 'style';
import { useTranslate } from 'react-admin';
import classnames from 'classnames';

interface ModalContentProps {
  className?: string;
  contentClassName?: string;
  title: string;
}

export const ModalContent = forwardRef<any, React.PropsWithChildren<ModalContentProps>>(
  ({ title, className, contentClassName, children }: React.PropsWithChildren<ModalContentProps>, ref) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
      <Paper elevation={0} className={classnames([classes.card, className])} ref={ref}>
        <AppBar className={classes.header} position="static">
          <Typography variant="h6">{translate(title)}</Typography>
        </AppBar>
        <div className={classnames([classes.modalContent, contentClassName])}>{children}</div>
      </Paper>
    );
  }
);
ModalContent.displayName = 'ModalContent';

const useStyles = makeStyles({
  card: {
    borderRadius: '4px',
    overflow: 'hidden',
    maxWidth: '555px',
    margin: '0 auto'
  },
  header: {
    background: styles.colors.primaryBlue,
    padding: '15px 30px'
  },
  modalContent: {
    padding: '30px 60px 54px 60px'
  }
});
