import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 18px 0',
    minHeight: '100%',
    marginLeft: '10px'
  },
  title: {
    marginBottom: '40px'
  },
  chart: {
    marginBottom: '20px'
  },
  indicator: {
    width: '100px'
  }
}));
