import { makeStyles } from '@material-ui/core';
import * as styles from 'style';

export const useStyles = makeStyles({
  container: {
    background: styles.colors.lightGray,
    height: '100vh'
  },
  languageDropdown: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loginWrapper: {
    maxWidth: '555px',
    margin: '0 auto'
  },
  companyLogo: {
    maxWidth: '300px',
    margin: '0 auto',
    padding: '50px 0px'
  },
  card: {
    borderRadius: '4px',
    overflow: 'hidden'
  },
  header: {
    background: styles.colors.primaryBlue,
    padding: '15px 30px'
  },
  formContent: {
    padding: '30px 60px 60px 60px'
  },
  submit: {
    marginTop: '50px',
    width: '100%'
  },
  password: {
    marginTop: '30px'
  }
});
