import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from 'style';

export const myTheme = createMuiTheme({
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(',')
  },
  overrides: {
    // @ts-ignore
    RaShow: {
      card: {
        boxShadow: 'none',
        borderRadius: 0
      }
    },
    RaTabbedShowLayout: {
      content: {
        paddingLeft: '0',
        paddingRight: '0',
        backgroundColor: colors.lightGray
      }
    },
    MuiButton: {
      label: {
        fontWeight: 450,
        letterSpacing: '1px'
      },
      textPrimary: {
        color: colors.lightBlue
      },
      containedPrimary: {
        backgroundColor: colors.lightBlue
      }
    },
    MuiSwitch: {
      colorPrimary: {
        color: colors.lightGray,

        '&.Mui-disabled': {
          '& + .MuiSwitch-track': {
            backgroundColor: colors.gray137
          }
        },
        '&.Mui-checked': {
          color: colors.primaryBlue,
          '& + .MuiSwitch-track': {
            backgroundColor: colors.lighterBlue
          }
        }
      }
    }
  }
});
