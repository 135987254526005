export const medicalCenterConsts = Object.freeze({
  LIST_TITLE: 'medicalcenter.list.header',
  CREATE_TITLE: 'medicalcenter.create.title',
  EDIT_TITLE: 'medicalcenter.edit.title',
  LABELS: {
    NAME: 'medicalcenter.list.labels.name',
    CITY: 'medicalcenter.list.labels.city',
    ACTIVE: 'medicalcenter.list.labels.active',
    SUBCENTERS: 'medicalcenter.list.labels.subCenters'
  }
});

export const medicalCenterDictionary = Object.freeze({
  SUBCENTERS: [
    { id: 'true', name: 'medicalcenter.list.labels.available' },
    { id: 'false', name: 'medicalcenter.list.labels.notAvailable' }
  ]
});
