import 'chartjs-plugin-annotation';

import React, { useEffect, useRef } from 'react';
import { colors } from 'style/colors';

import Chartjs from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { chartOptions } from './config';

interface MeasurementExceedValues {
  exceed: number;
  total: number;
}

interface ExceedChartProps {
  data: MeasurementExceedValues;
  type: string;
}

export function ExceedChart({ data, type }: ExceedChartProps) {
  const chartContainer = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const { exceed, total } = data;
      type tbarColor = {
        [key: string]: string;
      };
      const barColor: tbarColor = {
        temperature: colors.green,
        saturation: colors.primaryBlue,
        pulse: colors.primaryBlueDark
      };
      const mappedChartData = {
        labels: [],
        datasets: [
          {
            data: [exceed],
            backgroundColor: barColor[type],
            datalabels: {
              color: colors.white
            }
          },
          {
            data: total === 0 ? [1] : [total - exceed],
            backgroundColor: colors.lightGray,
            hoverBackgroundColor: colors.lightGray,
            datalabels: {
              labels: {
                title: null
              }
            }
          }
        ]
      };
      const chartConfig = {
        type: 'horizontalBar',
        data: mappedChartData,
        options: chartOptions,
        plugins: [ChartDataLabels]
      };
      new Chartjs(chartContainer.current, chartConfig);
    }
  }, [chartContainer, data, type]);

  if (!data) return null;

  return <canvas ref={chartContainer} height="20" />;
}
