import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { InsertChartOutlined, Timer } from '@material-ui/icons';
import React, { ReactNode } from 'react';

import { MeasurementDetailsDto } from 'models/models';
import { isNil, isEmpty } from 'lodash';
import { useStyles } from './measurement-card.styles';
import moment from 'moment';
import { constProvider } from 'providers';

type MeasurementCardProps = {
  className: string;
  icon: ReactNode;
  label: string;
  data?: MeasurementDetailsDto;
  suffix?: string;
};

export function MeasurementCard({ className, icon, label, data, suffix }: MeasurementCardProps) {
  const classes = useStyles();
  if (!data) return null;

  const { numberOfAll, stats } = data;

  if (isEmpty(stats)) return null;

  const stat = {
    value: stats[0].value ?? 0,
    examDate: moment(stats[0].examDate).format(constProvider.DATE_TIME_FORMAT) ?? '-',
    status: stats[0].status ?? 'black'
  };

  const color = {
    color: stat.status
  };

  return (
    <Card className={className}>
      <CardContent className={classes.measurementContent}>
        {icon}
        <Typography variant="h6" component="p" align="center">
          {label}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="h3" component="p" className={classes.measurementValue} style={color}>
            {stat.value} {!isNil(suffix) && suffix}
          </Typography>
        </Box>
        <div className={classes.bottom}>
          <div className={classes.iconContainer}>
            <Timer className={classes.bottomIcon} /> <Typography variant="subtitle2">{stat.examDate}</Typography>{' '}
          </div>
          <div className={classes.iconContainer}>
            <InsertChartOutlined className={classes.bottomIcon} />{' '}
            <Typography variant="subtitle2">{numberOfAll || 0}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
