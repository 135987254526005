import { Style, Stroke, Fill, Icon } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { hexToRgb } from 'services/utils';
import { colors } from 'style';
import patientTagIcon from './assets/patient-tag-icon.svg';
import deviceTagIcon from './assets/device-tag-icon.svg';

export const MAP_PROJECTION = 'EPSG:3857';

export const DEFAULT_CENTER_COORDINATE = [21.012229, 52.229676];

export const DEFAULT_ZOOM_LEVEL = 16;

export const REVERSE_ADDRESS_LOOKUP_ZOOM_LEVEL = 18;

export const LOCATION_LOCKDOWN_LAYER_STYLE = new Style({
  image: new Icon({
    anchor: [0.5, 46],
    anchorXUnits: IconAnchorUnits.FRACTION,
    anchorYUnits: IconAnchorUnits.PIXELS,
    opacity: 0.95,
    src: patientTagIcon
  }),
  stroke: new Stroke({
    width: 3,
    color: hexToRgb('#00c853'),
    lineDash: [5, 5]
  }),
  fill: new Fill({
    color: [255, 255, 255, 0.5]
  })
});

export const DEVICE_LOCATION_LAYER_STYLE = new Style({
  image: new Icon({
    anchor: [0.5, 46],
    anchorXUnits: IconAnchorUnits.FRACTION,
    anchorYUnits: IconAnchorUnits.PIXELS,
    opacity: 0.95,
    src: deviceTagIcon
  }),
  stroke: new Stroke({
    width: 3,
    color: hexToRgb(colors.green),
    lineDash: [5, 5]
  }),
  fill: new Fill({
    color: [255, 255, 255, 0.5]
  })
});

export const DEVICE_OUTSIDE_LOCK_DOWN_LOCATION_FILTER = {
  COLOR: [195, 39, 39],
  VALUE: 0.8
};

export const mapCardTranslationConsts = Object.freeze({
  LEGEND: {
    LOCATION_LOCK_DOWN: 'resources.patient.edit.localization.mapCard.legend.locationLockDown',
    DEVICE_LOCATION: 'resources.patient.edit.localization.mapCard.legend.deviceLocation'
  },
  MESSAGES: {
    REVERSE_ADDRESS_LOOKUP_ERROR_MESSAGE: 'resources.patient.edit.localization.mapCard.legend.locationLockDown'
  }
});
