import React, { useMemo } from 'react';
import { Pagination as ReactAdminPagination } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { PaginationLimit } from './pagination-limit';
import { colors } from 'style';

type PaginationProps = { resource: string; isCreateButtonAware?: boolean } & any;

export function Pagination({ isCreateButtonAware = true, resource, ...props }: PaginationProps) {
  const classes = useStyles();

  const paginationClasses = useMemo(() => {
    return !isCreateButtonAware
      ? undefined
      : {
          toolbar: classes.pagination
        };
  }, [isCreateButtonAware, classes.pagination]);

  return (
    <ReactAdminPagination classes={paginationClasses} {...props} limit={<PaginationLimit resource={resource} />} />
  );
}

const useStyles = makeStyles({
  pagination: {
    paddingRight: '20px',

    '& button.MuiButton-textPrimary': {
      color: colors.darkBlue
    }
  }
});
