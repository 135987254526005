import React from 'react';
import classnames from 'classnames';
import { Typography } from '@material-ui/core';
import { matches, constant, cond, get } from 'lodash';
import { constProvider } from 'providers';
import { PatientCategoryEnum } from 'models/models';
import { useStyles } from './patient-category-badge.styles';

type patientCategoryBadgeProps = {
  category: PatientCategoryEnum | string;
};

export function PatientCategoryBadge({ category }: patientCategoryBadgeProps) {
  const classes = useStyles();

  if (!category) return <Typography align="center">-</Typography>;

  const getValueStyle = cond([
    [matches(constProvider.PATIENT_CATEGORY.RED), constant(classes.red)],
    [matches(constProvider.PATIENT_CATEGORY.YELLOW), constant(classes.yellow)],
    [matches(constProvider.PATIENT_CATEGORY.GREEN), constant(classes.green)],
    [matches(constProvider.PATIENT_CATEGORY.BLUE), constant(classes.blue)],
    [matches(constProvider.PATIENT_CATEGORY.UNKNOWN), constant(classes.unknown)]
  ]);

  const { label } = classes;

  const text = get(constProvider.PATIENT_COLOR_TO_CATEGORY, category);

  return (
    <Typography align="center" className={classnames([label, getValueStyle(category)])}>
      {text}
    </Typography>
  );
}
