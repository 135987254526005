export const patientDashboardConsts = Object.freeze({
  LIST: {
    TEMPERATURE: 'resources.patient.edit.dashboard.list.temperature',
    SATURATION: 'resources.patient.edit.dashboard.list.saturation',
    PULSE: 'resources.patient.edit.dashboard.list.pulse'
  },
  MEASUREMENT: {
    TEMPERATURE: 'resources.patient.edit.dashboard.measurement.temperature',
    PULSE: 'resources.patient.edit.dashboard.measurement.pulse',
    SATURATION: 'resources.patient.edit.dashboard.measurement.saturation'
  },
  CHART: {
    TEMPERATURE: 'resources.patient.edit.dashboard.chart.temperature',
    PULSE: 'resources.patient.edit.dashboard.chart.pulse',
    SATURATION: 'resources.patient.edit.dashboard.chart.saturation',
    HIGHEST: 'resources.patient.edit.dashboard.chart.highest',
    LOWEST: 'resources.patient.edit.dashboard.chart.lowest'
  },
  STATUS: {
    STATUS: 'resources.patient.edit.dashboard.status.status'
  }
});
