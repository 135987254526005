import { makeStyles } from '@material-ui/core/styles';
import * as styles from 'style';

export const useStyles = makeStyles({
  button: {
    color: styles.colors.primaryBlue
  },
  arrow: {
    color: 'black'
  },
  menuItem: {
    borderBottom: `1px solid ${styles.colors.lightGray}`
  },
  growPlacementBottom: {
    transformOrigin: 'center top'
  }
});
