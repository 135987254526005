import React, { useCallback } from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import { Authenticated } from 'react-admin';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import { useDispatch } from 'react-redux';
import { ModalContent } from 'components/common';
import { MedicalcenterSelectionForm } from './MedicalcenterSelectionForm';
import { MedicalcenterSelectionFormModel } from './MedicalcenterSelectionFormModel';
import { selectMedicalcenterContext } from 'features/selectedMedicalcenter/actions';

export function MedicalcenterSelectionPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    ({ id }: MedicalcenterSelectionFormModel, api: FormApi<MedicalcenterSelectionFormModel>) => {
      if (api.getState().valid) {
        dispatch(selectMedicalcenterContext(id));
      }
    },
    [dispatch]
  );

  return (
    <Authenticated className={classes.container}>
      <Modal open={true} disableAutoFocus disableEnforceFocus>
        <ModalContent title="medicalcenterSelection.name" className={classes.modal}>
          <Form onSubmit={onSubmit} render={MedicalcenterSelectionForm} />
        </ModalContent>
      </Modal>
    </Authenticated>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  modal: {
    width: '70%'
  }
});
