import { Box, Button, Toolbar } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { Datagrid, List, ReferenceField, SaveButton, TextField, useTranslate, FunctionField } from 'react-admin';
import { UniversalFilter, UniversalFilterFields } from 'components/common';
import { useToggleListItem } from 'hooks/common';
import { constProvider } from 'providers';
import { defaultListProps } from 'services/utils';
import { colors } from 'style';
import { useStyles } from './list-picker.styles';

export type ListPickerColumns<T extends object, S extends object = any> = Array<{
  source: keyof T;
  resource?: keyof typeof constProvider.RESOURCES;
  refSource?: keyof S;
  render?: (item: T) => any;
}>;

export interface ListPickerProps<T extends object> {
  basePath: string;
  resource: string;
  filterFields?: UniversalFilterFields<T>;
  listColumns: ListPickerColumns<T>;
  onCancel: () => void;
  onSave: (ids: number[]) => void;
  children: any;
  filterDefaultValues?: object;
}

export function ListPicker<T extends { id: number }>({
  basePath,
  resource: resourceURI,
  filterFields,
  listColumns,
  onCancel,
  onSave,
  children,
  filterDefaultValues
}: ListPickerProps<T>) {
  const [pickedItems, setPickedItems] = useState<number[]>([]);
  const toggleListItem = useToggleListItem(pickedItems, setPickedItems);
  const highlightSelected = useCallback(
    (record: T) => {
      return {
        backgroundColor: pickedItems.includes(record.id) ? colors.lightGray : colors.white
      };
    },
    [pickedItems]
  );
  const onSaveBound = useCallback(() => {
    onSave(pickedItems);
  }, [pickedItems, onSave]);
  const classes = useStyles();
  const translate = useTranslate();

  const rowSelectable = useCallback(() => false, []);

  const columns = useMemo(
    () =>
      listColumns.map(({ source, resource, refSource, render }) => {
        return resource ? (
          <ReferenceField key={source} variant="standard" source={source} reference={resource}>
            {render ? <FunctionField render={render} source={refSource} /> : <TextField source={refSource} />}
          </ReferenceField>
        ) : render ? (
          <FunctionField render={render} source={source} />
        ) : (
          <TextField key={source} source={source} />
        );
      }),
    [listColumns]
  );

  const listProps = {
    ...defaultListProps,
    basePath,
    resource: resourceURI,
    filterDefaultValues,
    filters: filterFields ? <UniversalFilter fields={filterFields} /> : null
  };

  return (
    <>
      <Box>
        {children}
        <List {...listProps} className={classes.list}>
          <Datagrid isRowSelectable={rowSelectable} rowClick={toggleListItem} rowStyle={highlightSelected}>
            {columns}
          </Datagrid>
        </List>
      </Box>
      <Toolbar>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button color="primary" className={classes.cancelButton} onClick={onCancel}>
            {translate(constProvider.BUTTONS.CANCEL)}
          </Button>
          <SaveButton handleSubmitWithRedirect={onSaveBound} />
        </Box>
      </Toolbar>
    </>
  );
}
