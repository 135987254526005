import React, { ChangeEvent } from 'react';
import { UserPermissions } from '../../User.const';
import { PrivilegesRow, Row } from './privileges-row';

interface PrivilegesSectionProps {
  availablePrivileges: Set<UserPermissions>;
  section: Row[];
  privileges: string[];
  setPrivilege: (event: ChangeEvent<any>, value: boolean) => void;
}

export function PrivilegesSection({ availablePrivileges, section, privileges, setPrivilege }: PrivilegesSectionProps) {
  return (
    <>
      {section.map((row) => (
        <PrivilegesRow
          availablePrivileges={availablePrivileges}
          setPrivilege={setPrivilege}
          privileges={privileges}
          key={row.privilegeName}
          row={row}
        />
      ))}
    </>
  );
}
