import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { Filter, SelectInput, TextInput, ReferenceInput } from 'react-admin';
import { ActiveFilterDropdown } from 'components/common';
import { constProvider } from 'providers';

const useStyles = makeStyles({
  input: {
    minWidth: '220px'
  },
  filters: {
    'margin-bottom': '20px'
  }
});

type PatientFilters = { className?: string } & any;

export function PatientFilters({ className, ...props }: PatientFilters) {
  const classes = useStyles();

  const filterClassName = useMemo(() => [classes.filters, className], [className, classes.filters]);

  return (
    <Filter {...props} className={filterClassName}>
      <TextInput className={classes.input} source="firstName" alwaysOn />
      <TextInput className={classes.input} source="lastName" alwaysOn />
      <ActiveFilterDropdown className={classes.input} source="active" alwaysOn />
      <ReferenceInput
        alwaysOn
        className={classes.input}
        source="medicalCenterId"
        reference={constProvider.RESOURCES.MEDICALCENTER_FILTER_SELECT.URI}
      >
        <SelectInput variant="standard" />
      </ReferenceInput>
    </Filter>
  );
}
