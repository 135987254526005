import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List as ReactAdminList } from 'react-admin';
import { Pagination } from 'components/common';
type ListProps = { className?: string; children?: React.ReactNode } & any;

export function List({ className, children, ...props }: ListProps) {
  const classes = useStyles();
  return (
    <ReactAdminList pagination={<Pagination />} {...props} className={`${classes.list} ${className}`}>
      {children}
    </ReactAdminList>
  );
}

const useStyles = makeStyles({
  list: {
    position: 'relative',
    backgroundColor: 'white',
    padding: '25px',
    borderRadius: '4px'
  }
});
