import React, { PropsWithChildren } from 'react';
import { Typography, Box } from '@material-ui/core';

type TabPanelProps = PropsWithChildren<{
  index: string | number;
  value: string | number;
  className?: string;
}>;

export function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}
