import { makeStyles } from '@material-ui/styles';
import { fontWeight } from 'style';

export const useStyles = makeStyles((theme: any) => ({
  row: {
    paddingLeft: '3.5em'
  },
  searchAvailableModal: {
    width: '70%',
    minWidth: '900px'
  },
  subtitle: {
    marginLeft: '2.3em',
    marginRight: '2.3em',
    color: theme.palette.text.secondary,
    fontWeight: fontWeight.medium
  }
}));
